import React, { Component } from "react";
import { Button, Row, Col, Tabs, Table, Select, DatePicker, Input, Spin } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { tuple } from "antd/lib/_util/type";
import { Bar, } from "react-chartjs-2";
import { Chart } from "chart.js";

export default class UserRoyalties extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: '1',
            isExport: false,
            isLoading: false,
            searchModel: { pageSize: 30, page: 1,startTime: new Date().setMonth(new Date().getMonth() - 1), endTime: new Date(),},
            model: { pageSize: 10, page: 1,},
            tables: [],
            selectedRowKeys: [],
            selectedRows: [],
            chartData: [],
            chartList: [],
            wineAmountTotal: 0,
            foodAmountTotal: 0,
            rebateAmountTotal: 0,
        }
    }

    componentDidMount() {
        this.chartSearch()
        this.search()
    }

    searchChartChange(k, e) {
        let {searchModel} = this.state;
        if (k === "startTime") {
            searchModel.endTime = null;
        }
        searchModel[k] = e;
        this.setState({ searchModel });
    }

    handleEndDisabledDate(current) {
        let {startTime} = this.state.searchModel
        if (!current || !startTime) {
            return true;
        }
        return current > moment(startTime).add(1, 'month') || current < moment(startTime);
    }

    searchChange(k, e) {
        let { model } = this.state;
        model[k] = e;
        this.setState({ model });
    }

    check(model) {
        let Newmodel = model;
        let startTime = Newmodel.startTime != null ? moment(Newmodel.startTime).format("YYYYMMDD") : null;
        let endTime = Newmodel.endTime != null ? moment(Newmodel.endTime).format("YYYYMMDD") : null;
        if (startTime != null && endTime != null) {
            if (endTime < startTime) {
                Message.openMsgError("错误", "开始日期不能大于结束至日期;");
                return false;
            }
        }
        return true
    }

    chartSearch() {
        let model = this.state.searchModel;
        if (this.check(model)) {
            fetch(Config.apiBackServer + "userRoyalties/chartSearch", {
                method: 'POST',
                body: JSON.stringify(model),
                headers: Config.header,
                mode: 'cors',
                cache: 'default'
            }).then(res => res.json()).then((data) => {
                if ("SUCCESS" === data.msg) {
                    let list = data.object;
                    let xAxisContentList = [];
                    let rebateAmountList = [];
                    let foodAmountList = [];
                    let wineAmountList = [];
                    let wineAmountTotal = 0;
                    let foodAmountTotal = 0;
                    let rebateAmountTotal = 0
                    list.forEach(element => {
                        xAxisContentList.push(element.phone)
                        foodAmountList.push(element.foodAmount || 0)
                        wineAmountList.push(element.wineAmount || 0)
                        rebateAmountList.push(element.rebateAmount || 0)
                        foodAmountTotal += parseFloat(element.foodAmountTotal || 0)
                        wineAmountTotal += parseFloat(element.wineAmountTotal || 0)
                        rebateAmountTotal += parseFloat(element.rebateAmountTotal || 0)
                    });
    
                    let datesetsItem = {
                        label: "业绩奖励",
                        data: rebateAmountList,
                        backgroundColor: "#37a2da",
                        borderColor: "#37a2da",
                        borderWidth: 1,
                        hoverBackgroundColor: "#37a2da",
                        hoverBorderColor: "#37a2da",
                        barThickness: "flex",
                        fill: false
                    }
                    let foodAmountDateItem = {
                        label: "返现金额",
                        data: foodAmountList,
                        backgroundColor: "#ee3f4d",
                        borderColor: "#ee3f4d",
                        borderWidth: 1,
                        hoverBackgroundColor: "#ee3f4d",
                        hoverBorderColor: "#ee3f4d",
                        barThickness: "flex",
                        fill: false
                    }
                    let wineAmountDateItem = {
                        label: "不返现金额",
                        data: wineAmountList,
                        backgroundColor: "#FF9933",
                        borderColor: "#FF9933",
                        borderWidth: 1,
                        hoverBackgroundColor: "#FF9933",
                        hoverBorderColor: "#FF9933",
                        barThickness: "flex",
                        fill: false
                    }
                    let  chartData = {
                        labels: xAxisContentList,
                        datasets: [foodAmountDateItem,wineAmountDateItem,datesetsItem]
                    }
                    this.setState({
                        chartData: chartData,
                        chartList: data.object,
                        wineAmountTotal: wineAmountTotal,
                        foodAmountTotal: foodAmountTotal,
                        rebateAmountTotal: rebateAmountTotal
                    })
                }
            })
        }
    }

    search() {
        let model = this.state.model
        if (this.check(model)) {
            this.setState({ isLoading: true })
            fetch(Config.apiBackServer + "userRoyalties/search" ,{
                method: 'POST',
                body: JSON.stringify(model),
                headers: Config.header,
                mode: 'cors',
                cache: 'default'
            }).then(res => res.json()).then((data) => {
                let model = this.state.model
                if ("SUCCESS" === data.msg) {
                    model.total = data.object.total;
                } else {
                    Message.openMsgError("错误", data.msg,data.errorCode)
                }
                this.setState({
                    model: model,
                    tables: data.object.list
                })
            }).catch(err => {}).finally(() => this.setState({ isLoading: false }));
        }
    }

    export() {
        this.setState({ isExport: true })
        fetch(Config.apiBackServer + "userRoyalties/export", {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "用户奖励统计" + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;   
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    this.cancel();
                }
            }
        }).catch(err => {}).finally(() => this.setState({ isExport: false }))
    }

    exportChartData() {
        this.setState({ isExport: true })
        fetch(Config.apiBackServer + "userRoyalties/exportChartData", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "用户奖励统计" + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    this.cancel();  
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        })
    }

    tableChange(value) {
        this.setState({
            tabIndex: value,
        }, () => {
            this.reset();
            if (value === '2') {
                this.search();
            } else {
                this.chartSearch()
            }
        })
    }

    reset() {
        this.setState({
            selectedRowKeys: [],
            selectedRows: [],
            model: { pageSize: 10, page: 1,},
            searchModel: { pageSize: 30, page: 1,startTime: new Date().setMonth(new Date().getMonth() - 1), endTime: new Date(),},
        }, () => {
            if (this.state.tabIndex === "1") {
                this.chartSearch()
            } else {
                this.search();
            }
        })
    }

    pageChange = (current, pageSize) => {
        let model = this.state.model;
        model.pageSize = pageSize;
        model.page = current;
        this.setState({
            model: model,
        });
        this.search();
    }

    onSelectChange = (selectedRowKeys, selectedRows) =>{
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    render() {
        const { TabPane } = Tabs;
        const columns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth10",
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: "tableWidth10",
            },
            {
                title: '推荐人卡号',
                dataIndex: 'referrerCardNumber',
                className: "tableWidth10",
            },
            {
                title: '推荐人手机号',
                dataIndex: 'referrerPhone',
                className: "tableWidth10",
            },
            {
                title: '业绩奖励',
                dataIndex: 'rebateAmount',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmount',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmount',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '钱包余额',
                dataIndex: 'walletBalance',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '已到账奖励',
                dataIndex: 'rewardReceived',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },

            {
                title: '奖励待审核',
                dataIndex: 'auditAward',
                className: "tableWidth9 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        }
        
        return(
            <div className="userRoyalties">
                <div className="zhRoot">
                    <div className="zhTitle">用户奖励统计</div>
                    <Spin spinning={this.state.isExport} tip="下载中">
                        <div className="content">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="统计" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">消费日期：</Col>
                                        <Col span={6} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                allowClear={true}
                                                className="userRoyaltiesDatePicker"
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChartChange("startTime", value)}
                                                format={Config.dateFormat}/>
                                            &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                allowClear={true}
                                                className="userRoyaltiesDatePicker"
                                                disabledDate={(e) => this.handleEndDisabledDate(e)}
                                                value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChartChange("endTime", value)}
                                                format={Config.dateFormat}/>
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button type="primary" icon="search" onClick = {() => this.chartSearch()}>搜索</Button>
                                            <Button className="zhButtonRight" icon="redo" onClick = {() => this.reset()}>重置</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button disabled={this.state.isExport} className="zhButtonLeft" type="primary" icon="download" onClick = {() => this.exportChartData()}>导出</Button>
                                    </Row>
                                    <Row style={{ height: "500px" }}>
                                        <Bar
                                             width={1200}
                                             data={this.state.chartData}
                                             options={{
                                                title: {
                                                    display: true,
                                                    text: "用户奖励统计",
                                                    position: "top",
                                                },
                                                tooltips: {
                                                    enabled: true,
                                                    intersect: false
                                                },
                                                maintainAspectRatio: false,
                                                responsive: true,
                                                scales: {
                                                    xAxes: [{

                                                    }],
                                                    yAxes: [{
                                                        ticks: {
                                                            beginAtZero: true,
                                                            callback: function (value) { if (value % 1 === 0) { return value } }
                                                        }
                                                    }]
                                                },
                                                legend: {
                                                    position: "bottom",
                                                    align: "center",
                                                    labels: {
                                                        boxWidth: 20
                                                    }
                                                },
                                                hover: {
                                                    animationDuration: 0  // 防止鼠标移上去，数字闪烁
                                                },
                                                animation: {
                                                    onComplete: function() {
                                                        var chartInstance = this.chart,
                                                        ctx = chartInstance.ctx;
                                                        // 以下属于canvas的属性（font、fillStyle、textAlign...）
                                                        ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                                                        ctx.fillStyle = "black";
                                                        ctx.textAlign = 'center';
                                                        ctx.textBaseline = 'bottom';

                                                        this.data.datasets.forEach(function (dataset, i) {
                                                            var meta = chartInstance.controller.getDatasetMeta(i);
                                                            meta.data.forEach(function (bar, index) {
                                                                var data = dataset.data[index];
                                                                ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                                            });
                                                        });
                                                    }
                                                }
                                             }}
                                        />
                                    </Row>
                                    <Row><span>返现总金额:</span><span style={{fontSize:"16px", fontWeight:"600",marginLeft:"12px"}}>{Common.numberFormat(this.state.foodAmountTotal || 0, 2)}</span></Row>
                                    <Row><span>不返现总金额:</span><span style={{fontSize:"16px", fontWeight:"600",marginLeft:"12px"}}>{Common.numberFormat(this.state.wineAmountTotal || 0, 2)}</span></Row>
                                    <Row><span>业绩总奖励:</span><span style={{fontSize:"16px", fontWeight:"600",marginLeft:"12px"}}>{Common.numberFormat(this.state.rebateAmountTotal || 0, 2)}</span></Row>
                                </TabPane>
                                <TabPane tab="列表" key="2">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="会员卡号"
                                                value={this.state.model.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="会员手机号"
                                                value={this.state.model.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">消费日期：</Col>
                                        <Col span={6} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="userRoyaltiesDatePicker"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.model.startTime == null ? null : moment(new Date(this.state.model.startTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("startTime", value)}
                                                format={Config.dateFormat}/>
                                             &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="userRoyaltiesDatePicker"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.model.endTime == null ? null : moment(new Date(this.state.model.endTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endTime", value)}
                                                format={Config.dateFormat}/>
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.export()} icon="download">导出</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                       <Table rowSelection={rowSelection}
                                            rowKey="cardNumber"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables} //数据
                                            pagination={{  //分页
                                                showSizeChanger: true,       
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法                   //是否显示可以设置几条一页的选项
                                                total: this.state.model.total,             //总共多少条数据
                                                current: this.state.model.page,         //当前页
                                                pageSize: this.state.model.pageSize,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'cardNumber', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                       /> 
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Spin>
                </div>
            </div>
        )
    }
}