import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Input, Select, Spin, Modal, Typography, DatePicker } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';
import { Bar, } from "react-chartjs-2";
import { Chart } from "chart.js";
import './rebateStatistics.css';

export default class RebateStatistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {startDate: new Date().setMonth(new Date().getMonth() - 1), endDate: new Date(), pageSize: 10, page: 1,},
            tables: [],
            isLoading: false,
            searchModel: { startDate: new Date().setMonth(new Date().getMonth() - 1), endDate: new Date(), pageSize: 30, page: 1, auditStatus: "CHECKED" },
            chartList: [],
            charData: {},
            isExport: false,
            oneLayerList: [],
            twoLayerList: [],
            threeLayerList: [],
            newUsersTotal: 0,
            foodAmountAll: 0,
            wineAmountAll: 0,
        }

    }

    componentDidMount() {
        this.chartSearch();
        this.threeLevelNestingSearch()
    }

    threeLevelNestingSearch() {
        fetch(Config.apiBackServer + "area/threeLevelNestingSearch", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({
                    oneLayerList: data.object
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }
    check() {
        let model = this.state.searchModel
        let str = ""
        if (Common.isNotEmpty(model.consumeStartDate) && Common.isNotEmpty(model.consumeEndDate) ) {
            if(model.consumeStartDate > model.consumeEndDate){
                str += "消费由日期不能大于消费至日期;"
            }
           
        }
        if (Common.isNotEmpty(model.startDate) && Common.isNotEmpty(model.endDate)) {
           if( model.startDate > model.endDate){
            str += "奖励由日期不能大于奖励至日期;"
           }
        }
        return str
    }
    
    chartSearch() {
        let model = this.state.searchModel
        let consumeStartDate = model.consumeStartDate != null ? moment(model.consumeStartDate).format("YYYYMMDD") : null;
        let consumeEndDate = model.consumeEndDate != null ? moment(model.consumeEndDate).format("YYYYMMDD") : null;
        if (consumeEndDate!=null&& consumeStartDate != null ) {
            if(consumeEndDate < consumeStartDate){
                Message.openMsgError("错误", "消费由日期不能大于消费至日期;");
                return;
            }
        }
        let startDate = model.startDate != null ? moment(model.startDate).format("YYYYMMDD") : null;
        let endDate = model.endDate != null ? moment(model.endDate).format("YYYYMMDD") : null;
        if (startDate!=null&& endDate != null ) {
            if(endDate < startDate){
                Message.openMsgError("错误", "奖励由日期不能大于奖励至日期;");
                return;
            }
        }
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "rebateStatistics/chartSearch", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                let list = data.object;
                let xAxisContentList = [];
                let regionList = [];
                let foodAmountList = [];
                let wineAmountList = [];
                let newUsersTotal = 0;
                let foodAmountAll = 0;
                let wineAmountAll = 0;
                list.forEach(element => {
                    foodAmountAll += parseFloat(element.foodAmount || 0)
                    wineAmountAll += parseFloat(element.wineAmount || 0)
                    newUsersTotal += parseFloat(element.rebateAmount || 0)
                    xAxisContentList.push(element.region);
                    regionList.push(element.rebateAmount);
                    foodAmountList.push(element.foodAmount || 0)
                    wineAmountList.push(element.wineAmount || 0)
                });
                let foodAmountDateItem = {
                    label: "返现金额",
                    data: foodAmountList,
                    backgroundColor: "#ee3f4d",
                    borderColor: "#ee3f4d",
                    borderWidth: 1,
                    hoverBackgroundColor: "#ee3f4d",
                    hoverBorderColor: "#ee3f4d",
                    barThickness: "flex",
                    fill: false
                }
                let wineAmountDateItem = {
                    label: "不返现金额",
                    data: wineAmountList,
                    backgroundColor: "#FF9933",
                    borderColor: "#FF9933",
                    borderWidth: 1,
                    hoverBackgroundColor: "#FF9933",
                    hoverBorderColor: "#FF9933",
                    barThickness: "flex",
                    fill: false
                }
                let datesetsItem = {
                    label: "业绩奖励",
                    data: regionList,
                    backgroundColor: "#37a2da",
                    borderColor: "#37a2da",
                    borderWidth: 1,
                    hoverBackgroundColor: "#37a2da",
                    hoverBorderColor: "#37a2da",
                    barThickness: "flex",
                    fill: false
                }
                let chartData = {
                    labels: xAxisContentList,
                    datasets: [foodAmountDateItem,wineAmountDateItem,datesetsItem]
                }
                this.setState({
                    chartList: data.object,
                    chartData: chartData,
                    newUsersTotal: newUsersTotal,
                    foodAmountAll: foodAmountAll,
                    wineAmountAll: wineAmountAll,
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    

    fromSearch() {
        let model = this.state.model
        let consumeStartDate = model.consumeStartDate != null ? moment(model.consumeStartDate).format("YYYYMMDD") : null;
        let consumeEndDate = model.consumeEndDate != null ? moment(model.consumeEndDate).format("YYYYMMDD") : null;
        if (consumeEndDate!=null&& consumeStartDate != null ) {
            if(consumeEndDate < consumeStartDate){
                Message.openMsgError("错误", "消费由日期不能大于消费至日期;");
                return;
            }
        }
        let startDate = model.startDate != null ? moment(model.startDate).format("YYYYMMDD") : null;
        let endDate = model.endDate != null ? moment(model.endDate).format("YYYYMMDD") : null;
        if (startDate!=null&& endDate != null ) {
            if(endDate < startDate){
                Message.openMsgError("错误", "奖励由日期不能大于奖励至日期;");
                return;
            }
        }
        model.region = model.provinceName + model.cityName + model.areaName
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "rebateStatistics/fromSearch", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log(data)
            let model = this.state.model;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
                this.setState({
                    model: model,
                    tables: data.object.list,
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    tableChange(value) {
        this.setState({
            tabIndex: value,
        }, () => {
            this.reset();
            if (value === "2") {
                this.fromSearch();
            } else {
                this.chartSearch();
            }
        })
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        if (k === "provinceId") {
            newModel.cityId = ""
            newModel.areaId = ""
            newModel.cityName = "";
            newModel.areaName = "";
            newModel.region = "";
            newModel.provinceName = "";
            let twoLayerList = []
            for (let item of this.state.oneLayerList) {
                if (e === item.id) {
                    if (item.name === "北京" || item.name === "上海" || item.name === "天津" || item.name === "重庆") {
                        newModel.provinceName = item.name + "市"
                    } else if (item.name === "西藏") {
                        newModel.provinceName = item.name + "自治区"
                    } else if (item.name === "新疆") {
                        newModel.provinceName = item.name + "维吾尔自治区"
                    } else {
                        newModel.provinceName = item.name + "省"
                    }
                    newModel.cityName = "";
                    newModel.areaName = "";
                    twoLayerList = item.areaList
                    this.setState({
                        twoLayerList: twoLayerList,
                        threeLayerList: [],
                    });
                    break;
                }
            }
        } else if (k === "cityId") {
            newModel.areaId = ""
            let threeLayerList = []
            for (let item of this.state.twoLayerList) {
                if (e === item.id) {
                    if (newModel.provinceName === "北京市" || newModel.provinceName === "上海市" || newModel.provinceName === "天津市" || newModel.provinceName === "重庆市") {
                        newModel.cityName = ""
                    } else {
                        newModel.cityName = item.name + "市"
                    }
                    newModel.areaName = ""
                    threeLayerList = item.areaList
                    this.setState({
                        threeLayerList: threeLayerList,
                    })
                    break
                }
            }
        } else if (k === "areaId") {
            for (let item of this.state.threeLayerList) {
                if (e === item.id) {
                    newModel.areaName = item.name
                    break
                } else {
                    newModel.areaName = ""
                }
            }
        }
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        }, () => {
            console.log(this.state.searchModel)
        });
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        if (k === "provinceId") {
            newModel.cityId = ""
            newModel.areaId = ""
            newModel.cityName = "";
            newModel.areaName = "";
            newModel.region = "";
            newModel.provinceName = "";
            let twoLayerList = []
            for (let item of this.state.oneLayerList) {
                if (e === item.id) {
                    if (item.name === "北京" || item.name === "上海" || item.name === "天津" || item.name === "重庆") {
                        newModel.provinceName = item.name + "市"
                    } else if (item.name === "西藏") {
                        newModel.provinceName = item.name + "自治区"
                    } else if (item.name === "新疆") {
                        newModel.provinceName = item.name + "维吾尔自治区"
                    } else {
                        newModel.provinceName = item.name + "省"
                    }
                    newModel.cityName = "";
                    newModel.areaName = "";
                    twoLayerList = item.areaList
                    this.setState({
                        twoLayerList: twoLayerList,
                        threeLayerList: [],
                    });
                    break;
                }
            }
        } else if (k === "cityId") {
            newModel.areaId = ""
            let threeLayerList = []
            for (let item of this.state.twoLayerList) {
                if (e === item.id) {
                    if (newModel.provinceName === "北京市" || newModel.provinceName === "上海市" || newModel.provinceName === "天津市" || newModel.provinceName === "重庆市") {
                        newModel.cityName = ""
                    } else {
                        newModel.cityName = item.name + "市"
                    }
                    newModel.areaName = ""
                    threeLayerList = item.areaList
                    this.setState({
                        threeLayerList: threeLayerList,
                    })
                    break
                }
            }
        } else if (k === "areaId") {
            for (let item of this.state.threeLayerList) {
                if (e === item.id) {
                    newModel.areaName = item.name
                    break
                } else {
                    newModel.areaName = ""
                }
            }
        }
        newModel[k] = e;
        this.setState({
            model: newModel,
        });
    }

    reset() {
        let model = { page: 1, pageSize: 10, funcName: "", startDate: new Date().setMonth(new Date().getMonth() - 1), endDate: new Date(), region: "" }
        if (this.state.tabIndex === "1") {
            model.auditStatus = "CHECKED"
        }
        this.setState({
            searchModel: model,
            model: { page: 1, pageSize: 10, funcName: "", startDate: new Date().setMonth(new Date().getMonth() - 1), endDate: new Date(), region: "" },
            twoLayerList: [],
            threeLayerList: [],
        }, () => {
            if (this.state.tabIndex === "1") {
                this.chartSearch()
            } else {
                this.fromSearch()
            }
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    pageChange = (current, pageSize) => {
        let model = this.state.model;
        model.pageSize = pageSize;
        model.page = current;
        this.setState({
            selectedRowKeys: [],
            selectedRows: [],
            model: model,
        });
        this.fromSearch();
    }

    exportReport() {
        let model = this.state.model
        let consumeStartDate = model.consumeStartDate != null ? moment(model.consumeStartDate).format("YYYYMMDD") : null;
        let consumeEndDate = model.consumeEndDate != null ? moment(model.consumeEndDate).format("YYYYMMDD") : null;
        if (consumeEndDate!=null&& consumeStartDate != null ) {
            if(consumeEndDate < consumeStartDate){
                Message.openMsgError("错误", "消费由日期不能大于消费至日期;");
                return;
            }
        }
        let startDate = model.startDate != null ? moment(model.startDate).format("YYYYMMDD") : null;
        let endDate = model.endDate != null ? moment(model.endDate).format("YYYYMMDD") : null;
        if (startDate!=null&& endDate != null ) {
            if(endDate < startDate){
                Message.openMsgError("错误", "奖励由日期不能大于奖励至日期;");
                return;
            }
        }
        model.region = model.provinceName + model.cityName + model.areaName
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "rebateStatistics/exportExcel", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "区县奖励统计" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }

    exportChartReport() {
        let model = this.state.searchModel
        let consumeStartDate = model.consumeStartDate != null ? moment(model.consumeStartDate).format("YYYYMMDD") : null;
        let consumeEndDate = model.consumeEndDate != null ? moment(model.consumeEndDate).format("YYYYMMDD") : null;
        if (consumeEndDate!=null&& consumeStartDate != null ) {
            if(consumeEndDate < consumeStartDate){
                Message.openMsgError("错误", "消费由日期不能大于消费至日期;");
                return;
            }
        }
        let startDate = model.startDate != null ? moment(model.startDate).format("YYYYMMDD") : null;
        let endDate = model.endDate != null ? moment(model.endDate).format("YYYYMMDD") : null;
        if (startDate!=null&& endDate != null ) {
            if(endDate < startDate){
                Message.openMsgError("错误", "奖励由日期不能大于奖励至日期;");
                return;
            }
        }
        model.region = model.provinceName + model.cityName + model.areaName
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "rebateStatistics/exportChartExcel", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "区县奖励图表统计" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '所属地区',
                dataIndex: 'region',
                className: "tableWidth12",
            },
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: "tableWidth9",
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: "tableWidth9",
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth9",
            },
            {
                title: '奖励日期',
                dataIndex: 'rebateTime',
                className: "tableWidth9",
                render: (text) => text == null ? null : moment(text).format(Config.dateFormat)
            },
            {
                title: '推荐类型',
                dataIndex: 'rebateType',
                className: "tableWidth9",
                render: (text) => ContextHandler.getMapValue('rebateSalesRebateTypeMap', text)
            },
            {
                title: '奖励金额',
                dataIndex: 'rebateAmount',
                className: 'tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '审核状态',
                dataIndex: 'auditStatus',
                className: "tableWidth7",
                render: (text) => ContextHandler.getMapValue('auditStatusMap', text)
            },
            {
                title: '审核日期',
                dataIndex: 'auditDate',
                className: "tableWidth9",
                render: (text) =>  text == null ? null : moment(text).format(Config.dateFormat)
            },
            {
                title: '消费金额',
                dataIndex: 'tradeAmount',
                className: "tableWidth9 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmount',
                className: "tableWidth9 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        }

        return (
            <div className="rebateStatistics">
                <div className="zhRoot">
                    <div className="zhTitle">区县奖励统计</div>
                    <div className="content">
                        <Spin spinning={this.state.isExport} tip="下载中...">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="统计" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">所属地区：</Col>
                                        <Col span={5} className="zhInput">
                                            <Select className="rebateStatisticsSelect" placeholder="省" value={this.state.searchModel.provinceId || ''}
                                                onChange={(e) => this.searchChange('provinceId', e)}>
                                                <Option key={"a"} value={""}> </Option >
                                                {this.state.oneLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option >)}
                                            </Select>
                                            <Select className="rebateStatisticsSelect" placeholder="市" value={this.state.searchModel.cityId || ''}
                                                onChange={(e) => this.searchChange('cityId', e)}>
                                                <Option key={"b"} value={""}> </Option >
                                                {this.state.twoLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option >)}
                                            </Select>
                                            <Select className="rebateStatisticsSelect" placeholder="县" value={this.state.searchModel.areaId || ''}
                                                onChange={(e) => this.searchChange('areaId', e)}>
                                                <Option key={"c"} value={""}> </Option >
                                                {this.state.threeLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option >)}
                                            </Select>
                                        </Col>
                                        <Col span={2} className="zhTxt">审核状态：</Col>
                                        <Col span={3} className="zhInput">
                                            <Select style={{ width: "100%" }} value={this.state.searchModel.auditStatus || ''}
                                                onChange={(e) => this.searchChange('auditStatus', e)}>
                                                <Option key={"a"} value={""}>全部</Option>
                                                {context.auditStatusMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={2} className="zhTxt">奖励日期：</Col>
                                        <Col span={5} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                allowClear={true}
                                                className="rebateStatisticsDatePicker"
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.startDate == null ? null : moment(new Date(this.state.searchModel.startDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("startDate", value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                allowClear={true}
                                                className="rebateStatisticsDatePicker"
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.endDate == null ? null : moment(new Date(this.state.searchModel.endDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>

                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button type="primary" onClick={() => this.chartSearch()} icon="search">搜索</Button>
                                            <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.exportChartReport()} icon="download">导出</Button>
                                    </Row>
                                    <Row style={{ height: "500px" }}>
                                        {Common.isNotEmpty(this.state.chartData) ?
                                            <Bar
                                                width={1200}
                                                data={this.state.chartData}
                                                options={{
                                                    title: {
                                                        display: true,
                                                        text: "区县奖励统计",
                                                        position: "top",
                                                    },
                                                    tooltips: {
                                                        enabled: true,
                                                        intersect: false
                                                    },
                                                    maintainAspectRatio: false,
                                                    responsive: true,
                                                    scales: {
                                                        xAxes: [{
                                                            // stacked: true,
                                                            // ticks: {
                                                            //     beginAtZero: true,
                                                            //     maxTicksLimit: this.state.rightxAxisList ? this.state.rightxAxisList.length : 14,
                                                            // }
                                                        }],
                                                        yAxes: [{
                                                            // stacked: true,
                                                            ticks: {
                                                                beginAtZero: true,
                                                                callback: function (value) { if (value % 1 === 0) { return value } }
                                                            }
                                                        }]
                                                    },
                                                    legend: {
                                                        position: "bottom",
                                                        align: "center",
                                                        labels: {
                                                            boxWidth: 20
                                                        }
                                                    },
                                                    hover: {
                                                        animationDuration: 0  // 防止鼠标移上去，数字闪烁
                                                    },
                                                    animation: {           // 这部分是数值显示的功能实现
                                                        onComplete: function () {
                                                            var chartInstance = this.chart,
                                                                ctx = chartInstance.ctx;
                                                            // 以下属于canvas的属性（font、fillStyle、textAlign...）
                                                            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                                                            ctx.fillStyle = "black";
                                                            ctx.textAlign = 'center';
                                                            ctx.textBaseline = 'bottom';

                                                            this.data.datasets.forEach(function (dataset, i) {
                                                                var meta = chartInstance.controller.getDatasetMeta(i);
                                                                meta.data.forEach(function (bar, index) {
                                                                    var data = dataset.data[index];
                                                                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                                                });
                                                            });
                                                        }
                                                    }

                                                }}
                                            />
                                            : ""}
                                    </Row>
                                    <Row>返现总金额：{Common.numberFormat(this.state.foodAmountAll, 2) || 0}</Row>
                                    <Row>不返现总金额：{Common.numberFormat(this.state.wineAmountAll, 2) || 0}</Row>
                                    <Row>业绩奖励总金额：{Common.numberFormat(this.state.newUsersTotal, 2) || 0}</Row>
                                </TabPane>
                                <TabPane tab="明细" key="2">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">所属地区：</Col>
                                        <Col span={5} className="zhInput">
                                            <Select className="rebateStatisticsSelect" placeholder="省" value={this.state.model.provinceId || ''}
                                                onChange={(e) => this.modelChange('provinceId', e)}>
                                                <Option key={"a"} value={""}> </Option>
                                                {this.state.oneLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                            <Select className="rebateStatisticsSelect" placeholder="市" value={this.state.model.cityId || ''}
                                                onChange={(e) => this.modelChange('cityId', e)}>
                                                <Option key={"b"} value={""}> </Option>
                                                {this.state.twoLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                            <Select className="rebateStatisticsSelect" placeholder="县" value={this.state.model.areaId || ''}
                                                onChange={(e) => this.modelChange('areaId', e)}>
                                                <Option key={"c"} value={""}> </Option>
                                                {this.state.threeLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="会员卡号"
                                                value={this.state.model.cardNumber || ''}
                                                onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={20}
                                                placeholder="会员手机号"
                                                value={this.state.model.phone || ''}
                                                onChange={(e) => this.modelChange('phone', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">奖励日期：</Col>
                                        <Col span={5} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="rebateStatisticsDatePicker"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.model.startDate == null ? null : moment(new Date(this.state.model.startDate), Config.dateFormat)}
                                                onChange={(value) => this.modelChange("startDate", value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="rebateStatisticsDatePicker"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.model.endDate == null ? null : moment(new Date(this.state.model.endDate), Config.dateFormat)}
                                                onChange={(value) => this.modelChange("endDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={2} className="zhTxt">审核状态：</Col>
                                        <Col span={3} className="zhInput">
                                            <Select style={{ width: "100%" }} value={this.state.model.auditStatus || ''}
                                                onChange={(e) => this.modelChange('auditStatus', e)}>
                                                <Option key={"a"} value={""}>全部</Option>
                                                {context.auditStatusMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={2} className="zhTxt">审核日期：</Col>
                                        <Col span={5} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="rebateStatisticsDatePicker"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.model.startTime == null ? null : moment(new Date(this.state.model.startTime), Config.dateFormat)}
                                                onChange={(value) => this.modelChange("startTime", value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="rebateStatisticsDatePicker"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.model.endTime == null ? null : moment(new Date(this.state.model.endTime), Config.dateFormat)}
                                                onChange={(value) => this.modelChange("endTime", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button type="primary" onClick={() => this.fromSearch()} icon="search">搜索</Button>
                                            <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.exportReport()} icon="download">导出</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table 
                                            // rowSelection={rowSelection}
                                            // rowKey= {record => record.region + moment(record.rebateTime).format(Config.dateFormat) + record.cardNumber + record.auditStatus + record.rebateType}
                                            rowKey="rebateSalesId"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            dataSource={this.state.tables} //数据
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            pagination={{  //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.model.total,           //总共多少条数据
                                                current: this.state.model.page,         //当前页
                                                pageSize: this.state.model.pageSize,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                        //  onRow={(record) => {
                                        //     return {
                                        //         onClick: event => Common.selectRadioRow.call(this, record, 'rebateSalesId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                        //     };
                                        //  }}
                                         rowClassName="tableColor"
                                        />
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}