import React, { Component } from 'react';
import { InputNumber, Button, Row, Col, Tabs, Table, Input, Select, Modal, DatePicker, Spin } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import './consumptionManagement.css';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';

const confirm = Modal.confirm;
var css;
export default class ConsumptionManagement extends Component {

    constructor(props) {
        super(props); 
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            searchModel: {shopId: Config.userInfo.shopId, pageSize: 10, page: 1, type: "消费管理",},
            isLoading: true,
            isExport: false,
        }
    }

    componentDidMount() {
        this.search();
    }


    // search() {
    //     this.setState({ isLoading: true })
    //     fetch(Config.apiBackServer + "cashier/platformSearchByShopId", {
    //         method: 'POST',
    //         body: JSON.stringify(this.state.searchModel),
    //         headers: Config.header,
    //         mode: 'cors',
    //         cache: 'default'
    //     }).then(res => res.json()).then((data) => {
    //         let model = this.state.searchModel;
    //         if ("SUCCESS" === data.msg) {
    //             model.total = data.object.total;
    //         } else {
    //             Message.openMsgError("错误", data.msg, data.errorCode)
    //         }
    //         this.setState({
    //             searchModel: model,
    //             tables: data.object.list
    //         })
    //     }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    // }


    search() {
        this.setState({isLoading:true})
        fetch(Config.apiBackServer + "consumptionManagementOfPlatform/searchbyShopId", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
            console.log(data)
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    selectRadioRow(record) {
        let selectedRowKeys = this.state.selectedRowKeys;
        let checkboxState = this.state.selectedRows;
        let i = checkboxState.indexOf(record);
        if (i > -1) {
            selectedRowKeys = [];
            checkboxState = [];
        } else {
            selectedRowKeys = [record.cardNumber + record.cashierTime];
            checkboxState = [record];
        }
        this.setState({ selectedRowKeys : selectedRowKeys, selectedRows: checkboxState });
    }

    reset() {
        this.setState({
            searchModel: {shopId: Config.userInfo.shopId, pageSize: 10, page: 1, type: "消费管理",},
            selectedRowKeys: [],
            selectedRows: [],
        }, () => {
            this.search();
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let module = this.state.selectedRows[0];
        module.cashierType = ContextHandler.getTransactionTypeValue(module.cashierType);
        this.setState({
            tabIndex: "2",
            flag: "edit",
            model: module
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({
            model: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            searchModel: {shopId: Config.userInfo.shopId, pageSize: 10, page: 1, type: "消费管理",},
        }, () => {
            this.search()
        })
    }

    tableChange(value) {
        if (value === "2") {
            this.edit();
        } else {
            this.setState({
                tab: value,
            })
            this.cancel();
        }
    }

    onChange = e => {
        this.setState({
            value: e.target.value,
        });
    };

    render() {
        const { TabPane } = Tabs;
        const columns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth18",
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: "tableWidth12",
            },
            {
                title: '消费日期',
                dataIndex: 'cashierTime',
                className: "tableWidth12",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '返现金额',
                className: "tableWidth12 tableRightAlignment",
                dataIndex: 'footAmount',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '不返现金额',
                className: "tableWidth12 tableRightAlignment",
                dataIndex: 'wineAmount',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '总金额',
                className: "tableWidth12 tableRightAlignment",
                dataIndex: 'tradeAmount',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '消费方式',
                dataIndex: 'cashierType',
                className: "tableWidth10",
                render: (text) => ContextHandler.getTransactionTypeValue(text)
            },
            {
                title: '收银状态',
                dataIndex: 'cashierStatus',
                className: "tableWidth10",
                render: (text) => ContextHandler.getMapValue('cashierStatusMap', text)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };

        return (
            <div className="consumptionManagement">
                <div className="cashier">
                    <div className="zhRoot">
                        <Spin spinning={this.state.isExport} tip="下载中...">
                            <div className="zhTitle">消费管理</div>
                            <div className="content">
                                <Tabs activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                    <TabPane tab="列表" key="1">
                                        <Row className="row">
                                            <Col span={2} className="zhTxt">会员卡号：</Col>
                                            <Col span={3} className="zhInput">
                                                <Input maxLength={10}
                                                    placeholder="会员卡号"
                                                    value={this.state.searchModel.cardNumber || ''}
                                                    onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                            </Col>
                                            <Col span={2} className="zhTxt">会员手机号：</Col>
                                            <Col span={3} className="zhInput">
                                                <Input maxLength={11}
                                                    type="phone" 
                                                    placeholder="会员手机号"
                                                    value={this.state.searchModel.phone || ''}
                                                    onChange={(e) => this.searchChange('phone', e.target.value)} />
                                            </Col>
                                            <Col span={2} className="zhTxt">消费日期：</Col>
                                            <Col span={8} className="zhInput">
                                                <DatePicker placeholder="由日期"
                                                    className="consumptionManagementDatePicker"
                                                    allowClear={true}
                                                    disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                    value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                                    onChange={(value) => this.searchChange("startTime", value)}
                                                    format={Config.dateFormat} />
                                                    &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                    className="consumptionManagementDatePicker"
                                                    allowClear={true}
                                                    disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                    value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                                    onChange={(value) => this.searchChange("endTime", value)}
                                                    format={Config.dateFormat} />
                                            </Col>
                                        </Row>
                                        <Row className="rowButton">
                                            <Col className="zhRowButtonRight">
                                                <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                                <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.edit()} icon="eye">查看</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" type="primary" onClick={() => this.exportExcel()} icon="download">导出</Button>
                                        </Row>
                                        <Row className='zhRow'>
                                            <Table rowSelection={rowSelection}
                                                rowKey={record => record.cardNumber + record.cashierTime}
                                                bordered={true} //边线
                                                columns={columns} //列名
                                                dataSource={this.state.tables} //数据
                                                loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                                pagination={{  //分页
                                                    showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                    onChange: this.pageChange,            //上页下页或者跳页方法
                                                    onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                    total: this.state.searchModel.total,             //总共多少条数据
                                                    current: this.state.searchModel.page,         //当前页
                                                    pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                                    defaultCurrent: 1,                               //默认当前页为1
                                                }}
                                                onRow={(record) => {
                                                    return {
                                                        onClick: event => this.selectRadioRow(record), // 点击行
                                                    };
                                                }}
                                                rowClassName="tableColor"
                                            />
                                        </Row>
                                    </TabPane>
                                    <TabPane tab="详情" key="2">
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>会员卡号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input
                                                    maxLength={10}
                                                    disabled
                                                    placeholder="会员卡号"
                                                    value={this.state.model.cardNumber || ''}
                                                    onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                            </Col>
                                            <Col span={4} className='zhTxt'>会员手机号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input
                                                    maxLength={11}
                                                    disabled
                                                    placeholder="会员手机号"
                                                    value={this.state.model.phone || ''}
                                                    onChange={(e) => this.modelChange('phone', e.target.value)} />
                                            </Col>
                                        </Row>

                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>返现金额：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input className="zhInputNumber"
                                                    placeholder="返现金额"
                                                    disabled
                                                    value={Common.numberFormat(this.state.model.footAmount,2) || ''}
                                                    onChange={(e) => this.modelChange('footAmount', e)} />
                                            </Col>
                                            <Col span={4} className='zhTxt'>不返现金额：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input className="zhInputNumber"
                                                    min={0}
                                                    disabled
                                                    placeholder="不返现金额"
                                                    value={Common.numberFormat(this.state.model.wineAmount,2) || ''}
                                                    onChange={(e) => this.modelChange('wineAmount', e)} />
                                            </Col>
                                        </Row>

                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>总金额：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input className="zhInputNumber"
                                                    min={0}
                                                    disabled
                                                    placeholder="合计金额"
                                                    value={Common.numberFormat(this.state.model.tradeAmount , 2) || ''}
                                                    onChange={(e) => this.modelChange('tradeAmount', e)} />
                                            </Col>
                                            <Col span={4} className='zhTxt'>消费方式：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Select
                                                    style={{ width: "100%" }}
                                                    placeholder="消费方式"
                                                    value={this.state.model.cashierType || ''}
                                                    disabled
                                                >
                                                </Select>
                                            </Col>
                                        </Row>

                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>消费状态：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Select
                                                    style={{ width: "100%" }}
                                                    placeholder="消费状态"
                                                    value={ContextHandler.getMapValue('cashierStatusMap', this.state.model.cashierStatus) || ''}
                                                    disabled
                                                >
                                                </Select>
                                            </Col>
                                            <Col span={4} className='zhTxt'>消费时间：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input className="zhInputNumber"
                                                    min={0}
                                                    disabled
                                                    placeholder="消费时间"
                                                    value={moment(this.state.model.cashierTime).format(Config.dateFormatALL)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='zhRow zhRowButtonRight'>
                                            <Button className="zhButtonRight" icon="rollback" onClick={() => { this.cancel() }}>返回</Button>
                                        </Row>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }

    exportExcel() {
        this.setState({ isExport: true });
        fetch(Config.apiBackServer + "consumptionManagementOfPlatform/exportShopExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "消费管理.xlsx";
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({ isExport: false })
        });
    }
}