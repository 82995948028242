import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Select, DatePicker, Input, Spin, AutoComplete } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import './consumptionReport.css';


export default class ConsumptionReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            isLoading: true,
            isExport: false,
            searchModel: { type: "平台会员消费统计", pageSize: 10, page: 1, },
            funcList: [],
            searchShopList: []
        }
    }

    componentDidMount() {
        this.search();
        this.searchShop();
    }

    searchShop() {
        fetch(Config.apiBackServer + "shop/searchShop", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let list = [];
            if ("SUCCESS" === data.msg) {
                data.object.list.forEach(element => {
                    list.push(element.shopName)
                });
                this.setState({
                    shopNameList:list
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode);
            }
        }).catch(err => { });
    }

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "consumptionReport/consumptionReportSearch", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    
    onSelect(value) {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({
            searchModel: model,
            dataSource: []
        })
    }

    onChangeShop = (value) => {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({ 
            searchModel: model
        });
    }

    onSearch = searchText => {
        let model = this.state.searchModel
        model.shopName = searchText
        let dataSource = [];
        if (searchText) {
            let list = this.state.shopNameList
            for(let i=0; i < list.length; i++) {
                if (list[i].split(searchText).length > 1) {
                    dataSource.push(list[i]);
                }
            }
        }
        this.setState({
            dataSource: dataSource
        })
    };

    reset() {
        this.setState({
            searchModel: { type: "平台会员消费统计", pageSize: 10, page: 1, },
            tables:[],
        }, () => {
            this.search()
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    searchChange(k, e) {
        let { searchModel} = this.state;
        searchModel[k] = e;
        this.setState({ searchModel });
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    selectRadioRow(record) {
        let selectedRowKeys = this.state.selectedRowKeys;
        let checkboxState = this.state.selectedRows;
        let i = checkboxState.indexOf(record);
        if (i > -1) {
            selectedRowKeys = [];
            checkboxState = [];
        } else {
            selectedRowKeys = [record.shopId + record.cardNumber];
            checkboxState = [record];
        }
        this.setState({ selectedRowKeys : selectedRowKeys, selectedRows: checkboxState });
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: "tableWidth15",
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth15",
            },
            {
                title: '会员名称',
                dataIndex: 'nickName',
                className: "tableWidth15",
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: "tableWidth15",
            },
            {
                title: '性别',
                dataIndex: 'sex',
                className: "tableWidth10",
                render: (text) => ContextHandler.getMapValue('sexMap', text)
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmountSum',
                className: "tableWidth15 tableRightAlignment",
                sorter: (a, b) => { return Common.sortMethod(a, b, 'foodAmountSum') },
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmountSum',
                className: "tableWidth15 tableRightAlignment",
                sorter: (a, b) => { return Common.sortMethod(a, b, 'wineAmountSum') },
                render: (text) => Common.numberFormat(text, 2)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="sysFunc">
                <div className="zhRoot">
                    <Spin spinning={this.state.isExport} tip="下载中...">
                        <div className="zhTitle">平台会员消费统计</div>
                        <div className="content">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex}>
                                <TabPane tab="列表" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">店铺名称：</Col>
                                        <Col span={3} className="zhInput">
                                            {/* <Select style={{ width: "100%" }} placeholder="店铺名称" value={this.state.searchModel.shopId || ''}
                                                onChange={(e) => this.searchChange('shopId', e)}>
                                                <Option key="" value="">全部</Option>
                                                {this.state.searchShopList.map((item) => <Option key={item.shopId} value={item.shopId}>{item.shopName}</Option>)}
                                            </Select> */}
                                            <AutoComplete
                                                dropdownMatchSelectWidth={false}//下拉菜单与选择器同宽
                                                style={{ width: '100%' }}
                                                dataSource={this.state.dataSource}
                                                value={this.state.searchModel.shopName || ''}
                                                onSelect={(e) => this.onSelect(e)}
                                                onChange={this.onChangeShop}
                                                onSearch={this.onSearch}
                                                filterOption={true}     //是否根据输入项进行筛选。
                                                placeholder="店铺名称"
                                            />
                                        </Col>
                                        <Col span={2} className="zhTxt">消费日期：</Col>
                                        <Col span={7} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="consumptionManagementDate"
                                                allowClear={false}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("startTime", value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="consumptionManagementDate"
                                                allowClear={false}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endTime", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="会员手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.exportConsumptionExcel()} icon="download">导出</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey={record => record.shopId + record.cardNumber}
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables} //数据
                                            pagination={{  //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total,             //总共多少条数据
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => this.selectRadioRow(record), // 点击行
                                                };
                                            }}
                                        />
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Spin>
                </div>
            </div>
        )
    }

    exportConsumptionExcel() {
        this.setState({ isExport: true });
        fetch(Config.apiBackServer + "consumptionReport/exportConsumptionExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "平台会员消费统计.xlsx";
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    this.cancel();
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({ isExport: false })
        });
    }
}