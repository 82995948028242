import React, { Component } from 'react';
import { Spin, Button, Row, Col, Tabs, Table, Input, Select, Modal, DatePicker } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import context from '../../public/js/context';
import { ContextHandler } from '../../public/js/contextHandler';
import $ from 'jquery'

import './promotionPerformanceStatistics.css';

const confirm = Modal.confirm;
const { TextArea } = Input;
const { Option } = Select;
var css;
export default class PromotionPerformanceStatistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondarySelectedRowKeys: [],
            secondarySelectedRows: [],
            model: {},
            tables: [],
            secondaryList: [],
            secondaryFlag: "add",
            searchModel: { pageSize: 10, page: 1, showShop: 'Y' },
            secondaryModel: { pageSize: 10, page: 1, showShop: 'Y' },
            searchShopList: [],
            paymentAmount: [{}],
            shopFlag: false,
            preservationFlag: false,
            openSpecialGoldCard: false,
            paymentAmount: '',
            statu: '',
            goldCardCount: 0,
            silverCardCount: 0,
            isLoading: true,
            saveFlag: true,//重复点击标志
            secondarySave: true,
            isDown: false,
            provinceList: [],//省
            cityList: [],//市
            areaList: [],//区
            isExport: false,
        }
        this.init()
    }

    init() {
    }

    componentWillMount = () => {
        this.search();
    }

    search() {
        this.setState({ isLoading: true })
        var model = this.state.searchModel;
        fetch(Config.apiBackServer + "promotionPerformanceStatistics/search", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let list = JSON.parse(JSON.stringify(data.object.list.list));
            if ("SUCCESS" === data.msg) {
                model.total = data.object.list.total;
                if(data.object.amountAll != null && data.object.amountAll != undefined){
                    let newModel=data.object.amountAll;
                    newModel.cardNumber="cardNumber";
                    list.push(newModel);
                }
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }


    reset() {
        this.setState({
            searchModel: { page: 1, 
                           pageSize: 10, 
                           cardNumber: "" ,
                           phone :"",
                           openDate:null,
                           endDate:null,
                           performanceSettlementStatus :'',
                           consumptionOpenDate :null,
                           consumptionEndDate:null,
                        },
        }, () => {
            this.cancel();
        })

       
    }

    exportReport() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "promotionPerformanceStatistics/report", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "推广业绩统计" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }
    secondaryOnSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            secondarySelectedRowKeys: selectedRowKeys,
            secondarySelectedRows: selectedRows
        })
    }


    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let module = this.state.selectedRows[0];
        module.pageSize = 10;
        module.page = 1;
        module.openDate=this.state.searchModel.openDate;
        module.endDate=this.state.searchModel.endDate;
        module.consumptionOpenDate =  this.state.searchModel.consumptionOpenDate;
        module.consumptionEndDate = this.state.searchModel.consumptionEndDate;
        module.countAmount = module.noCountAmount + module.passCountAmount;
        module.foodAmount = module.noFoodAmount + module.passFoodAmount;
        module.rebate = module.passAmount;
        module.noRebate = module.noAmount;
        module.amount = module.noAmount+module.passAmount;
        module.performanceSettlementStatus = this.state.searchModel.performanceSettlementStatus;
        console.log(module);
        fetch(Config.apiBackServer + "promotionPerformanceStatistics/searchEdit", {
            method: 'POST',
            body: JSON.stringify(module),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let list = JSON.parse(JSON.stringify(data.object.list.list));
            console.log(list);
            if ("SUCCESS" === data.msg) {
                let newModel=data.object.amountAll;
                if(newModel != null && newModel !=undefined){
                    newModel.id="id";
                    list.push(newModel);
                }
                this.setState({
                    tabIndex: "2",
                    model: module,
                    flag: "edit",
                    secondaryList:list,
                })
            }
        }).catch(err => { });
    }

    searchSecondaryReset(){
        let module = this.state.model;
        module.openDate = null;
        module.endDate = null;
        this.setState({
            model: module,
        })
        this.searchSecondary();
    }

    searchSecondary(){
        let module = this.state.model;
        module.pageSize = 10;
        module.page = 1;
        fetch(Config.apiBackServer + "promotionPerformanceStatistics/searchEdit", {
            method: 'POST',
            body: JSON.stringify(module),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let list = JSON.parse(JSON.stringify(data.object.list.list));
            if ("SUCCESS" === data.msg) {
                let newModel=data.object.amountAll;
                if(newModel != null && newModel !=undefined){
                    newModel.id="id";
                    list.push(newModel);
                }
                this.setState({
                    tabIndex: "2",
                    model: module,
                    flag: "edit",
                    secondaryList:list,
                })
            }
        }).catch(err => { });
    }


    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize-1;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }
    secondaryPageChange = (current, pageSize) => {
        let secondaryModel = this.state.secondaryModel;
        secondaryModel.pageSize = pageSize-1;
        secondaryModel.page = current;
        this.setState({
            secondaryModel: secondaryModel,
        });
        this.editSearch();
    }

    modelChange(k, e) {//需求待定
        let newModel = this.state.model;
        newModel[k] = e;
        console.log(k);
        console.log(e);
        this.setState({
            model: newModel,
        });
    }
    secondaryChange(k, e) {//需求待定
        let newModel = this.state.secondaryModel;
        newModel[k] = e;
        this.setState({
            secondaryModel: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondaryList: [],
            secondarySelectedRows: [],
            secondarySelectedRowKeys: [],
            secondaryFlag: "add",
            searchModel:{}
        })
        this.search()
    }

    tableChange(value, flag) {
        if (flag === "secondary") {
            if (value === "2") {
                if (this.state.secondarySelectedRows.length == 1) {
                    this.secondaryEdit();
                } else {
                    this.secondaryAdd();
                }
            } else {
                this.setState({
                    secondaryTabIndex: value,
                })
                this.secondaryCancel();
            }
        } else {
            if (value === "2") {
                if (this.state.selectedRows.length == 1) {
                    this.edit();
                }
            } else {
                this.setState({
                    tab: value,
                })
                this.cancel();
            }
        }

    }
    downloadCurrentImage1(){
        var model =this.state.model;
        var url = Config.generalizedQRCode +this.state.model.generalizedQRcode;
        model.url = url;
        fetch(Config.apiBackServer + "marketingPromotionStatistics/download", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob().then(blob => {
            if (blob.type == "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" !== data.msg) {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = this.state.model.generalizedQRcode;
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        })).catch(err => ({ err })).finally(() => {
            this.setState({ isDown: false })
        });
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '推广会员卡号',
                dataIndex: 'cardNumber',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">总计</span>,
                            props: {
                                colSpan: 3,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '推广会员手机号',
                dataIndex: 'phone',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '推广卡类型',
                dataIndex: 'cardNumberType',
                className: 'tableWidth10',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return  (Common.isNotEmpty(item.sysRoleCode) && item.sysRoleCode.indexOf("HEAD_RESPONSIBLE") > -1 ? "总部卡" :
                        (Common.isNotEmpty(item.sysRoleCode) && item.sysRoleCode.indexOf("STORE_RESPONSIBLE") > -1 ? "店铺卡" :
                            (text === "Y" ? "金卡" : (text === "N" ? "银卡" : ""))
                        ))
                    }
                }
                   
            },
            {
                title: '消费金额',
                dataIndex: 'noCountAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat((text+item.passCountAmount), 2) }</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return  Common.numberFormat((text+item.passCountAmount), 2) 
                    }
                }
            },
            {
                title: '返现金额',
                dataIndex: 'noFoodAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat((text+item.passFoodAmount), 2) }</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return  Common.numberFormat((text+item.passFoodAmount), 2)
                    }
                }
            },
            {
                title: '推广业绩已返利金额',
                dataIndex: 'passAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{(Common.numberFormat(text, 2))}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return  Common.numberFormat(text, 2) 
                    }
                }
            },
            {
                title: '推广业绩未返利金额',
                dataIndex: 'noAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{(Common.numberFormat(text, 2))}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return  Common.numberFormat(text, 2) 
                    }
                }
            },
            {
                title: '返利总金额',
                dataIndex: 'amount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{(Common.numberFormat((item.passAmount+item.noAmount), 2))}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return  Common.numberFormat((item.passAmount+item.noAmount), 2)
                    }
                }
            }

        ];    
        const secondaryColumns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: <span className="weight">总计</span>,
                            props: {
                                colSpan: 4,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '消费日期',
                dataIndex: 'cashierTime',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return  text == null ? null : moment(text).format(Config.dateFormat)
                    }
                }
            },
            {
                title: '推广结算日期',
                dataIndex: 'promotionEndDate',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return  text == null ? null : moment(text).format(Config.dateFormat)
                    }
                }
            },
            {
                title: '消费金额',
                dataIndex: 'countAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat((text+item.wineAmount), 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat((text+item.wineAmount), 2)
                    }
                }
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat((text), 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat((text), 2)
                    }
                }
            },
            {
                title: '推广业绩返利金额',
                dataIndex: 'rebateAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat((text), 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return item.settlementStatus === 'Y'? Common.numberFormat(item.promotionRebate, 2) : Common.numberFormat(text, 2)
                    }
                }
            },
            {
                title: '推广业绩返利比例',
                dataIndex: 'memberConsumptionCommission',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '结算状态',
                dataIndex: 'settlementStatus',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return text=== 'Y' ? '已结算' : '未结算'
                    }
                }
            },
            {
                title: '消费店铺',
                dataIndex: 'shopName',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
           
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        const secondaryRowSelection = {
            selectedRowKeys: this.state.secondarySelectedRowKeys,
            onChange: this.secondaryOnSelectChange,
            type: 'radio',
        };
        return (
            <div className="promotionPerformanceStatistics">
                <div className="zhRoot">
                    <Spin spinning={this.state.isExport} tip="下载中...">
                    <div className="zhTitle">推广业绩统计</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Spin spinning={this.state.isDown} tip="下载中...">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">推广会员卡号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="推广会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={3} className="zhTxt">推广会员手机号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="推广会员手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">推广结算日期：</Col>
                                        <Col span={5} className="zhInput">
                                            <DatePicker placeholder="开始日期"
                                                className="Picker1"
                                                allowClear={false}
                                                value={this.state.searchModel.openDate == null ? null : moment(new Date(this.state.searchModel.openDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("openDate", value)}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                format={Config.dateFormat} />
                                             &nbsp;至&nbsp;
                                            <DatePicker placeholder="结束日期"
                                                className="Picker1"
                                                allowClear={false}
                                                value={this.state.searchModel.endDate == null ? null : moment(new Date(this.state.searchModel.endDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endDate", value)}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>

                                    <Row className="row">
                                    <Col span={2} className="zhTxt">结算状态：</Col>
                                        <Col span={4} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.searchModel.performanceSettlementStatus || ''}
                                                onChange={(e) => this.searchChange('performanceSettlementStatus', e)} >
                                                <Option value={""}>全部</Option>
                                                {context.settlementStatusMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                        
                                        <Col span={3} className="zhTxt">消费日期：</Col>
                                        <Col span={5} className="zhInput">
                                            <DatePicker placeholder="开始日期"
                                                className="Picker1"
                                                allowClear={false}
                                                value={this.state.searchModel.consumptionOpenDate == null ? null : moment(new Date(this.state.searchModel.consumptionOpenDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("consumptionOpenDate", value)}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                format={Config.dateFormat} />
                                             &nbsp;至&nbsp;
                                            <DatePicker placeholder="结束日期"
                                                className="Picker1"
                                                allowClear={false}
                                                value={this.state.searchModel.consumptionEndDate == null ? null : moment(new Date(this.state.searchModel.consumptionEndDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("consumptionEndDate", value)}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>

                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.edit()} icon="edit">查看</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" type="primary" onClick={() => this.exportReport()} icon="download">导出excel</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey="cardNumber"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables} //数据
                                            pagination={{  //分页
                                                ageSizeOptions: ['11', '21', '31', '41'],
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total + (
                                                    this.state.searchModel.total % this.state.searchModel.pageSize === 0 ?
                                                        parseInt(this.state.searchModel.total / this.state.searchModel.pageSize) :
                                                        parseInt(this.state.searchModel.total / this.state.searchModel.pageSize) + 1), 
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize+ 1,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'cardNumber', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                            rowClassName="tableColor"
                                        />
                                    </Row>
                                </Spin>
                            </TabPane>
                            <TabPane tab="详情" key="2">
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>推广会员卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="推广会员卡号"
                                                value={this.state.model.cardNumber || ''}
                                                onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt' >推广会员手机号：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="推广会员手机号"
                                                disabled
                                                value={this.state.model.phone || ''}
                                                onChange={(e) => this.modelChange('phone', e.target.value)} />
                                        </Col>
                                    </Row>
                                   
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>推广结算日期：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="开始日期"
                                                className="Picker"
                                                allowClear={false}
                                                value={this.state.model.openDate}
                                                onChange={(value) => this.modelChange("openDate", value)}
                                                disabled
                                                format={Config.dateFormat} />
                                                 &nbsp;至&nbsp;
                                            <DatePicker placeholder="结束日期"
                                                className="Picker"
                                                allowClear={false}
                                                value={this.state.model.endDate == null ? null : moment(new Date(this.state.model.endDate), Config.dateFormat)}
                                                disabled
                                                onChange={(value) => this.modelChange("endDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>消费日期：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="开始日期"
                                                className="Picker"
                                                allowClear={false}
                                                value={this.state.model.consumptionOpenDate == null ? null : moment(new Date(this.state.model.consumptionOpenDate), Config.dateFormat)}
                                                onChange={(value) => this.modelChange("consumptionOpenDate", value)}
                                                disabled
                                                format={Config.dateFormat} />
                                                 &nbsp;至&nbsp;
                                            <DatePicker placeholder="结束日期"
                                                className="Picker"
                                                allowClear={false}
                                                value={this.state.model.consumptionEndDate == null ? null : moment(new Date(this.state.model.consumptionEndDate), Config.dateFormat)}
                                                disabled
                                                onChange={(value) => this.modelChange("consumptionEndDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                       
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>消费金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="消费金额"
                                                value={Common.numberFormat(this.state.model.countAmount, 2)  || ''}
                                                onChange={(e) => this.modelChange('countAmount', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>返现金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="返现金额"
                                                value={Common.numberFormat(this.state.model.foodAmount, 2) || ''}
                                                onChange={(e) => this.modelChange('foodAmount', e.target.value)} />
                                        </Col>
                                       
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>推广业绩已返利金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="推广业绩已返利金额"
                                                value={Common.numberFormat(this.state.model.rebate,2)|| ''}
                                                onChange={(e) => this.modelChange('rebate', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>推广业绩未返利金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="推广业绩未返利金额"
                                                value={Common.numberFormat(this.state.model.noRebate,2) || ''}
                                                onChange={(e) => this.modelChange('noRebate', e.target.value)} />
                                        </Col>
                                       
                                    </Row>
                                    <Row className='row'>   
                                        <Col span={4} className='zhTxt'>推广业绩返利比例：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="推广业绩返利比例"
                                                value={this.state.model.memberConsumptionCommission || ''}
                                                onChange={(e) => this.modelChange('memberConsumptionCommission', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>推广业绩返利总金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="推广业绩返利总金额"
                                                value={Common.numberFormat(this.state.model.amount,2) || ''}
                                                onChange={(e) => this.modelChange('amount', e.target.value)} />
                                        </Col>
                                    </Row>

                                    
                                    <Row className='zhRow'>
                                            <Row className='zhRow'></Row>
                                            <Row className='row'>
                                                <Table 
                                                    rowKey="id"
                                                    bordered={true} //边线
                                                    loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                                    columns={secondaryColumns} //列名
                                                    dataSource={this.state.secondaryList} //数据
                                                    pagination={{  //分页
                                                        pageSizeOptions: ['11', '21', '31', '41'],
                                                        showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                        onChange: this.secondaryPageChange,            //上页下页或者跳页方法
                                                        onShowSizeChange: this.secondaryPageChange,    //每页显示多少条数据方法
                                                        total: this.state.secondaryModel.total + (
                                                            this.state.secondaryModel.total % this.state.secondaryModel.pageSize === 0 ?
                                                                parseInt(this.state.secondaryModel.total / this.state.secondaryModel.pageSize) :
                                                                parseInt(this.state.secondaryModel.total / this.state.secondaryModel.pageSize) + 1), 
                                                        current: this.state.secondaryModel.page,         //当前页
                                                        pageSize: this.state.secondaryModel.pageSize+ 1,       //每页显示多少条数据
                                                        defaultCurrent: 1,                               //默认当前页为1
                                                    }}

                                                   
                                                    rowClassName="tableColor"
                                                />
                                            </Row>
                                           
                                        
                                    </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                    </Spin>
                </div>
            </div >
        )
    }


}