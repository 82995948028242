import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, DatePicker, Input, Spin, AutoComplete } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';

export default class ShopCountStatementPlatform extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            searchDayModel: { pageSize: 10, page: 1,  type: "shopCountStatementPlatform" },
            selectedRowKeysDay: [],
            selectedRowsDay: [],
            dayTables: [],
            isLoading: false,
            isExport: false,
            model: { pageSize: 10, page: 1 , type: "shopCountStatementPlatform" },
            selectedRowKeysMonth: [],
            selectedRowsMonth: [],
            monthTables: [],

            searchModel: { pageSize: 10, page: 1, type: "shopCountStatementPlatform" },
            selectedRowKeys: [],
            selectedRows: [],
            tables: [],

            shopNameList: [],
            dataSource: [], // 自动填充数据
        }
    }

    componentDidMount() {
        this.searchDay();
        this.searchShop();
    }

    searchShop() {
        fetch(Config.apiBackServer + "shop/searchShop", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let list = [];
            if ("SUCCESS" === data.msg) {
                data.object.list.forEach(element => {
                    list.push(element.shopName)
                });
                this.setState({
                    shopNameList:list
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode);
            }
        }).catch(err => { });
    }

    // 日报表
    searchDay() {
        this.setState({ isLoading: true })
        let model = this.state.searchDayModel;
        fetch(Config.apiBackServer + "shopCountStatement/searchDay", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
            this.setState({
                searchDayModel: model,
                dayTables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    resetDay() {
        this.setState({
            searchDayModel: { pageSize: 10, page: 1,  type: "shopCountStatementPlatform" },
            selectedRowKeysDay: [],
            selectedRowsDay: [],
            dayTables:[],
        }, () => {
            this.searchDay();
        })
    }

    searchDayChange(k, e) {
        let { searchDayModel} = this.state;
        searchDayModel[k] = e;
        this.setState({ searchDayModel });
    }

    onSelectChangeDay = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeysDay: selectedRowKeys,
            selectedRowsDay: selectedRows
        })
    }

    dayPageChange = (current, pageSize) => {
        let searchModel = this.state.searchDayModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchDayModel: searchModel,
        });
        this.searchDay();
    }

    selectDayRadioRow(record) {
        let selectedRowKeys = this.state.selectedRowKeysDay;
        let checkboxState = this.state.selectedRowsDay;
        let i = checkboxState.indexOf(record);
        if (i > -1) {
            selectedRowKeys = [];
            checkboxState = [];
        } else {
            selectedRowKeys = [ record.shopName + record.cashierTime + record.cardNumber + record.remark];
            checkboxState = [record];
        }
        this.setState({ selectedRowKeysDay : selectedRowKeys, selectedRowsDay: checkboxState });
    }

    onDaySelect(value) {
        let model = this.state.searchDayModel
        model.shopName = value
        this.setState({
            searchDayModel: model,
            dataSource: []
        })
    }

    onDayChangeShop = (value) => {
        let model = this.state.searchDayModel
        model.shopName = value
        this.setState({ 
            searchDayModel: model
        });
    }

    onDaySearch = searchText => {
        let model = this.state.searchDayModel
        model.shopName = searchText
        let dataSource = [];
        if (searchText) {
            let list = this.state.shopNameList
            for(let i=0; i < list.length; i++) {
                if (list[i].split(searchText).length > 1) {
                    dataSource.push(list[i]);
                }
            }
        }
        this.setState({
            dataSource: dataSource
        })
    };

    tableChange(value){
        this.setState({
            tabIndex: value,
            dataSource: [],
        }, () => {
            if(value == 2){
                this.resetMonth();
            } else if (value == 3) {
                this.reset();
            } else {
            }
        })
    }

    // 每一个月
    searchMonth() {
        this.setState({ isLoading: true })
        let model = this.state.model;
        fetch(Config.apiBackServer + "shopCountStatement/searchMonth", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
            this.setState({
                model: model,
                monthTables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({
            model: newModel
        });
    }

    resetMonth() {
        this.setState({
            model: { pageSize: 10, page: 1 , type: "shopCountStatementPlatform" },
            selectedRowKeysMonth: [],
            selectedRowsMonth: [],
            monthTables: [],
        }, () => {
            this. searchMonth();
        })
    }

    onSelectChangeMonth = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeysMonth: selectedRowKeys,
            selectedRowsMonth: selectedRows
        })
    }

    onMonthSelect(value) {
        let model = this.state.model
        model.shopName = value
        this.setState({
            model: model,
            dataSource: []
        })
    }

    onMonthChangeShop = (value) => {
        let model = this.state.model
        model.shopName = value
        this.setState({ 
            model: model
        });
    }

    onMonthSearch = searchText => {
        let model = this.state.model
        model.shopName = searchText
        let dataSource = [];
        if (searchText) {
            let list = this.state.shopNameList
            for(let i=0; i < list.length; i++) {
                if (list[i].split(searchText).length > 1) {
                    dataSource.push(list[i]);
                }
            }
        }
        this.setState({
            dataSource: dataSource
        })
    };

    monthPageChange = (current, pageSize) => {
        let model = this.state.model;
        model.pageSize = pageSize;
        model.page = current;
        this.setState({
            model: model,
        });
        this.searchMonth();
    }

    selectMonthRadioRow(record) {
        let selectedRowKeys = this.state.selectedRowKeysMonth;
        let checkboxState = this.state.selectedRowsMonth;
        let i = checkboxState.indexOf(record);
        if (i > -1) {
            selectedRowKeys = [];
            checkboxState = [];
        } else {
            selectedRowKeys = [record.shopName + record.monthTime + record.cardNumber + record.remark];
            checkboxState = [record];
        }
        this.setState({ selectedRowKeysMonth : selectedRowKeys, selectedRowsMonth: checkboxState });
    }

    // 详细
    search() {
        this.setState({ isLoading: true })
        let model = this.state.searchModel;
        fetch(Config.apiBackServer + "shopCountStatement/search", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    reset() {
        this.setState({
            searchModel: { pageSize: 10, page: 1, type: "shopCountStatementPlatform" },
            selectedRowKeys: [],
            selectedRows: [],
            tables: [],
        }, () => {
            this.search();
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    pageChange = (current, pageSize) => {
        let model = this.state.searchModel;
        model.pageSize = pageSize;
        model.page = current;
        this.setState({
            searchModel: model,
        });
        this.search();
    }

    onSelect(value) {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({
            searchModel: model,
            dataSource: []
        })
    }

    onChangeShop = (value) => {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({ 
            searchModel: model
        });
    }

    onSearch = searchText => {
        let model = this.state.searchModel
        model.shopName = searchText
        let dataSource = [];
        if (searchText) {
            let list = this.state.shopNameList
            for(let i=0; i < list.length; i++) {
                if (list[i].split(searchText).length > 1) {
                    dataSource.push(list[i]);
                }
            }
        }
        this.setState({
            dataSource: dataSource
        })
    };

    render() {
        const { TabPane } = Tabs;
        const { MonthPicker } = DatePicker;

        // 日报表
        const rowSelectionDay = {
            selectedRowKeys: this.state.selectedRowKeysDay,
            onChange: this.onSelectChangeDay,
            type: 'radio',
        };

        // 月报表
        const rowSelectionMonth = {
            selectedRowKeys: this.state.selectedRowKeysMonth,
            onChange: this.onSelectChangeMonth,
            type: 'radio',
        };

        // 详细
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        const dayColumns = [
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: "tableWidth12",
            },
            {
                title: '消费日期',
                dataIndex: 'cashierTime',
                className: "tableWidth14",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '消费总金额',
                className: "tableWidth12 tableRightAlignment",
                dataIndex: 'tradeAmount',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmount',
                className: "tableWidth12 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmount',
                className: "tableWidth12 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '会员奖励',
                dataIndex: 'rebateAmount',
                className: "tableWidth12 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '店铺到账',
                dataIndex: 'paymentAmount',
                className: "tableWidth12 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '折扣率',
                dataIndex: 'discount',
                className: "tableWidth12 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
        ];

        const monthColumns = [
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: "tableWidth12",
            },
            {
                title: '消费日期',
                dataIndex: 'monthTime',
                className: "tableWidth14",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.monthFormat)}</span>,
            },
            {
                title: '消费总金额',
                className: "tableWidth12 tableRightAlignment",
                dataIndex: 'tradeAmount',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmount',
                className: "tableWidth12 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmount',
                className: "tableWidth12 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '会员奖励',
                dataIndex: 'rebateAmount',
                className: "tableWidth12 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '店铺到账',
                dataIndex: 'paymentAmount',
                className: "tableWidth12 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '折扣率',
                dataIndex: 'discount',
                className: "tableWidth12 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
        ];

        const columns = [
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: "tableWidth9",
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth9",
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: "tableWidth9",
            },
            {
                title: '消费日期',
                dataIndex: 'cashierTime',
                className: "tableWidth10",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '消费总金额',
                className: "tableWidth9 tableRightAlignment",
                dataIndex: 'tradeAmount',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmount',
                className: "tableWidth9 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmount',
                className: "tableWidth9 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '会员奖励',
                dataIndex: 'rebateAmount',
                className: "tableWidth9 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '店铺到账',
                dataIndex: 'paymentAmount',
                className: "tableWidth9 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '折扣率',
                dataIndex: 'discount',
                className: "tableWidth9 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '备注',
                dataIndex: 'remark',
                className: "tableWidth9 tableRightAlignment",
            },
        ];

        return(
            <div className="shopCountStatement">
                <div className="zhRoot">
                    <div className="zhTitle">店铺总报表</div>
                    <div className="content">
                        <Spin spinning={this.state.isExport} tip="下载中...">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="日报表" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">店铺名称：</Col>
                                        <Col span={3} className="zhInput">
                                             <AutoComplete
                                                dropdownMatchSelectWidth={false}//下拉菜单与选择器同宽
                                                style={{ width: '100%' }}
                                                dataSource={this.state.dataSource}
                                                value={this.state.searchDayModel.shopName || ''}
                                                onSelect={(e) => this.onDaySelect(e)}
                                                onChange={this.onDayChangeShop}
                                                onSearch={this.onDaySearch}
                                                filterOption={true}     //是否根据输入项进行筛选。
                                                placeholder="店铺名称"
                                            />
                                        </Col>
                                        <Col span={2} className="zhTxt">消费日期：</Col>
                                        <Col span={6} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                allowClear={true}
                                                className="shopCountStatementDatePicker"
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchDayModel.startTime == null ? null : moment(new Date(this.state.searchDayModel.startTime), Config.dateFormat)}
                                                onChange={(value) => this.searchDayChange("startTime", value)}
                                                format={Config.dateFormat}/>
                                            &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                allowClear={true}
                                                className="shopCountStatementDatePicker"
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchDayModel.endTime == null ? null : moment(new Date(this.state.searchDayModel.endTime), Config.dateFormat)}
                                                onChange={(value) => this.searchDayChange("endTime", value)}
                                                format={Config.dateFormat}/>
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.searchDay()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.resetDay()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.exportDayPlatformExcel()} icon="download">导出</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                    <Table rowSelection={rowSelectionDay}
                                            rowKey={record =>record.shopName + record.cashierTime + record.cardNumber + record.remark}
                                            bordered={true} //边线
                                            columns={dayColumns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.dayTables} //数据
                                            pagination={{  //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.dayPageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.dayPageChange,    //每页显示多少条数据方法
                                                total: this.state.searchDayModel.total,             //总共多少条数据
                                                current: this.state.searchDayModel.page,         //当前页
                                                pageSize: this.state.searchDayModel.pageSize,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => this.selectDayRadioRow(record) // 点击行
                                                };
                                            }}
                                        />
                                    </Row>
                                </TabPane>
                                <TabPane tab="月报表" key="2">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">店铺名称：</Col>
                                        <Col span={3} className="zhInput">
                                             <AutoComplete
                                                dropdownMatchSelectWidth={false}//下拉菜单与选择器同宽
                                                style={{ width: '100%' }}
                                                dataSource={this.state.dataSource}
                                                value={this.state.model.shopName || ''}
                                                onSelect={(e) => this.onMonthSelect(e)}
                                                onChange={this.onMonthChangeShop}
                                                onSearch={this.onMonthSearch}
                                                filterOption={true}     //是否根据输入项进行筛选。
                                                placeholder="店铺名称"
                                            />
                                        </Col>
                                        <Col span={2} className="zhTxt">消费日期：</Col>
                                        <Col span={6} className="zhInput">
                                            <MonthPicker placeholder="由日期"
                                                allowClear={true}
                                                className="shopCountStatementDatePicker"
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMM") > Common.dateFormat(new Date(), "yyyyMM")}
                                                value={this.state.model.startTime == null ? null : moment(new Date(this.state.model.startTime), Config.monthFormat)}
                                                onChange={(value) => this.modelChange("startTime", value)}
                                                format={Config.monthFormat}/>
                                            &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <MonthPicker placeholder="至日期"
                                                allowClear={true}
                                                className="shopCountStatementDatePicker"
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMM") > Common.dateFormat(new Date(), "yyyyMM")}
                                                value={this.state.model.endTime == null ? null : moment(new Date(this.state.model.endTime), Config.monthFormat)}
                                                onChange={(value) => this.modelChange("endTime", value)}
                                                format={Config.monthFormat}/>
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.searchMonth()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.resetMonth()} icon="redo">重置</Button>
                                        </Col>
                                    </Row> 
                                    <Row >
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.exportMonthPlatformExcel()} icon="download">导出</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelectionMonth}
                                            rowKey={record => record.shopName + record.monthTime + record.cardNumber + record.remark}
                                            bordered={true} //边线
                                            columns={monthColumns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.monthTables} //数据
                                            pagination={{  //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.monthPageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.monthPageChange,    //每页显示多少条数据方法
                                                total: this.state.model.total,             //总共多少条数据
                                                current: this.state.model.page,         //当前页
                                                pageSize: this.state.model.pageSize,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => this.selectMonthRadioRow(record) // 点击行
                                                };
                                            }}
                                        />
                                    </Row>
                                </TabPane>
                                <TabPane tab="详细" key="3">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="会员手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">消费日期：</Col>
                                        <Col span={6} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                allowClear={true}
                                                className="shopCountStatementDatePicker"
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("startTime", value)}
                                                format={Config.dateFormat}/>
                                            &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                allowClear={true}
                                                className="shopCountStatementDatePicker"
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endTime", value)}
                                                format={Config.dateFormat}/>
                                        </Col>
                                    </Row>
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">店铺名称：</Col>
                                        <Col span={3} className="zhInput">
                                             <AutoComplete
                                                dropdownMatchSelectWidth={false}//下拉菜单与选择器同宽
                                                style={{ width: '100%' }}
                                                dataSource={this.state.dataSource}
                                                value={this.state.searchModel.shopName || ''}
                                                onSelect={(e) => this.onSelect(e)}
                                                onChange={this.onChangeShop}
                                                onSearch={this.onSearch}
                                                filterOption={true}     //是否根据输入项进行筛选。
                                                placeholder="店铺名称"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.exportPlatformExcel()} icon="download">导出</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                            rowKey="cashierId"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables} //数据
                                            pagination={{  //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total,             //总共多少条数据
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'cashierId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                        />
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }

    exportDayPlatformExcel() {
        this.setState({ isExport: true });
        fetch(Config.apiBackServer + "shopCountStatement/exportDayPlatformExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.searchDayModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "店铺日报表" + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    this.cancel();
                }
            }
        }).catch(err => ({ err })).finally(() => { this.setState({ isExport: false })});
    }

    exportMonthPlatformExcel() {
        this.setState({ isExport: true });
        fetch(Config.apiBackServer + "shopCountStatement/exportMonthPlatformExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "店铺月报表" + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    this.cancel();
                }
            }
        }).catch(err => ({ err })).finally(() => { this.setState({ isExport: false })});
    }

    exportPlatformExcel() {
        this.setState({ isExport: true });
        fetch(Config.apiBackServer + "shopCountStatement/exportPlatformExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                      //  this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "店铺详细报表" + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    this.cancel();
                }
            }
        }).catch(err => ({ err })).finally(() => { this.setState({ isExport: false })});
    }
}