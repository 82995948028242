import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Select, DatePicker } from 'antd';
import moment from 'moment';//日期格式化导包

import ExportJsonExcel from 'js-export-excel';
import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import './platformStoresStatistics.css';


export default class PlatformStoresStatistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            searchModel: { type: "平台会员消费统计", pageSize: 10, page: 1, },
            funcList: [],
            isLoading: false,
            searchShopList: []
        }
        this.init()
    }

    init() {
        this.search();
        this.searchShop();
    }

    search() {
        this.setState({ isLoading: true })
        let model = this.state.searchModel;
        fetch(Config.apiBackServer + "consumptionReport/platformStoresStatisticsSearch", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchShop() {
        fetch(Config.apiBackServer + "openMembership/searchShop", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({
                    searchShopList: data.object.list
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode);
            }
        }).catch(err => { });
    };

    reset() {
        this.setState({
            searchModel: { type: "平台会员消费统计", page: 1, pageSize: 10, startTime: null, endTime: null, shopId: "" },
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    searchChange(k, e) {
        let { searchModel, searchShopList } = this.state;
        searchModel[k] = e;
        if ('shopId' === k) {
            if (Common.isNotEmpty(e)) {
                let index = searchShopList.findIndex((item) => item.shopId === e);
                searchModel.shopName = searchShopList[index].shopName;
            } else {
                searchModel.shopName = null;
            }
        }
        this.setState({ searchModel });
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    downloadTableExcel() {
        const data = this.state.tables ? this.state.tables : '';//表格数据
        var option = {};
        let dataTable = [];
        if (data) {
            for (let i in data) {
                if (data) {
                    let obj = {
                        '店铺名称': data[i].shopName,
                        '店铺手机号': data[i].shopPhone,
                        '营业时间': data[i].businessDate,
                        '返现金额': data[i].foodAmountSum,
                        '不返现金额': data[i].wineAmountSum,
                    }
                    dataTable.push(obj);
                }
            }
        }
        option.fileName = '平台店铺消费统计' + moment(new Date()).format(Config.dateFormat);
        option.datas = [{
            sheetData: dataTable,
            sheetName: 'sheet',
            sheetFilter: ['店铺名称', '店铺手机号', '营业时间', '返现金额', '不返现金额'],
            sheetHeader: ['店铺名称', '店铺手机号', '营业时间', '返现金额', '不返现金额'],
            columnWidths: ['10', '10', '10', '10', '10',], // 列宽
        }];
        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '店铺名称',
                dataIndex: 'shopName',
            },
            {
                title: '店铺手机号',
                dataIndex: 'shopPhone',
            },
            {
                title: '营业时间',
                dataIndex: 'businessDate',
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmountSum',
                className: "tableRightAlignment",
                sorter: (a, b) => { return Common.sortMethod(a, b, 'wineAmountSum') },
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmountSum',
                className: "tableRightAlignment",
                sorter: (a, b) => { return Common.sortMethod(a, b, 'wineAmountSum') },
                render: (text) => Common.numberFormat(text, 2)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="platformStoresStatistics">
                <div className="zhRoot">
                    <div className="zhTitle">平台店铺消费统计</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">店铺名称：</Col>
                                    <Col span={3} className="zhInput">
                                        <Select style={{ width: "100%" }} placeholder="店铺名称" value={this.state.searchModel.shopId || ''}
                                            onChange={(e) => this.searchChange('shopId', e)}>
                                            <option key="" value=""></option>
                                            {this.state.searchShopList.map((item) => <option key={item.shopId} value={item.shopId}>{item.shopName}</option>)}
                                        </Select>
                                    </Col>
                                    <Col span={2} className="zhTxt">日期：</Col>
                                    <Col span={3} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("startTime", value)}
                                            format={Config.dateFormat} />
                                    </Col>

                                    <Col span={2} className="zhTxt">至</Col>
                                    <Col span={3} className="zhInput">
                                        <DatePicker placeholder="至日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("endTime", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <Button type="primary" onClick={() => this.downloadTableExcel()} icon="download">导出Excel表格</Button>
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="id"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                    />
                                </Row>

                            </TabPane>

                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }

    exportConsumptionExcel() {
        fetch(Config.apiBackServer + "consumptionReport/exportConsumptionExcelByShopId", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "平台店铺消费统计.xlsx";
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    this.cancel();
                }
            }
        }).catch(err => ({ err })).finally(() => {

        });
    }

}