import React, { Component } from 'react';
import { Spin, Button, Row, Col, Tabs, Table, Input, Select, Modal, DatePicker ,Upload,Icon} from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import context from '../../public/js/context';
import { ContextHandler } from '../../public/js/contextHandler';
import $ from 'jquery'

import './promotionPerformanceDetails.css';

const confirm = Modal.confirm;
const { TextArea } = Input;
const { Option } = Select;
var css;
export default class PromotionPerformanceDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondarySelectedRowKeys: [],
            secondarySelectedRows: [],
            model: {},
            tables: [],
            secondaryList: [],
            secondaryFlag: "add",
            searchModel: { pageSize: 10, page: 1, showShop: 'Y' },
            secondaryModel: { pageSize: 10, page: 1, showShop: 'Y' },
            searchShopList: [],
            paymentAmount: [{}],
            shopFlag: false,
            preservationFlag: false,
            openSpecialGoldCard: false,
            paymentAmount: '',
            statu: '',
            goldCardCount: 0,
            silverCardCount: 0,
            isLoading: true,
            saveFlag: true,//重复点击标志
            secondarySave: true,
            isDown: false,
            provinceList: [],//省
            cityList: [],//市
            areaList: [],//区
            isExport: false,

            previewVisibleZFB:false,
            imageFileZFB: [],
            ImageNumZFB: 1,
            previewVisibleWX:false,
            imageFileWX: [],
            ImageNumWX: 1,
            imageFile: [],
            ImageNum: 1,
            previewVisible: false,
            imageFileMember: [],
            ImageNumMember: 1,
            previewVisibleMember: false,
        }
        this.init()
    }

    init() {
    }

    componentWillMount = () => {
        this.search();
    }


    search() {
        this.setState({ isLoading: true })
        console.log(this.state.searchModel);
        // return;
        fetch(Config.apiBackServer + "promotionPerformanceDetails/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            let list = JSON.parse(JSON.stringify(data.object.list.list));
            console.log(data);
            if ("SUCCESS" === data.msg) {
                model.total = data.object.list.total;
                if(data.object.amountAll != null && data.object.amountAll != undefined){
                    let newModel=data.object.amountAll;
                    newModel.cardNumber="cashierId";
                    list.push(newModel);
                }
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: list
            })
            console.log(this.state.tables);
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    handlePreviewZFB = (file) => {
        this.setState({
            previewImageZFB: file.url || file.thumbUrl,
            previewVisibleZFB: true,
        });
    }

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    handlePreviewWX = (file) => {
        this.setState({
            previewImageWX: file.url || file.thumbUrl,
            previewVisibleWX: true,
        });
    }

    handlePreviewMember = (file) => {
        this.setState({
            previewImageMember: file.url || file.thumbUrl,
            previewVisibleMember: true,
        });
    }

    handleCancel2 (){
        this.setState({ previewVisibleZFB: false })
      } 
    handleCancel3 (){
    this.setState({ previewVisibleWX: false })
    } 
    handleCancel (){
        this.setState({ previewVisible: false })
     } 

    handleCancelMember (){
    this.setState({ previewVisibleMember: false })
    } 

    handleChangeMember(file) {
        this.setState({
            imageFileMember: file.fileList
        })
    }

    handleChangeZFB(file) {
        this.setState({
            imageFileZFB: file.fileList
        })
    }

    handleChangeWX(file) {
        this.setState({
            imageFileWX: file.fileList
        })
    }

    handleChange(file) {
        this.setState({
            imageFile: file.fileList
        })
    }

    
    reset() {
        var model = this.state.searchModel;
        model.settlementStatus ='';
        model.promotionCardNumber='';
        model.promotionPhone ='';
        model.cardNumber='';
        model.phone='';
        model.openDate=null;
        model.endDate=null;
        this.setState({
            searchModel: { page: 1, pageSize: 10, searchModel: model},
        })
        this.cancel();
    }

    exportReport() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "promotionPerformanceDetails/exportReport", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "推广业绩明细" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }
    secondaryOnSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            secondarySelectedRowKeys: selectedRowKeys,
            secondarySelectedRows: selectedRows
        })
    }


    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let module = this.state.selectedRows[0];
        var weChatAddress = module.weChatAddress;
        let file1 = [{ uid: '-1', }]
        if (Common.isNotEmpty(weChatAddress) && weChatAddress.indexOf('http') != -1) {
            file1[0].url = weChatAddress;
            file1[0].name = weChatAddress;
        } else if (Common.isNotEmpty(weChatAddress) && weChatAddress.indexOf('http') == -1) {
            file1[0].url = Config.consumptionRebate + weChatAddress;
            file1[0].name = weChatAddress;
        } else {
            file1 = []
        }
        var alipayAddress = module.alipayAddress;
        let file2 = [{ uid: '-1', }]
        if (Common.isNotEmpty(alipayAddress) && alipayAddress.indexOf('http') != -1) {
            file2[0].url = alipayAddress;
            file2[0].name = alipayAddress;
        } else if (Common.isNotEmpty(alipayAddress) && alipayAddress.indexOf('http') == -1) {
            file2[0].url = Config.consumptionRebate + alipayAddress;
            file2[0].name = alipayAddress;
        } else {
            file2 = []
        }
        var settlementVoucher = module.settlementVoucher;
        let file3 = [{ uid: '-1', }]
        if (Common.isNotEmpty(settlementVoucher) && settlementVoucher.indexOf('http') != -1) {
            file3[0].url = settlementVoucher;
            file3[0].name = settlementVoucher;
        } else if (Common.isNotEmpty(settlementVoucher) && settlementVoucher.indexOf('http') == -1) {
            file3[0].url = Config.consumptionRebate + settlementVoucher;
            file3[0].name = settlementVoucher;
        } else {
            file3 = []
        }

        var headPortrait = module.headPortrait;
        let file4 = [{ uid: '-1', }]
        if (Common.isNotEmpty(headPortrait) && headPortrait.indexOf('http') != -1) {
            file4[0].url = headPortrait;
            file4[0].name = headPortrait;
        } else if (Common.isNotEmpty(headPortrait) && headPortrait.indexOf('http') == -1) {
            file4[0].url = Config.headPortraitImgs + headPortrait;
            file4[0].name = headPortrait;
        } else {
            file4 = []
        }
        if(Common.isEmpty(module.settlementStatus)){
            module.settlementStatus ='N';
        }
         if('Y' === module.settlementStatus){
            module.amount = Common.numberFormat(module.promotionRebate, 2);
        }else if('N' === module.settlementStatus){
            module.amount =Common.numberFormat(module.amount, 2)
        }
        this.setState({
            tabIndex: "2",
            model: module,
            flag: "edit",
            imageFileWX: file1,
            imageFileZFB: file2,
            imageFile:file3,
            imageFileMember:file4,
        })
    }

    searchSecondaryReset(){
        let module = this.state.model;
        module.openDate = null;
        module.endDate = null;
        this.setState({
            model: module,
        })
        this.searchSecondary();
    }

    searchSecondary(){
        let module = this.state.model;
        module.pageSize = 10;
        module.page = 1;
        fetch(Config.apiBackServer + "marketingPromotionStatistics/searchEdit", {
            method: 'POST',
            body: JSON.stringify(module),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({
                    tabIndex: "2",
                    model: module,
                    flag: "edit",
                    secondaryList:data.object.list,
                })
            }
        }).catch(err => { });
    }

    goldenCardExport() {
        this.setState({ isDown: true })
        fetch(Config.apiBackServer + "openMembership/goldenCardExport", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob().then(blob => {
            if (blob.type == "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" !== data.msg) {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "企业金卡" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + ".xlsx"
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        })).catch(err => ({ err })).finally(() => {
            this.setState({ isDown: false })
        });
    }



    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize-1;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        }, () => {
            console.log(searchModel);
            this.search();
        })
       
    }
    secondaryPageChange = (current, pageSize) => {
        let secondaryModel = this.state.secondaryModel;
        secondaryModel.pageSize = pageSize;
        secondaryModel.page = current;
        this.setState({
            secondaryModel: secondaryModel,
        });
        this.editSearch();
    }

    modelChange(k, e) {//需求待定
        let newModel = this.state.model;
        newModel[k] = e;
        console.log(k);
        console.log(e);
        this.setState({
            model: newModel,
        });
    }
    secondaryChange(k, e) {//需求待定
        let newModel = this.state.secondaryModel;
        newModel[k] = e;
        this.setState({
            secondaryModel: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondaryList: [],
            secondarySelectedRows: [],
            secondarySelectedRowKeys: [],
            secondaryFlag: "add"
        })
        this.search()
    }

    tableChange(value, flag) {
        if (flag === "secondary") {
            if (value === "2") {
                if (this.state.secondarySelectedRows.length == 1) {
                    this.secondaryEdit();
                } else {
                    this.secondaryAdd();
                }
            } else {
                this.setState({
                    secondaryTabIndex: value,
                })
                this.secondaryCancel();
            }
        } else {
            if (value === "2") {
                if (this.state.selectedRows.length == 1) {
                    this.edit();
                }
                // else {
                //     this.add();
                // }
            } else {
                this.setState({
                    tab: value,
                })
                this.cancel();
            }
        }

    }
    downloadCurrentImage1(){
        var model =this.state.model;
        var url = Config.generalizedQRCode +this.state.model.generalizedQRcode;
        model.url = url;
        fetch(Config.apiBackServer + "marketingPromotionStatistics/download", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob().then(blob => {
            if (blob.type == "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" !== data.msg) {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = this.state.model.generalizedQRcode;
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        })).catch(err => ({ err })).finally(() => {
            this.setState({ isDown: false })
        });
    }

    /**download current image as PNG */
    downloadCurrentImage() {
        const browserName = this.getBrowser();
        console.log(browserName);
        //在我的项目中获取imageSrc是这样的，请根据自己的项目内容来写；
        var url = Config.generalizedQRCode +this.state.model.generalizedQRcode;
        this.convertUrlToBase64(url).then((base64) => {
        if (browserName === "IE" || browserName === "Edge") {
            const blob = this.convertBase64UrlToBlob(base64);
            //第二个参数是下载文件名
            window.navigator.msSaveBlob(blob,this.state.model.generalizedQRcode);
        } else {
            const a = document.createElement("a");
            console.log(a);
            //下载文件名：我是用id
            a.download = this.state.model.generalizedQRcode;
            a.href = url;
            a.click();
        }
        });
    }

    /** get current browser */
   getBrowser() {
        const userAgent = navigator.userAgent;
        const browserArr = ["Opera", "IE", "Edge", "FF", "Safari", "Chrome"];
        const isOpera = userAgent.includes("Opera");
        //IE
        const isIE = userAgent.includes("compatible") && userAgent.includes("MSIE") && !isOpera;
        const isEdge = userAgent.includes("Edge");
        const isFF = userAgent.includes("Firefox");
        // safari
        const isSafari = userAgent.includes("Safari") && !userAgent.includes("Chrome");
        // chrome
        const isChrome = userAgent.includes("Chrome") && userAgent.includes("Safari");
        const agentArr = [isOpera, isIE, isEdge, isFF, isSafari, isChrome];
        for (let i = 0; i < agentArr.length; i++) {
            if (agentArr[i] === true) {
                return browserArr[i];
            }
        }
    }

     /**
   * convert image's base64 url to Blob
   * @param base64`string` image's base64 url
   */
   convertBase64UrlToBlob(base64) {
    //baseArr[0]: head of base64, image's type(eg: "image/png")
    const baseArr = base64.split(",");
    //baseArr[1]: after removing the head of base64, the rest of content
    const newCode = window.atob(baseArr[1]);
    const newArr = new Uint8Array(newCode.length);
    for (let i = 0; i < newCode.length; i++) {
      newArr[i] = newCode.charCodeAt(i);
    }
    //type可以根据需求修改
    return new Blob([newArr], {type: "image/png"});
  }

  /**
   * convert image's url to base64
   * @param url `string` image's url
   */
   convertUrlToBase64(url) {
    return new Promise((resolve, reject) => {
      const images = new Image();
      images.crossOrigin = "Anonymous";
      images.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = images.width;
        canvas.height = images.height;
        const ctx = canvas.getContext("2d") ;
        ctx.drawImage(images, 0, 0, images.width, images.height);
        resolve(canvas.toDataURL("image/png", 1));
      };
      images.onerror = () => {
        reject(new Error("get image error"));
      };
      images.src = url;
    });
  }






    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const uploadButtonZFB = (
            <div>
                <Icon type="plus" />
                <div>支付宝收款码</div>
            </div>
        );
        const uploadButtonWX = (
            <div>
                <Icon type="plus" />
                <div>微信收款码</div>
            </div>
        );
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div>结算凭证</div>
            </div>
        );
        const uploadButtonMember = (
            <div>
                <Icon type="plus" />
                <div>会员头像</div>
            </div>
        );
        const columns = [
            {
                title: '推广会员卡号',
                dataIndex: 'promotionCardNumber',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">总计</span>,
                            props: {
                                colSpan: 4,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '推广会员手机号',
                dataIndex: 'promotionPhone',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '消费金额',
                dataIndex: 'consumptionAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat((text), 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat(text, 2)
                    }
                }
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat((text), 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat(text, 2)
                    }
                }
            },
            {
                title: '推广返利金额',
                dataIndex: 'amount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat((text), 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat(text, 2)
                    }
                }
            },
            {
                title: '结算状态',
                dataIndex: 'settlementStatus',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return text == 'Y' ? "已结算" : "未结算"
                    }
                }
            },
            {
                title: '消费日期',
                dataIndex: 'cashierTime',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return  text == null ? null : moment(text).format(Config.dateFormat)
                    }
                }
            },
            {
                title: '结算日期',
                dataIndex: 'promotionEndDate',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return  text == null ? null : moment(text).format(Config.dateFormat)
                    }
                }
            },

        ];    
      
           

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        const secondaryRowSelection = {
            selectedRowKeys: this.state.secondarySelectedRowKeys,
            onChange: this.secondaryOnSelectChange,
            type: 'radio',
        };
        return (
            <div className="promotionPerformanceDetails">
                <div className="zhRoot">
                    <Spin spinning={this.state.isExport} tip="下载中...">
                    <div className="zhTitle">推广业绩明细</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Spin spinning={this.state.isDown} tip="下载中...">
                                <Row className="row">
                                        <Col span={2} className="zhTxt">推广会员卡号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="推广会员卡号"
                                                value={this.state.searchModel.promotionCardNumber || ''}
                                                onChange={(e) => this.searchChange('promotionCardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={3} className="zhTxt">推广会员手机号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="推广会员手机号"
                                                value={this.state.searchModel.promotionPhone || ''}
                                                onChange={(e) => this.searchChange('promotionPhone', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">结算状态：</Col>
                                        <Col span={4} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.searchModel.settlementStatus || ''}
                                                onChange={(e) => this.searchChange('settlementStatus', e)} >
                                                <Option value={""}>全部</Option>
                                                {context.settlementStatusMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                    </Row>

                                    <Row className="row">
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={3} className="zhTxt">会员手机号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="会员手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">消费日期：</Col>
                                        <Col span={5} className="zhInput">
                                            <DatePicker placeholder="开始日期"
                                                className="datePicker1"
                                                allowClear={false}
                                                value={this.state.searchModel.openDate == null ? null : moment(new Date(this.state.searchModel.openDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("openDate", value)}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                format={Config.dateFormat} />
                                                &nbsp;至&nbsp;
                                                <DatePicker placeholder="结束日期"
                                                className="datePicker1"
                                                allowClear={false}
                                                value={this.state.searchModel.endDate == null ? null : moment(new Date(this.state.searchModel.endDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endDate", value)}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>

                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.edit()} icon="edit">查看</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" type="primary" onClick={() => this.exportReport()} icon="download">导出excel</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey="cashierId"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables} //数据
                                            pagination={{  //分页
                                                pageSizeOptions: ['11', '21', '31', '41'],
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total + (
                                                    this.state.searchModel.total % this.state.searchModel.pageSize === 0 ?
                                                        parseInt(this.state.searchModel.total / this.state.searchModel.pageSize) :
                                                        parseInt(this.state.searchModel.total / this.state.searchModel.pageSize) + 1), 
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize+1,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'cashierId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                            rowClassName="tableColor"
                                        />
                                    </Row>
                                </Spin>
                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Modal title="放大图" visible={this.state.previewVisibleZFB} footer={null} onCancel={()=>this.handleCancel2()}>
                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImageZFB} />
                                </Modal>
                                <Modal title="放大图" visible={this.state.previewVisibleWX} footer={null} onCancel={()=>this.handleCancel3()}>
                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImageWX} />
                                </Modal>
                                <Modal title="放大图" visible={this.state.previewVisible} footer={null} onCancel={()=>this.handleCancel()}>
                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                                </Modal>
                                <Modal title="放大图" visible={this.state.previewVisibleMember} footer={null} onCancel={()=>this.handleCancelMember()}>
                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImageMember} />
                                </Modal>
                                <Row className='row'>
                                        <Col span={4} className='zhTxt'>推广会员卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="推广会员卡号"
                                                value={this.state.model.promotionCardNumber || ''}
                                                onChange={(e) => this.modelChange('promotionCardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt' >推广会员手机号：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="推广会员手机号"
                                                disabled
                                                value={this.state.model.promotionPhone || ''}
                                                onChange={(e) => this.modelChange('promotionPhone', e.target.value)} />
                                        </Col>
                                    </Row>

                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>会员卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="会员卡号"
                                                value={this.state.model.cardNumber || ''}
                                                onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt' >会员手机号：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="会员手机号"
                                                disabled
                                                value={this.state.model.phone || ''}
                                                onChange={(e) => this.modelChange('phone', e.target.value)} />
                                        </Col>
                                    </Row>
                                   
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>消费日期：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="消费日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                disabled
                                                value={this.state.model.cashierTime == null ? null : moment(new Date(this.state.model.cashierTime), Config.dateFormat)}
                                                onChange={(value) => this.modelChange("cashierTime", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>结算日期：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="结算日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                disabled
                                                value={this.state.model.promotionEndDate == null ? null : moment(new Date(this.state.model.promotionEndDate), Config.dateFormat)}
                                                onChange={(value) => this.modelChange("promotionEndDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                   
                                   
                                    <Row className="row">
                                        <Col span={4} className="zhTxt">结算状态：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                disabled
                                                value={this.state.model.settlementStatus || ''}
                                                onChange={(e) => this.modelChange('settlementStatus', e)} >
                                                <Option value={""}>全部</Option>
                                                {context.settlementStatusMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={4} className='zhTxt' >推广业绩返利比例：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="推广业绩返利比例"
                                                disabled
                                                value={this.state.model.memberConsumptionCommission || ''}
                                                onChange={(e) => this.modelChange('memberConsumptionCommission', e.target.value)} />
                                        </Col>
                                       
                                        
                                    </Row>
                                    <Row className="row">
                                        <Col span={4} className='zhTxt' >消费金额：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="消费金额"
                                                disabled
                                                value={Common.numberFormat(this.state.model.consumptionAmount, 2) || ''}
                                                onChange={(e) => this.modelChange('consumptionAmount', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt' >返现金额：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="返现金额"
                                                disabled
                                                value={Common.numberFormat(this.state.model.foodAmount, 2)  || ''}
                                                onChange={(e) => this.modelChange('foodAmount', e.target.value)} />
                                        </Col>
                                       
                                    </Row>
                                    <Row className="row">
                                        <Col span={4} className='zhTxt' >推广返利金额：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="推广返利金额"
                                                disabled
                                                value={Common.numberFormat(this.state.model.amount , 2)|| ''}
                                                onChange={(e) => this.modelChange('amount', e.target.value)} />
                                        </Col>
                                     </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt' >地区：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="地区"
                                                disabled
                                                value={this.state.model.region || ''}
                                                onChange={(e) => this.modelChange('region', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt' >详细地址：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="详细地址"
                                                disabled
                                                value={this.state.model.address || ''}
                                                onChange={(e) => this.modelChange('address', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>会员头像：</Col>
                                        <Col span={6} className='imgUpload zhInput'>
                                            <Upload
                                                key={"1"}
                                                disabled
                                                accept=".png, .jpg, .jpeg"
                                                
                                                action={Config.apiBackServer + "test/test"}
                                                listType="picture-card"
                                                fileList={this.state.imageFileMember}
                                                onPreview={this.handlePreviewMember}
                                                onChange={(file) => this.handleChangeMember(file)}>
                                                {this.state.imageFileMember.length >= this.state.ImageNumMember ? null : uploadButtonMember}
                                            </Upload>
                                        </Col>

                                        <Col span={4} className='zhTxt'>结算凭证：</Col>
                                        <Col span={6} className='imgUpload zhInput'>
                                            <Upload
                                                key={"1"}
                                                disabled
                                                accept=".png, .jpg, .jpeg"
                                                
                                                action={Config.apiBackServer + "test/test"}
                                                listType="picture-card"
                                                fileList={this.state.imageFile}
                                                onPreview={this.handlePreview}
                                                onChange={(file) => this.handleChange(file)}>
                                                {this.state.imageFile.length >= this.state.ImageNum ? null : uploadButton}
                                            </Upload>
                                        </Col>
                                    </Row>

                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>支付宝收款码：</Col>
                                        <Col span={6} className='imgUpload zhInput'>
                                            <Upload
                                                key={"1"}
                                                disabled
                                                accept=".png, .jpg, .jpeg"
                                                action={Config.apiBackServer + "test/test"}
                                                listType="picture-card"
                                                fileList={this.state.imageFileZFB}
                                                onPreview={this.handlePreviewZFB}
                                                >
                                                {this.state.imageFileZFB.length >= this.state.ImageNumZFB ? null : uploadButtonZFB}
                                            </Upload>
                                        </Col>
                                        <Col span={4} className='zhTxt'>微信收款码：</Col>
                                        <Col span={6} className='imgUpload zhInput'>
                                            <Upload
                                                key={"1"}
                                                disabled
                                                accept=".png, .jpg, .jpeg"
                                                action={Config.apiBackServer + "test/test"}
                                                listType="picture-card"
                                                fileList={this.state.imageFileWX}
                                                onPreview={this.handlePreviewWX}
                                               >
                                                {this.state.imageFileWX.length >= this.state.ImageNumWX ? null : uploadButtonWX}
                                            </Upload>
                                        </Col>
                                    </Row>

                                    
                                  
                            </TabPane>
                        </Tabs>
                    </div>
                    </Spin>
                </div>
            </div >
        )
    }


}