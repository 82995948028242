import React, { Component } from 'react';
import { DatePicker, Button, Row, Col, Tabs, Table, Input, Select, Modal, Spin, message } from 'antd';
import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import moment from 'moment';//日期格式化导包
import './shopBatchPayMentManagement.css';

export default class shopBatchPayMentManagement extends Component {

    constructor(props){
        super(props)                                                       
        this.state = {
            tabIndex: "1", // 页面切换
            searchModel:{batchRebateState:'NO_PAYMENT',pageSize:10, page:1,startTime: new Date().setHours(new Date().getHours() - 24*7) , endTime: new Date()},    // 查询保存的数据
            searchShopList:[],
            selectedRowKeys: [],
            selectedRows: [],
            model:{},
            tables:[],  // 表格数据
            rebateModel:{pageSize:10, page:1,},
            rebateTables:[],    // 子表表格数据
            isLoading:false, // 表格加载状态
            rebateStateTabe:false, // 显示支付按钮
            isPaying:false,//支付中
            rebateAllList:[],
            showModal:false,   //展示支付二维码
            payQrCode:"",   // 支付二维码
            wechatPaymentOrderId:"",//订单ID
            timer:null,
            countDownTimer:null,
            paymentTotalAmount:0,
            timeDown:60,
            selectPaymentFlag:false,
            openPaymentInd:'',
        }
    }

    componentDidMount(){
        if(Common.isNotEmpty(Config.userInfo.shopId)){
            this.searchBatchRebateList();
            this.searchOpenWxPayment();
        }
    }

    searchOpenWxPayment(){
        let model = { segment:"OPEN_WECHAT_PAYMENT", code:"OPEN_IND" }
        fetch(Config.apiBackServer + "sysParam/searchAll", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let openPaymentInd = "N";
            if(data.object !=null && data.object.length > 0){
                openPaymentInd = data.object[0].parmValue1;
            }
            this.setState({ openPaymentInd: openPaymentInd });
        }).catch(err => { });
    }

    // 列表 tab查询 
    searchBatchRebateList(){
        this.setState({ isLoading:true })
        let searchModel = this.state.searchModel;
        searchModel.shopId = Config.userInfo.shopId;
        fetch(Config.apiBackServer + "batchRebateManagement/batchRebateSearch",{
            method: "POST",
            body:JSON.stringify(searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if("SUCCESS" === data.msg){
                model.total = data.object.total;
            }else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list,
            })
        }).catch(err => { }).finally(() => 
            this.setState({ isLoading: false })
        );
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    // 重置
    reset(){
        this.setState({
            searchModel: {
                batchRebateState:'NO_PAYMENT',
                page: 1,
                pageSize: 10,
                shopId: '',
                shopName:'',
                startTime: new Date().setHours(new Date().getHours() - 24*7) ,
                endTime: new Date(),
            },
            selectedRowKeys:[], 
            selectedRows:[],
            rebateAllList:[]
        })
    }

    tableChange(value){
        if(value == 2){
            this.see();
        } else {
            this.setState({tab:value,})
            this.cancel();
        }
    }

    // 查看
    see(){
        if(this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误","请选择一条数据");
            return
        }
        let model = this.state.selectedRows[0];
        let newRebateModel = this.state.rebateModel;
        newRebateModel.shopId = model.shopId;
        newRebateModel.cashierTime = model.cashierTime;
        newRebateModel.batchRebateState = this.state.searchModel.batchRebateState;
        newRebateModel.batchId = model.batchId;
        this.setState({
            tabIndex: "2",
            flag:"eye",
            model:model,
            rebateModel:newRebateModel,
        }, () => {
            this.searchDetailRebateList();
        })
    }

    searchDetailRebateList(){
        this.setState({isLoading:true});
        fetch(Config.apiBackServer + "batchRebateManagement/searchDetailRebateList",{
            method: "POST",
            body:JSON.stringify(this.state.rebateModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log(data);
            let rebateModel = this.state.rebateModel;
            if("SUCCESS" === data.msg){
                rebateModel.total = data.object.total;
            }else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                rebateModel: rebateModel,
                rebateTables: data.object.list,
            })
        }).catch(err => { }).finally(() => 
            this.setState({ isLoading: false })
        );
    }

    pageChange = (current, pageSize) =>{
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel:searchModel,
        }, function() {
            this.searchBatchRebateList();
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log(selectedRowKeys)
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    cancel(){
        this.setState({
            tabIndex:"1",
            flag:"",
            selectedRowKeys: [],
            selectedRows: [],
            rebateTables:[],
            rebateModel:{pageSize:10, page:1},
            model:{},
            rebateAllList:[],
            showModal:false,
            payQrCode:"",
            wechatPaymentOrderId:"",
            paymentTotalAmount:0,
            timeDown:60,
        },() => {
            this.searchBatchRebateList();
        })
    }

    /**前往详情页 支付 */
    toPayment(){
        if(this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误","请选择一条数据");
            return
        }
        let model = this.state.selectedRows[0];
        if("PAYMENT" ===model.batchRebateState){
            Message.openMsgError("错误","已经支付过了，不能再次支付");
            return
        }
        let newRebateModel = this.state.rebateModel;
        newRebateModel.batchId = model.batchId;
        newRebateModel.shopId = model.shopId;
        newRebateModel.cashierTime = model.cashierTime;
        newRebateModel.batchRebateState = this.state.searchModel.batchRebateState;
        this.setState({
            tabIndex: "2",
            flag:"payment",
            model:model,
            rebateModel:newRebateModel,
            timeDown:60,
            selectPaymentFlag:false,
        }, () => {
            this.searchDetailRebateList();
            this.searchDetailRebateAllList();
        })
    }

    searchDetailRebateAllList(){
        fetch(Config.apiBackServer + "batchRebateManagement/searchDetailRebateAllList",{
            method: "POST",
            body:JSON.stringify(this.state.rebateModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log(data);
            if("SUCCESS" === data.msg){
                this.setState({
                    rebateAllList: data.object,
                })
            }else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        });
    }

    selectPayment(){
        console.log(!this.state.selectPaymentFlag);
        this.setState({selectPaymentFlag:!this.state.selectPaymentFlag})
    }

    /** 调 获取收款二维码 方法--*/
    payment(){
        if(this.state.openPaymentInd !="Y"){
            Message.openMsgError("错误","微信支付暂未开通，请联系平台");
            return;
        }
        console.log("getPayQRCode");
        let parmModel = this.state.model;
        parmModel.rebateList = this.state.rebateAllList;
        fetch(Config.apiBackServer + "batchRebateManagement/getPayQRCode", {
            method: 'POST',
            body: JSON.stringify(parmModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                showModal: true,
                payQrCode: data.object.qrCodeBase,
                wechatPaymentOrderId: data.object.wechatPaymentOrderId,
                paymentTotalAmount: data.object.total,
                timeDown:60,
            },()=>{
                this.countDownFunc();//60秒开始倒计时
                this.orderQuery();
            });
        }).catch(err => { });
    }

    countDownFunc(){
        let countDownTimer = setTimeout(() => {
            this.setState({timeDown:this.state.timeDown-1},()=>{
                if(this.state.timeDown <= 0){
                    clearTimeout(this.state.countDownTimer); 
                    this.payCancel("timeOut");//取消订单
                }else{
                    this.countDownFunc();
                }
            })
        }, 1000);
        this.setState({countDownTimer})
    }

    orderQuery() {
        console.log("orderQuery");
        let parmModel = this.state.model;
        parmModel.wechatPaymentOrderId = this.state.wechatPaymentOrderId;
        fetch(Config.apiBackServer + "batchRebateManagement/orderQuery", {
            method: 'POST',
            body: JSON.stringify(parmModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log("orderQuery data");
            console.log(data);
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            let flag = true;
            if("SUCCESS" === data.object || data.object.indexOf("SUCCESS") != -1) {
                console.log("SUCCESS---------");
                clearTimeout(this.state.countDownTimer); 
                this.cancel();
                Message.openMsg("success", "支付成功");
            } else if("CLOSED" === data.object) {
                console.log("CLOSED---------");
                Message.openMsg("info", "已取消支付");
            } else {
                console.log("继续---------");
                let {timer} = this.state;
                if(this.state.showModal){
                    timer = setTimeout(() => {
                        this.orderQuery();
                    }, 5000);
                    flag = false;
                    this.setState({timer})
                }
            }
            if(flag) {
                this.setState({
                    showModal: false,
                    payQrCode: "",
                    wechatPaymentOrderId: ""
                })
            }
        }).catch(err => { });
    }

    /**支付取消 */
    payCancel(parm) {
        console.log("cancelOrder");
        let parmModel = this.state.model;
        parmModel.wechatPaymentOrderId = this.state.wechatPaymentOrderId;
        fetch(Config.apiBackServer + "batchRebateManagement/cancelOrder", {
            method: 'POST',
            body: JSON.stringify(parmModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log(data);
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            if("SUCCESS" === data.object.status) {
                // clearTimeout(this.state.countDownTimer);
                this.cancel();
                Message.openMsg("success", "支付成功");
            } else if("CLOSED" === data.object.status) {
                if("timeOut" === parm){
                    Message.openMsg("error", "二维码已过期,请重新获取二维码");
                }else{
                    Message.openMsg("info", "已取消支付");
                }
            }
        }).catch(err => { }).finally(() => {
            let {timer,countDownTimer} = this.state;
            console.log("clearTimeout timer");
            clearTimeout(timer);
            if("timeOut" != parm){
                console.log("clearTimeout countDownTimer");
                clearTimeout(countDownTimer);
            }
            this.setState({
                showModal: false,
                payQrCode: "",
                wechatPaymentOrderId: "",
            })
        });
    }
   
    /**详情页 tab的 页码方法 */
    rebatePageChange = (current, pageSize) => {
        let rebateModel = this.state.rebateModel;
        rebateModel.pageSize = pageSize;
        rebateModel.page = current;
        this.setState({
            rebateModel:rebateModel,
        },function() {
            this.searchDetailRebateList();
        });        
    }

    selectRadioRow(record) {
        let selectedRowKeys = this.state.selectedRowKeys;
        let checkboxState = this.state.selectedRows;
        let i = checkboxState.indexOf(record);
        if (i > -1) {
            selectedRowKeys = [];
            checkboxState = [];
        } else {
            selectedRowKeys = [record.shopId +record.batchId+ record.cashierTime];
            checkboxState = [record];
        }
        console.log(selectedRowKeys)
        this.setState({ selectedRowKeys : selectedRowKeys, selectedRows: checkboxState });
    }

    render(){
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: 'tableWidth11',
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmount',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmount',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '消费金额',
                dataIndex: 'consumeAmount',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '返利总金额',
                dataIndex: 'rebateAmount',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            }, 
            {
                title: '已返利金额',
                dataIndex: 'rebateAmountCompleted',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            }, 
            {
                title: '消费笔数',
                dataIndex: 'cashierCount',
                className: "tableWidth9 tableRightAlignment",
                render: (text) => Common.numberFormat(text)
            },                 
            {
                title: '消费日期',
                dataIndex: 'cashierTime',
                className: "tableWidth10 tableLeftAlignment",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '打款时间',
                dataIndex: 'batchRebateTime',
                className: "tableWidth10 tableLeftAlignment",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '打款状态',
                dataIndex: 'batchRebateState',
                className: "tableWidth10 tableLeftAlignment",
                render: (text) => Common.isNotEmpty(text) ? (text === 'NO_PAYMENT' ? '未支付' : (text === 'PAYMENT' ? '已支付' : '已付款')) : ""
            }           
        ]

        
        // 子表列名
        const rebateColumns = [
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: 'tableWidth10',
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth10',
            },
            {
                title: '消费时间',
                dataIndex: 'cashierTime',
                className: 'tableWidth10',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '消费金额',
                dataIndex: 'consumeAmount',
                className: 'tableWidth10 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmount',
                className: 'tableWidth10 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmount',
                className: 'tableWidth10 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '返利金额',
                dataIndex:"rebateAmount",
                className: 'tableWidth10 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '返利时间',
                dataIndex:"rebateTime",
                className: 'tableWidth10',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '返利状态',
                dataIndex:"rebateState",
                className: 'tableWidth10',
                render: (text) => Common.isNotEmpty(text) ? (text ==='AINCOMPLETE'?'未完成': (text ==='COMPLETED'?'已完成': '')) : ""
            },
            {
                title: '返利类型',
                dataIndex:"rebateType",
                className: 'tableWidth8',
                render: (text) => Common.isNotEmpty(text) ? (text === 'MASTER_CARD' ? '金卡' : '银卡') : ""
            },
        ]

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return(
            <div className="shopBatchPayMentManagement">
                <div className="zhRoot">
                    <div className="zhTitle">批量打款管理</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">店铺名称：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="店铺名称"
                                            value={this.state.searchModel.shopName || ''}
                                            onChange={(e) => this.searchChange('shopName',e.target.value)}
                                        />
                                    </Col>

                                    <Col span={2} className="zhTxt">消费日期：</Col>
                                    <Col span={6} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            className="shopRebateStatement"
                                            allowClear={true}
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("startTime", value)}
                                            format={Config.dateFormat} />
                                            &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                            className="shopRebateStatement"
                                            allowClear={true}
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("endTime", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                    <Col span={2} className="zhTxt">批量打款状态：</Col>
                                    <Col span={3} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            value={this.state.searchModel.batchRebateState || ''}
                                            onChange={(e) => this.searchChange('batchRebateState', e)} >
                                            <Option key="NO_PAYMENT" value={"NO_PAYMENT"}>未支付</Option>
                                            <Option key="PAYMENT"    value={"PAYMENT"}>已支付</Option>
                                        </Select>
                                    </Col>
                                </Row>    
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button type="primary" icon="search" disabled={this.state.isLoading} onClick={() => this.searchBatchRebateList()}>搜索</Button>
                                        <Button className="zhButtonRight" icon="redo" onClick={() => this.reset()}>重置</Button>
                                    </Col>    
                                </Row> 
                                <Row>
                                    <Button disabled={this.state.isLoading} type="primary" icon="export" onClick={() => this.toPayment()}  >批量打款</Button>
                                    <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" icon="eye" onClick={() => this.see()}>查看</Button>
                                </Row>
                                <Row className="zhRow">
                                    <Table  rowSelection={rowSelection}
                                        rowKey={record => record.shopId +record.batchId+ record.cashierTime}
                                        bordered={true} // 边线
                                        columns={columns}   // 列名
                                        loading={{spinning:this.state.isLoading, tip:"加载中..."}}
                                        dataSource={this.state.tables}  // 数据
                                        pagination={{   // 分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => this.selectRadioRow(record), // 点击行
                                            };
                                        }}
                                    />
                                </Row>
                            </TabPane>
                            <TabPane tab="详细" key="2">
                            <Row className="row">
                                    <Col span={4} className='zhTxt'>店铺名称</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                                disabled={true}
                                                placeholder="店铺名称"
                                                className='shopName'
                                                value={this.state.model.shopName || ''}
                                        />
                                    </Col>
                                    <Col span={4} className='zhTxt'>消费日期</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                                disabled={true}
                                                placeholder="消费日期"
                                                className='cashierTime'
                                                value={this.state.model.cashierTime == null ? null : moment(this.state.model.cashierTime).format(Config.dateFormat)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={4} className='zhTxt'>消费金额</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                                disabled={true}
                                                placeholder="消费金额"
                                                className='tradeAmount'
                                                value={this.state.model.consumeAmount || ''}
                                        />
                                    </Col>
                                    <Col span={4} className='zhTxt'>消费笔数</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                                disabled={true}
                                                placeholder="消费笔数"
                                                className='cashierCount'
                                                value={this.state.model.cashierCount || ''}
                                        />
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={4} className='zhTxt'>返现金额</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                                disabled={true}
                                                placeholder="返现金额"
                                                className='foodAmount'
                                                value={this.state.model.foodAmount || ''}
                                        />
                                    </Col>
                                    <Col span={4} className='zhTxt'>不返现金额</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                                disabled={true}
                                                placeholder="不返现金额"
                                                className='wineAmount'
                                                value={this.state.model.wineAmount || ''}
                                        />
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={4} className='zhTxt'>返利总金额</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                                disabled={true}
                                                placeholder="返利总金额"
                                                className='rebateAmount'
                                                value={this.state.model.rebateAmount || ''}
                                        />
                                    </Col>
                                    <Col span={4} className='zhTxt'>已返利金额</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                            disabled={true}
                                            placeholder="已返利金额"
                                            className='rebateAmountCompleted'
                                            value={this.state.model.rebateAmountCompleted || 0}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} className='zhTxt'>打款时间</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                            disabled={true}
                                            placeholder="打款时间"
                                            className='batchRebateTime'
                                            value={this.state.model.batchRebateTime == null ? null :  moment(this.state.model.batchRebateTime).format(Config.dateFormatALL)}
                                        />
                                    </Col>
                                    <Col span={4} className='zhTxt'>打款状态</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                                disabled={true}
                                                placeholder="打款状态"
                                                className='batchRebateState'
                                                value={Common.isNotEmpty(this.state.model.batchRebateState) ?
                                                    (this.state.model.batchRebateState === 'NO_PAYMENT' ? '未支付' : 
                                                        (this.state.model.batchRebateState === 'PAYMENT' ? '已支付' : '已付款')
                                                    ) : ""}
                                        />
                                    </Col>
                                </Row>
                                <Row className="zhRow"> 
                                    <Table
                                        rowKey="rebateId"
                                        bordered={true} // 边线
                                        columns={rebateColumns}   // 列名
                                        dataSource={this.state.rebateTables}  // 数据
                                        loading={{spinning:this.state.isLoading, tip:"加载中..."}}
                                        pagination={{   // 分页
                                            showSizeChanger:true, //是否显示可以设置几条一页的选项
                                            onChange:this.rebatePageChange,  //上页下页或者跳页方法
                                            onShowSizeChange:this.rebatePageChange, //每页显示多少条数据方法
                                            total: this.state.rebateModel.total,             //总共多少条数据
                                            current: this.state.rebateModel.page,         //当前页
                                            pageSize: this.state.rebateModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                              //默认当前页为
                                        }}
                                    >                                            
                                    </Table>
                                </Row>
                                <Row className='zhRow'>   
                                    <div style={{width:"50%",textAlign:"left",float:"left",height:"200px"}}>
                                        <div className={this.state.selectPaymentFlag?"payWeiXinSelect":"payWeiXin"} hidden={this.state.flag === "eye"}>
                                            <img src={Config.icon+"payMentImg.png"} className="payWeiXinImg" onClick={() => { this.selectPayment()}}/>
                                        </div>
                                        <div className="toPayRow" hidden={!this.state.selectPaymentFlag} >
                                            <Button className="zhButtonLeft" type="primary" icon="check-circle" onClick={() => this.payment()} >前往支付</Button>
                                            <Button className="zhButtonRight" icon="rollback" onClick={() => { this.cancel() }}>返回</Button>
                                        </div>
                                    </div>
                                </Row>

                                <Modal
                                    title={<div className="modalTitle">微信支付</div>}
                                    className="batchRebateManagementModal"
                                    maskClosable={false}
                                    width={700}
                                    visible={this.state.showModal}
                                    onCancel={() => this.payCancel()}
                                    footer={[
                                        <Button key='cancel' className="buttonWhiteStyle" onClick={() => this.payCancel()}>取消</Button>
                                    ]}
                                >
                                    <div className="topInfo">
                                        <div className="order">店铺名称：<span className="infoValue">{this.state.model.shopName}</span></div>
                                        <div className="amount">消费日期：<span className="infoValue">{moment(this.state.model.cashierTime).format(Config.dateFormat)}</span></div>
                                    </div>
                                    <div className="topInfo marginBttom">
                                        <div className="order">订单编号：<span className="infoValue">{this.state.wechatPaymentOrderId}</span></div>
                                        <div className="amount">应付金额 {<span className="amountFont">{this.state.paymentTotalAmount}</span>} 元</div>
                                    </div>
                                    <div className="topInfo textLeft">
                                        距离二维码过期还有 <span className="redFont">{this.state.timeDown}</span> 秒，请尽快付款！过期后请重新获取二维码。
                                    </div>
                                    <div className="leftImg">
                                        <img src={"data:image/png;base64," + this.state.payQrCode} className="payQrCode" />
                                        <img src={Config.icon+"tishi.png"} className="tishi" />
                                    </div>
                                    <div className="rightInfo">
                                        <img src={Config.icon+"phoneBtn.png"}  className="phoneBtn" />
                                    </div>
                                </Modal>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}

