import React, { Component } from 'react';
import { DatePicker, Button, Row, Col, Tabs, Table, Input, Select, Modal, Spin, Radio } from 'antd';
import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import moment from 'moment';//日期格式化导包
import './shopToPay.css';

const confirm = Modal.confirm;
export default class shopToPay extends Component {

    constructor(props){
        super(props)                                                       
        this.state = {
            tabIndex: "1", // 页面切换
            searchModel:{
                cashierStatus:'',
                pageSize:10, 
                page:1,
                startTime: new Date().setHours(new Date().getHours() - 24*7) , 
                endTime: new Date()
            }, 
            selectedRowKeys: [],
            selectedRows: [],
            model:{},
            tables:[],  // 表格数据
            isLoading:false, // 表格加载状态
            showModal:false,   //展示支付二维码
            payQrCode:"",   // 支付二维码
            timer:null,
            countDownTimer:null,
            paymentTotalAmount:0,
            timeDown:300,   //300秒倒计时
            isShowModalLoading:false,
            openPaymentInd:'',
        }
    }

    componentDidMount() {
        this.searchOpenWxPayment();//查询是否开通微信支付
        this.searchList(); //tables表格list查询
    }

    searchOpenWxPayment(){
        let model = { segment:"OPEN_WECHAT_PAYMENT", code:"OPEN_IND" }
        fetch(Config.apiBackServer + "sysParam/searchAll", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let openPaymentInd = "N";
            if(data.object !=null && data.object.length > 0){
                openPaymentInd = data.object[0].parmValue1;
            }
            this.setState({ openPaymentInd: openPaymentInd });
        }).catch(err => { });
    }

    // 列表 tab查询 
    searchList(){
        this.setState({isLoading:true});
        let {searchModel} = this.state;
        searchModel.shopId = Config.userInfo.shopId;
        fetch(Config.apiBackServer + "shopToPay/searchList",{
            method: "POST",
            body:JSON.stringify(searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if("SUCCESS" === data.msg){
                model.total = data.object.total;
            }else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list,
            })
        }).catch(err => { }).finally(() => 
            this.setState({ isLoading: false })
        );
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    // 重置
    reset(){
        this.setState({
            searchModel: {
                cashierStatus:'',
                page: 1,
                pageSize: 10,
                startTime: new Date().setHours(new Date().getHours() - 24*7) ,
                endTime: new Date(),
            },
            selectedRowKeys:[], 
            selectedRows:[],
            model:{},
            payQrCode:"",
            paymentTotalAmount:0,
            timeDown:300,
        },()=>{
            this.searchList();
        })
    }

    reSearch(){
        this.setState({
            model:{},
            payQrCode:"",
            paymentTotalAmount:0,
            timeDown:300,
        },()=>{
            this.searchList();
        })
    }

    pageChange = (current, pageSize) =>{
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel:searchModel,
        }, function() {
            this.searchList();
        })
    }

    /** 调 获取收款二维码 方法--*/
    payment(parmModel){
        console.log("getPayQRCode");
        if(this.state.openPaymentInd !=="Y"){
            Message.openMsgError("错误","微信支付暂未开通，请联系平台");
            return;
        }
        console.log(parmModel);
        this.setState({isShowModalLoading:true,model:parmModel});
        fetch(Config.apiBackServer + "shopToPay/getPayQRCode", {
            method: 'POST',
            body: JSON.stringify(parmModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            let model = this.state.model;
            model.flowSalesReceiveId = data.object.flowSalesReceiveId;
            this.setState({
                model,
                showModal: true,
                payQrCode: data.object.qrCodeBase,
                paymentTotalAmount: data.object.receiveAmount,
                timeDown:300,
            },()=>{
                this.countDownFunc();//300秒开始倒计时
                this.orderQuery();
            });
        }).catch(err => { }).finally(() => 
            this.setState({isShowModalLoading:false})
        ); 
    }

    countDownFunc(){
        let countDownTimer = setTimeout(() => {
            this.setState({timeDown:this.state.timeDown-1},()=>{
                if(this.state.timeDown <= 0){
                    clearTimeout(this.state.countDownTimer); 
                    this.payCancel("timeOut");//取消订单
                }else{
                    this.countDownFunc();
                }
            })
        }, 1000);
        this.setState({countDownTimer})
    }

    orderQuery() {
        let parmModel = this.state.model;
        fetch(Config.apiBackServer + "shopToPay/orderQuery", {
            method: 'POST',
            body: JSON.stringify(parmModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log("orderQuery data");
            console.log(data);
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            let flag = true;
            if(Common.isNotEmpty(data.object) && data.object.indexOf("SUCCESS") >=0) {//SUCCESS
                clearTimeout(this.state.countDownTimer); 
                this.reSearch();
                Message.openMsg("success","支付成功");
            } else if("CLOSED" === data.object) {//CLOSED
                this.reSearch();
                Message.openMsg("info", "已取消支付");
            } else {
                let {timer} = this.state;
                if(this.state.showModal){
                    timer = setTimeout(() => {
                        this.orderQuery();
                    }, 6000);
                    flag = false;
                    this.setState({timer})
                }
            }
            if(flag) {
                this.setState({
                    showModal: false,
                    payQrCode: "",
                })
            }
        }).catch(err => { });
    }

    /**支付取消 */
    payCancel(parm) {
        fetch(Config.apiBackServer + "shopToPay/cancelOrder", {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log(data);
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            if("SUCCESS" === data.object || data.object.indexOf("SUCCESS") >=0) {
                this.reSearch();
                Message.openMsg("success", "支付成功");
            } else if("CLOSED" === data.object || data.object.indexOf("CLOSED") >=0) {
                if("timeOut" === parm){
                    Message.openMsg("error", "二维码已过期,请重新获取二维码");
                }else{
                    Message.openMsg("info", "已取消支付");
                }
            }
        }).catch(err => { }).finally(() => {
            let {timer,countDownTimer} = this.state;
            console.log("clearTimeout timer");
            clearTimeout(timer);
            if("timeOut" !== parm){
                console.log("clearTimeout countDownTimer");
                clearTimeout(countDownTimer);
            }
            this.setState({
                showModal: false,
                payQrCode: "",
            })
        });
    }

    delete(parmModel) {
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "shopToPay/delete", {
                    method: 'POST',
                    body: JSON.stringify(parmModel),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.reSearch()
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }
   
    render(){
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth11',
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: 'tableWidth11',
            },
            {
                title: '返现金额',
                dataIndex: 'footAmount',
                className: "tableWidth11 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmount',
                className: "tableWidth11 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '消费总金额',
                dataIndex: 'consumeAmount',
                className: "tableWidth11 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },      
            {
                title: '消费日期',
                dataIndex: 'cashierTime',
                className: "tableWidth11",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatHm)}</span>,
            },
            {
                title: '收银员手机号',
                dataIndex: 'operatorUserPhone',
                className: "tableWidth11",
            },
            {
                title: '买单状态',
                dataIndex: 'cashierStatus',
                className: "tableWidth6",
                render: (text) => Common.isNotEmpty(text) ? (text === 'PAID' ? '已支付' : '待支付') : ""
            },
            {
                title: '',
                dataIndex: '',
                className: "tableWidth15",
                render: (text,record) => <div>
                    <div className="tabButton">
                        <Button disabled={this.state.isShowModalLoading} hidden={"READY_TO_PAY" !==record.cashierStatus} type="primary" icon="export" onClick={() => this.payment(record)} >买单</Button>
                    </div>
                    <Button disabled={this.state.isLoading} hidden={"READY_TO_PAY" !==record.cashierStatus} className="zhButtonLeft"  type="danger" icon="delete" onClick={() => this.delete(record)} >删除</Button>
                </div>
            }
        ]

        return(
            <div className="shopToPay">
                <div className="zhRoot">
                    <div className="zhTitle">店铺代付</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' >
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">消费日期：</Col>
                                    <Col span={6} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            className="shopRebateStatement"
                                            allowClear={true}
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("startTime", value)}
                                            format={Config.dateFormat} />
                                            &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                            className="shopRebateStatement"
                                            allowClear={true}
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("endTime", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                    <Col span={2} className="zhTxt">买单状态：</Col>
                                    <Col span={3} className='zhInput'>
                                        <Select className="zhSelect"
                                            value={this.state.searchModel.cashierStatus || ''}
                                            onChange={(e) => this.searchChange('cashierStatus', e)} >
                                            <Option key="" value={""}>全部</Option>
                                            <Option key="READY_TO_PAY" value={"READY_TO_PAY"}>待支付</Option>
                                            <Option key="PAID"    value={"PAID"}>已支付</Option>
                                        </Select>
                                    </Col>
                                    <Col span={5} className="zhRowButtonRight">
                                        <Button type="primary" icon="search" disabled={this.state.isLoading} onClick={() => this.searchList()}>搜索</Button>
                                        <Button className="zhButtonRight" icon="redo" onClick={() => this.reset()}>重置</Button>
                                    </Col>   
                                </Row>    
                                <Row className="zhRow">
                                    <Table  
                                        // rowSelection={rowSelection}
                                        rowKey={record => record.cashierId}
                                        bordered={true} // 边线
                                        columns={columns}   // 列名
                                        loading={{spinning:this.state.isLoading, tip:"加载中..."}}
                                        dataSource={this.state.tables}  // 数据
                                        pagination={{   // 分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                    />
                                </Row>
                            </TabPane>
                            <Modal
                                title={<div className="modalTitle">微信支付</div>}
                                className="shopToPayModal"
                                maskClosable={false}
                                width={700}
                                visible={this.state.showModal}
                                onCancel={() => this.payCancel()}
                                footer={[
                                    <Button key='cancel' className="buttonWhiteStyle" onClick={() => this.payCancel()}>取消</Button>
                                ]}
                            >
                                <div className="topInfo">
                                    <div className="order">店铺名称：<span className="infoValue">{this.state.model.shopName}</span></div>
                                    <div className="amount">消费日期：<span className="infoValue">{moment(this.state.model.cashierTime).format(Config.dateFormat)}</span></div>
                                </div>
                                <div className="topInfo marginBttom">
                                    <div className="order">订单编号：<span className="infoValue">{this.state.model.flowSalesReceiveId}</span></div>
                                    <div className="amount">应付金额 {<span className="amountFont">{this.state.paymentTotalAmount}</span>} 元</div>
                                </div>
                                <div className="topInfo textLeft">
                                    距离二维码过期还有 <span className="redFont">{this.state.timeDown}</span> 秒，请尽快付款！过期后请重新获取二维码。
                                </div>
                                <div className="leftImg">
                                    <img alt="" src={"data:image/png;base64," + this.state.payQrCode} className="payQrCode" />
                                    <img alt="" src={Config.icon+"tishi.png"} className="tishi" />
                                </div>
                                <div className="rightInfo">
                                    <img alt="" src={Config.icon+"phoneBtn.png"}  className="phoneBtn" />
                                </div>
                            </Modal>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}

