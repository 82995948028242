import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Input, Select, Upload, Modal, DatePicker, Icon } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import Context from '../../public/js/context';
import { ContextHandler } from '../../public/js/contextHandler';
import './platformRebateManagement.css';
const { TextArea } = Input;
/**
 * 平台返利
 */
export default class platformRebateManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            isLoading: true,
            searchModel: {pageSize: 10, page: 1, auditStatus: "",},
            searchShopList: [],
        }
    }

    componentDidMount() {
        this.search();
        this.searchShop();
    }

    /**查询平台返利数据 */
    // search() {
    //     this.setState({ isLoading: true })
    //     let model = this.state.searchModel;
    //     fetch(Config.apiBackServer + "platformShopRebateManagement/platformRebateSearch", {
    //         method: 'POST',
    //         body: JSON.stringify(this.state.searchModel),
    //         headers: Config.header,
    //         mode: 'cors',
    //         cache: 'default'
    //     }).then(res => res.json()).then((data) => {
    //         if ("SUCCESS" === data.msg) {
    //             model.total = data.object.total;
    //         } else {
    //             Message.openMsgError("错误", data.msg, data.errorCode)
    //         }
    //         this.setState({
    //             searchModel: model,
    //             tables: data.object.list
    //         })
    //     }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    // }

    search() {
        this.setState({ isLoading: true })
        let model = this.state.searchModel;
        fetch(Config.apiBackServer + "shopRebateManagement/shopRebateSearchAll", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    /**查询店铺 */
    searchShop() {
        fetch(Config.apiBackServer + "openMembership/searchShop", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({
                    searchShopList: data.object.list
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode);
            }
        }).catch(err => { });
    };

    reset() {
        this.setState({
            searchModel: {pageSize: 10, page: 1, auditStatus: "",},
        },() => {
            this.search();
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    searchChange(k, e) {
        let { searchModel, searchShopList } = this.state;
        searchModel[k] = e;
        if ('shopId' === k) {
            if (Common.isNotEmpty(e)) {
                let index = searchShopList.findIndex((item) => item.shopId === e);
                searchModel.shopName = searchShopList[index].shopName;
            } else {
                searchModel.shopName = null;
            }
        }
        this.setState({ searchModel });
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }


    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: "tableWidth8",
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth8",
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: "tableWidth8",
            },
            {
                title: '推荐人卡号',
                dataIndex: 'recommendNumber',
                className: 'tableWidth8',
            },
            {
                title: '推荐人手机号',
                dataIndex: 'recommendPhone',
                className: "tableWidth8",
            },
            {
                title: '推荐类型',
                dataIndex: 'rebateType',
                className: "tableWidth9",
                render: (text) => ContextHandler.getMapValue('rebateSalesRebateTypeMap', text)
            },
            {
                title: '消费金额',
                dataIndex: 'tradeAmount',
                className: "tableWidth7 tableRightAlignment",
                render: (text) => Common.numberFormat(text || 0, 2)
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmount',
                className: "tableWidth7 tableRightAlignment",
                render: (text) => Common.numberFormat(text || 0, 2)
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmount',
                className: "tableWidth7 tableRightAlignment",
                render: (text) => Common.numberFormat(text || 0, 2)
            },
            {
                title: '奖励金额',
                dataIndex: 'rebateAmount',
                className: "tableWidth7 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '消费时间',
                dataIndex: 'cashierTime',
                className: "tableWidth9",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '审核状态',
                dataIndex: 'auditStatus',
                className: "tableWidth6",
                render: (text) => ContextHandler.getMapValue('auditStatusMap', text)
            },
            {
                title: '审核时间',
                dataIndex: 'auditDate',
                className: "tableWidth9",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '推荐人状态',
                dataIndex: 'status',
                className: "tableWidth7",
                render: (text) => ContextHandler.getMapValue('statusMap', text)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="sysFunc">
                <div className="zhRoot">
                    <div className="zhTitle">平台返利</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">店铺名称：</Col>
                                    <Col span={3} className="zhInput">
                                        {/* <Select style={{ width: "100%" }} placeholder="店铺名称" value={this.state.searchModel.shopId || ''}
                                            onChange={(e) => this.searchChange('shopId', e)}>
                                            <option key="" value="">全部</option>
                                            {this.state.searchShopList.map((item) => <option key={item.shopId} value={item.shopId}>{item.shopName}</option>)}
                                        </Select> */}
                                        <Input maxLength={20}
                                            placeholder="店铺名称"
                                            value={this.state.searchModel.shopName || ''}
                                            onChange={(e) => this.searchChange('shopName', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">会员卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="会员卡号"
                                            value={this.state.searchModel.cardNumber || ''}
                                            onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">消费日期：</Col>
                                    <Col span={8} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            className="shopRebateManagementDatePicker"
                                            allowClear={true}
                                            value={this.state.searchModel.cashierStartTime == null ? null : moment(new Date(this.state.searchModel.cashierStartTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("cashierStartTime", value)}
                                            format={Config.dateFormat} />
                                            &nbsp;&nbsp;至&nbsp;&nbsp;
                                    <DatePicker placeholder="至日期"
                                            className="shopRebateManagementDatePicker"
                                            allowClear={true}
                                            value={this.state.searchModel.cashierEndTime == null ? null : moment(new Date(this.state.searchModel.cashierEndTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("cashierEndTime", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={2} className="zhTxt">推荐人卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={11}
                                            placeholder="推荐人卡号"
                                            value={this.state.searchModel.recommendNumber || ''}
                                            onChange={(e) => this.searchChange('recommendNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">会员手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={11}
                                            placeholder="会员手机号"
                                            value={this.state.searchModel.phone || ''}
                                            onChange={(e) => this.searchChange('phone', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">审核日期：</Col>
                                    <Col span={8} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            className="shopRebateManagementDatePicker"
                                            allowClear={true}
                                            value={this.state.searchModel.auditStartDate == null ? null : moment(new Date(this.state.searchModel.auditStartDate), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("auditStartDate", value)}
                                            format={Config.dateFormat} />
                                            &nbsp;&nbsp;至&nbsp;&nbsp;
                                        <DatePicker placeholder="至日期"
                                            className="shopRebateManagementDatePicker"
                                            allowClear={true}
                                            value={this.state.searchModel.auditEndDate == null ? null : moment(new Date(this.state.searchModel.auditEndDate), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("auditEndDate", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={2} className="zhTxt">推荐人手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={11}
                                            placeholder="推荐人手机号"
                                            value={this.state.searchModel.recommendPhone || ''}
                                            onChange={(e) => this.searchChange('recommendPhone', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">审核状态：</Col>
                                    <Col span={3} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            value={this.state.searchModel.auditStatus || ''}
                                            onChange={(e) => this.searchChange('auditStatus', e)} >
                                            <Option key="" value={""}>全部</Option>
                                            <Option key="CHECKED" value={"CHECKED"}>已审核</Option>
                                            <Option key="UNCHECKED" value={"UNCHECKED"}>未审核</Option>
                                        </Select>
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    {/* <Button disabled={this.state.isLoading} type="primary" onClick={() => this.toRebate()} icon="export">返利</Button> */}
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="rebateSalesId"
                                        scroll={{ x: 1200 }}
                                        bordered={true}                                     //边线
                                        columns={columns}                                   //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables}                      //数据
                                        pagination={{                                       //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,                      //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,              //每页显示多少条数据方法
                                            total: this.state.searchModel.total,            //总共多少条数据
                                            current: this.state.searchModel.page,           //当前页
                                            pageSize: this.state.searchModel.pageSize,      //每页显示多少条数据
                                            defaultCurrent: 1,                              //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'rebateSalesId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                    />
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}