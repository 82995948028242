import React, { Component } from 'react';
import { Modal,  Button, Row, Col, Tabs, Table, Input, Select, DatePicker, AutoComplete} from 'antd';
import moment from 'moment';//日期格式化导包
import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';

const confirm = Modal.confirm;

export default class ShopTurnoverFlow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            isLoading: false,
            searchModel: {page: 1, pageSize: 10,},
            tables: [],
            selectedRowKeys: [],
            selectedRows: [],

            model: {page: 1, pageSize: 10,},
            selectedRowKeysFsp: [],
            selectedRowsFsp: [],
            flowSalesPaymentTables: [],

            shopNameList: [],
            dataSource: [], // 自动填充数据
        }
    }

    componentDidMount() {
        this.searchSalesReceiveFlow();
        this.searchShop();
    }
      
    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.searchSalesReceiveFlow();
    }

    reset() {
        this.setState({
            searchModel: {page: 1, pageSize: 10,},
            tables: [],
        },() => {
            this.searchSalesReceiveFlow();
        })
    }

    tableChange(value) {
        if (value === "2") {
            this.searchSalesPaymentFlow()
        }
        this.setState({
            tabIndex: value,
            dataSource: []
        })
    }

    searchShop() {
        fetch(Config.apiBackServer + "shop/searchShop", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let list = [];
            if ("SUCCESS" === data.msg) {
                data.object.list.forEach(element => {
                    list.push(element.shopName)
                });
                this.setState({
                    shopNameList:list
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode);
            }
        }).catch(err => { });
    }

    searchSalesReceiveFlow() {
        this.setState({ isLoading: true });
        fetch(Config.apiBackServer + "shopTurnoverFlow/searchSalesReceiveFlow", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
                this.setState({
                    searchModel: model,
                    tables: data.object.list
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)   
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchSalesPaymentFlow() {
        this.setState({ isLoading: true });
        fetch(Config.apiBackServer + "shopTurnoverFlow/searchSalesPaymentFlow", {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.model;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
                this.setState({
                    model: model,
                    flowSalesPaymentTables: data.object.list
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)   
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    onSelectChangeFsp = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeysFsp: selectedRowKeys,
            selectedRowsFsp: selectedRows
        })
    }

        
    modelChange(k, e) {
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({
            model: newModel,
        });
    }

    pageChangeFsp = (current, pageSize) => {
        let model = this.state.model;
        model.pageSize = pageSize;
        model.page = current;
        this.setState({
            model: model,
        }, () => {
            this.searchSalesPaymentFlow()
        });
    }

    onSelect(value) {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({
            searchModel: model,
            dataSource: []
        })
    }

    onChange = (value) => {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({ 
            searchModel: model
        });
    }

    onSearch = searchText => {
        let model = this.state.searchModel
        model.shopName = searchText
        let dataSource = [];
        if (searchText) {
            let list = this.state.shopNameList
            for(let i=0; i < list.length; i++) {
                if (list[i].split(searchText).length > 1) {
                    dataSource.push(list[i]);
                }
            }
        }
        this.setState({
            dataSource: dataSource
        })
    };

    onSelectModel(value) {
        let model = this.state.model
        model.shopName = value
        this.setState({
            model: model,
            dataSource: []
        })
    }

    onChangeModel = (value) => {
        let model = this.state.model
        model.shopName = value
        this.setState({ 
            model: model
        });
    }

    onSearchModel = searchText => {
        let model = this.state.model
        model.shopName = searchText
        let dataSource = [];
        if (searchText) {
            let list = this.state.shopNameList
            for(let i=0; i < list.length; i++) {
                if (list[i].split(searchText).length > 1) {
                    dataSource.push(list[i]);
                }
            }
        }
        this.setState({
            dataSource: dataSource
        })
    };

    resetFsp() {
        this.setState({
            model: {page: 1, pageSize: 10,},
        }, () => {
            this.searchSalesPaymentFlow()
        })
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '营业额流水ID',
                dataIndex: 'flowSalesReceiveId',
                className: 'tableWidth16',
            },
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: 'tableWidth10',
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth10',
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: 'tableWidth10',
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmount',
                className: 'tableWidth8',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmount',
                className: 'tableWidth8',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '打款人',
                dataIndex: 'nickName',
                className: 'tableWidth8',
            },
            {
                title: '收款金额',
                dataIndex: 'receiveAmount',
                className: 'tableWidth8 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '货币',
                dataIndex: 'feeType',
                className: 'tableWidth6',
                render: (text) => ContextHandler.getMapValue('feeTypeMap', text)
            },
            {
                title: '交易状态',
                dataIndex: 'status',
                className: 'tableWidth6',
                render: (text) => ContextHandler.getMapValue('wechatPaymentFlowMap', text)
            },
            {
                title: '支付时间',
                dataIndex: 'paymentTime',
                className: 'tableWidth10',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
        ]

        const columnsFsp = [
            {
                title: '营业额奖励流水',
                dataIndex: 'flowSalesPaymentId',
                className: 'tableWidth12',
            },
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: 'tableWidth8',
            },
            {
                title: '消费者卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth7',
            },
            {
                title: '消费者手机号',
                dataIndex: 'phone',
                className: 'tableWidth8',
            },
            {
                title: '奖励人卡号',
                dataIndex: 'referrerCardnumber',
                className: 'tableWidth7',
            },
            {
                title: '奖励人手机号',
                dataIndex: 'referrerPhone',
                className: 'tableWidth8',
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmount',
                className: 'tableWidth7 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmount',
                className: 'tableWidth7 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '收款人',
                dataIndex: 'nickName',
                className: 'tableWidth7',
            },
            {
                title: '打款金额',
                dataIndex: 'paymentAmount',
                className: 'tableWidth7 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '打款状态',
                dataIndex: 'paymentStatus',
                className: 'tableWidth6',
                render: (text) => ContextHandler.getMapValue('wechatPaymentFlowMap', text)
            },
            {
                title: '打款时间',
                dataIndex: 'paymentTime',
                className: 'tableWidth10',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '付款信息',
                dataIndex: 'errMessage',
                className: 'tableWidth6',
                render: (text) =><span>{text === 'ERR' ? "打款失败" : null }</span>,
            },
        ]

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        }

        const rowSelectionFsp = {
            selectedRowKeys: this.state.selectedRowKeysFsp,
            onChange: this.onSelectChangeFsp,
            // type: 'radio',
        }
        return (
            <div className="shopTurnoverFlow">
                <div className="zhRoot">
                    <div className="zhTitle">营业额流水</div>
                    <div className="content">
                        <Tabs defaultActiveKey = '1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="营业额流水" key='1'>
                                <Row className="row">
                                    <Col span={2} className="zhTxt">店铺名称：</Col>
                                    <Col span={3} className="zhInput">
                                        <AutoComplete
                                            dropdownMatchSelectWidth={false}//下拉菜单与选择器同宽
                                            style={{ width: '100%' }}
                                            dataSource={this.state.dataSource}
                                            value={this.state.searchModel.shopName || ''}
                                            onSelect={(e) => this.onSelect(e)}
                                            onChange={this.onChange}
                                            onSearch={this.onSearch}
                                            filterOption={true}     //是否根据输入项进行筛选。
                                            placeholder="店铺名称"
                                        />
                                    </Col>
                                    <Col span={2} className="zhTxt">会员卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="会员卡号"
                                            value={this.state.searchModel.cardNumber || ''}
                                            onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">支付日期：</Col>
                                    <Col span={6} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            allowClear={true}
                                            className="headResponsibleDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("startTime", value)}
                                            format={Config.dateFormat}/>
                                        &nbsp;&nbsp;至&nbsp;&nbsp;
                                        <DatePicker placeholder="至日期"
                                            allowClear={true}
                                            className="headResponsibleDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("endTime", value)}
                                            format={Config.dateFormat}/>
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={2} className="zhTxt">支付状态：</Col>
                                    <Col span={3} className="zhInput">
                                        <Select style={{ width: "100%" }} placeholder="支付状态" 
                                            value={this.state.searchModel.status || ''}
                                            onChange={(e) => this.searchChange('status', e)}>
                                            <Option  key="" value="">全部</Option >
                                            {context.wechatPaymentFlowMap.map((item) => <Option  key={item.key} value={item.key}>{item.value}</Option>)}
                                        </Select>   
                                    </Col>
                                    <Col span={2} className="zhTxt">会员手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="会员手机号"
                                            value={this.state.searchModel.phone || ''}
                                            onChange={(e) => this.searchChange('phone', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">创建日期：</Col>
                                    <Col span={6} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            allowClear={true}
                                            className="headResponsibleDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.createdStartDate == null ? null : moment(new Date(this.state.searchModel.createdStartDate), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("createdStartDate", value)}
                                            format={Config.dateFormat}/>
                                        &nbsp;&nbsp;至&nbsp;&nbsp;
                                        <DatePicker placeholder="至日期"
                                            allowClear={true}
                                            className="headResponsibleDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.createdEndDate == null ? null : moment(new Date(this.state.searchModel.createdEndDate), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("createdEndDate", value)}
                                            format={Config.dateFormat}/>
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.searchSalesReceiveFlow()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                {Config.userInfo.phone==="17777777777"||Config.userInfo.phone==="18888888888"?
                                    <Row >
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.forceDetection()} icon="export">强制检测支付状态</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.forcePayment()} icon="export">强制修改支付状态</Button>
                                    </Row>
                                 : null}
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="flowSalesReceiveId"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'flowSalesReceiveId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                    />
                                </Row>
                            </TabPane>
                            <TabPane tab="营业额奖励流水" key='2'>
                                <Row className="row">
                                    <Col span={2} className="zhTxt">店铺名称：</Col>
                                    <Col span={3} className="zhInput">
                                        <AutoComplete
                                            dropdownMatchSelectWidth={false}//下拉菜单与选择器同宽
                                            style={{ width: '100%' }}
                                            dataSource={this.state.dataSource}
                                            value={this.state.model.shopName || ''}
                                            onSelect={(e) => this.onSelectModel(e)}
                                            onChange={this.onChangeModel}
                                            onSearch={this.onSearchModel}
                                            filterOption={true}     //是否根据输入项进行筛选。
                                            placeholder="店铺名称"
                                        />
                                    </Col>
                                    <Col span={2} className="zhTxt">消费者卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="消费者卡号"
                                            value={this.state.model.cardNumber || ''}
                                            onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">消费者手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="消费者手机号"
                                            value={this.state.model.phone || ''}
                                            onChange={(e) => this.modelChange('phone', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={2} className="zhTxt">奖励人卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="奖励人卡号"
                                            value={this.state.model.referrerCardnumber || ''}
                                            onChange={(e) => this.modelChange('referrerCardnumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">奖励人手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="奖励人手机号"
                                            value={this.state.model.referrerPhone || ''}
                                            onChange={(e) => this.modelChange('referrerPhone', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">打款日期：</Col>
                                    <Col span={6} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            allowClear={true}
                                            className="headResponsibleDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.model.startTime == null ? null : moment(new Date(this.state.model.startTime), Config.dateFormat)}
                                            onChange={(value) => this.modelChange("startTime", value)}
                                            format={Config.dateFormat}/>
                                        &nbsp;&nbsp;至&nbsp;&nbsp;
                                        <DatePicker placeholder="至日期"
                                            allowClear={true}
                                            className="headResponsibleDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.model.endTime == null ? null : moment(new Date(this.state.model.endTime), Config.dateFormat)}
                                            onChange={(value) => this.modelChange("endTime", value)}
                                            format={Config.dateFormat}/>
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={2} className="zhTxt">打款状态：</Col>
                                    <Col span={3} className="zhInput">
                                        <Select style={{ width: "100%" }} placeholder="打款状态" 
                                            value={this.state.model.status || ''}
                                            onChange={(e) => this.modelChange('status', e)}>
                                            <Option  key="" value="">全部</Option >
                                            {context.wechatPaymentFlowMap.map((item) => <Option  key={item.key} value={item.key}>{item.value}</Option>)}
                                        </Select>   
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.searchSalesPaymentFlow()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.resetFsp()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row className='zhRow'>
                                    <Table
                                        rowKey="flowSalesPaymentId"
                                        bordered={true} //边线
                                        columns={columnsFsp} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.flowSalesPaymentTables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChangeFsp,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChangeFsp,    //每页显示多少条数据方法
                                            total: this.state.model.total,             //总共多少条数据
                                            current: this.state.model.page,         //当前页
                                            pageSize: this.state.model.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        // onRow={(record) => {
                                        //     return {
                                        //         onClick: event => Common.selectRadioRow.call(this, record, 'flowSalesPaymentId', 'selectedRowsFsp', 'selectedRowKeysFsp'), // 点击行
                                        //     };
                                        // }}
                                    />
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }


    /**强制检测*/
    forceDetection() {
        console.log(Config.userInfo.phone)
        console.log("this.state.selectedRows",this.state.selectedRows)
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        if (this.state.selectedRows[0].status !=="NOTPAY") {
            Message.openMsgError("错误", "未支付订单才允许进行强制检测")
            return
        }
        
        let that = this; 
        confirm({
            title: '您确定需要强制检测支付状态?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "shopTurnoverFlow/forceDetection", {
                    method: 'POST',
                    body: JSON.stringify(that.state.selectedRows[0]),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("提示","强制检测支付状态成功")
                        // Message.openMsgSuccess("提示", Common.isNotEmpty(data.object)?data.object:"强制检测支付状态成功")
                        // that.reset()
                        that.searchSalesReceiveFlow();
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }

    /**强制修改*/
    forcePayment() {
        console.log(Config.userInfo.phone)
        console.log("this.state.selectedRows",this.state.selectedRows)
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        if (this.state.selectedRows[0].status !=="NOTPAY") {
            Message.openMsgError("错误", "未支付订单才允许进行强制付款")
            return
        }
        
        let that = this; 
        confirm({
            title: '您确定需要强制修改支付状态?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "shopTurnoverFlow/forcePayment", {
                    method: 'POST',
                    body: JSON.stringify(that.state.selectedRows[0]),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        // Message.openMsgSuccess("提示", Common.isNotEmpty(data.object)?data.object:"强制修改支付状态成功")
                        Message.openMsgSuccess("提示","强制修改支付状态成功")
                        // that.reset()
                        that.searchSalesReceiveFlow();
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }


}