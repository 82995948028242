import React, { Component } from 'react';
import { InputNumber, Button, Row, Col, Tabs, Table, Input, Select, Modal, DatePicker, AutoComplete, Spin } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import './consumptionManagementOfPlatform.css';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';


const confirm = Modal.confirm;
var css;
export default class ConsumptionManagementOfPlatform extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            searchModel: { pageSize: 10, page: 1, type: "店铺消费明细"},
            isLoading:true,
            isExport: false,

            shopNameList: [],
            dataSource: [], // 自动填充数据
        }
    }

    componentDidMount() {
        this.search();
        this.searchShop();
    }

    searchShop() {
        fetch(Config.apiBackServer + "shop/searchShop", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let list = [];
            if ("SUCCESS" === data.msg) {
                data.object.list.forEach(element => {
                    list.push(element.shopName)
                });
                this.setState({
                    shopNameList:list
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode);
            }
        }).catch(err => { });
    }

    search() {
        this.setState({isLoading:true})
        fetch(Config.apiBackServer + "consumptionManagementOfPlatform/searchbyShopAll", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
            console.log(data)
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    onSelect(value) {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({
            searchModel: model,
            dataSource: []
        })
    }

    onChangeShop = (value) => {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({ 
            searchModel: model
        });
    }

    onSearch = searchText => {
        let model = this.state.searchModel
        model.shopName = searchText
        let dataSource = [];
        if (searchText) {
            let list = this.state.shopNameList
            for(let i=0; i < list.length; i++) {
                if (list[i].split(searchText).length > 1) {
                    dataSource.push(list[i]);
                }
            }
        }
        this.setState({
            dataSource: dataSource
        })
    };

    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, funcName: "" },
            selectedRowKeys: [],
            selectedRows: [],
        },() => {
            this.search()
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let module = this.state.selectedRows[0];
        module.cashierType = ContextHandler.getTransactionTypeValue(module.cashierType);
        this.setState({
            tabIndex: "2",
            flag: "edit",
            model: module
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({
            model: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            // searchModel: { pageSize: 10, page: 1, },
        })
    }

    tableChange(value) {
        if (value === "2") {
            this.edit();
        } else {
            this.setState({
                tab: value,
            })
            this.cancel();
        }
    }

    onChange = e => {
        this.setState({
            value: e.target.value,
        });
    };

    selectRadioRow(record) {
        let selectedRowKeys = this.state.selectedRowKeys;
        let checkboxState = this.state.selectedRows;
        let i = checkboxState.indexOf(record);
        if (i > -1) {
            selectedRowKeys = [];
            checkboxState = [];
        } else {
            selectedRowKeys = [record.shopId + record.cardNumber + record.cashierTime];
            checkboxState = [record];
        }
        this.setState({ selectedRowKeys : selectedRowKeys, selectedRows: checkboxState });
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: "tableWidth9",
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth9",
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: "tableWidth9",
            },
            {
                title: '推荐卡号',
                dataIndex: 'recommendCardNumber',
                className: "tableWidth9",
            },
            {
                title: '推荐卡手机号',
                dataIndex: 'recommendCardPhone',
                className: "tableWidth9",
            },
            {
                title: '消费日期',
                dataIndex: 'cashierTime',
                className: "tableWidth9",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '返现金额',
                dataIndex: 'footAmount',
                className: "tableWidth9 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmount',
                className: "tableWidth9 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '总金额',
                dataIndex: 'tradeAmount',
                className: "tableWidth9 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '消费方式',
                dataIndex: 'cashierType',
                className: "tableWidth9",
                render: (text) => ContextHandler.getTransactionTypeValue(text)
            },
            {
                title: '收银状态',
                dataIndex: 'cashierStatus',
                className: "tableWidth9",
                render: (text) => ContextHandler.getMapValue('cashierStatusMap', text)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };

        return (
            <div className="consumptionManagement">
                <div className="cashier">
                    <div className="zhRoot">
                        <Spin spinning={this.state.isExport} tip="下载中...">
                            <div className="zhTitle">店铺消费明细</div>
                            <div className="content">
                                <Tabs activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                    <TabPane tab="列表" key="1">
                                        <Row className="row">
                                            <Col span={2} className="zhTxt">店铺名称：</Col>
                                            <Col span={3} className="zhInput">
                                                <AutoComplete
                                                    dropdownMatchSelectWidth={false}//下拉菜单与选择器同宽
                                                    style={{ width: '100%' }}
                                                    dataSource={this.state.dataSource}
                                                    value={this.state.searchModel.shopName || ''}
                                                    onSelect={(e) => this.onSelect(e)}
                                                    onChange={this.onChangeShop}
                                                    onSearch={this.onSearch}
                                                    filterOption={true}     //是否根据输入项进行筛选。
                                                    placeholder="店铺名称"
                                                />
                                            </Col>
                                            <Col span={2} className="zhTxt">会员卡号：</Col>
                                            <Col span={3} className="zhInput">
                                                <Input maxLength={10}
                                                    placeholder="会员卡号"
                                                    value={this.state.searchModel.cardNumber || ''}
                                                    onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                            </Col>
                                            <Col span={2} className="zhTxt">会员手机号：</Col>
                                            <Col span={3} className="zhInput">
                                                <Input maxLength={11}
                                                    placeholder="会员手机号"
                                                    value={this.state.searchModel.phone || ''}
                                                    onChange={(e) => this.searchChange('phone', e.target.value)} />
                                            </Col>
                                        
                                        </Row>
                                        <Row className="row">
                                            <Col span={2} className="zhTxt">推荐卡号：</Col>
                                            <Col span={3} className="zhInput">
                                                <Input maxLength={20}
                                                    placeholder="推荐卡号"
                                                    value={this.state.searchModel.recommendCardNumber || ''}
                                                    onChange={(e) => this.searchChange('recommendCardNumber', e.target.value)} />
                                            </Col>
                                            <Col span={2} className="zhTxt">推荐卡手机号：</Col>
                                            <Col span={3} className="zhInput">
                                                <Input maxLength={20}
                                                    placeholder="推荐卡手机号"
                                                    value={this.state.searchModel.recommendCardPhone || ''}
                                                    onChange={(e) => this.searchChange('recommendCardPhone', e.target.value)} />
                                            </Col>
                                            <Col span={2} className="zhTxt">消费日期：</Col>
                                            <Col span={7} className="zhInput">
                                                <DatePicker placeholder="由日期"
                                                    className="consumptionManagementDate"
                                                    allowClear={true}
                                                    disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                    value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                                    onChange={(value) => this.searchChange("startTime", value)}
                                                    format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                                <DatePicker placeholder="至日期"
                                                    className="consumptionManagementDate"
                                                    allowClear={true}
                                                    disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                    value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                                    onChange={(value) => this.searchChange("endTime", value)}
                                                    format={Config.dateFormat} />
                                            </Col> 
                                        </Row>
                                        <Row className="row">
                                            <Col span={2} className="zhTxt">收银状态：</Col>
                                            <Col span={3} className="zhInput">
                                                <Select style={{ width: "100%" }} placeholder="收银状态" 
                                                    value={this.state.searchModel.cashierStatus || ''}
                                                    onChange={(e) => this.searchChange('cashierStatus', e)}>
                                                    <Option  key="" value="">全部</Option >
                                                    {context.cashierStatusMap.map((item) => <Option  key={item.key} value={item.key}>{item.value}</Option>)}
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className="rowButton">
                                            <Col className="zhRowButtonRight">
                                                <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                                <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.edit()} icon="eye">查看</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" type="primary" onClick={() => this.exportExcel()} icon="download">导出</Button>
                                        </Row>
                                        <Row className='zhRow'>
                                            <Table rowSelection={rowSelection}
                                                rowKey={record => record.shopId + record.cardNumber + record.cashierTime}
                                                bordered={true} //边线
                                                columns={columns} //列名
                                                dataSource={this.state.tables} //数据
                                                loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                                pagination={{  //分页
                                                    showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                    onChange: this.pageChange,            //上页下页或者跳页方法
                                                    onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                    total: this.state.searchModel.total,             //总共多少条数据
                                                    current: this.state.searchModel.page,         //当前页
                                                    pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                                    defaultCurrent: 1,                               //默认当前页为1
                                                }}
                                                onRow={(record) => {
                                                    return {
                                                        onClick: event => this.selectRadioRow(record), // 点击行
                                                    };
                                                }}
                                                rowClassName="tableColor"
                                            />
                                        </Row>
                                    </TabPane>
                                    <TabPane tab="详情" key="2">
                                        <div className="">
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>会员卡号：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input
                                                        maxLength={10}
                                                        disabled
                                                        placeholder="会员卡号"
                                                        value={this.state.model.cardNumber || ''}
                                                        onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                                </Col>
                                                <Col span={4} className='zhTxt'>会员手机号：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input
                                                        maxLength={11}
                                                        disabled
                                                        placeholder="会员手机号"
                                                        value={this.state.model.phone || ''}
                                                        onChange={(e) => this.modelChange('phone', e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>返现金额：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input className="zhInputNumber"
                                                        placeholder="返现金额"
                                                        disabled
                                                        value={Common.numberFormat(this.state.model.footAmount,2) || ''}
                                                        onChange={(e) => this.modelChange('footAmount', e)} />
                                                </Col>
                                                <Col span={4} className='zhTxt'>不返现金额：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input className="zhInputNumber"
                                                        min={0}
                                                        disabled
                                                        placeholder="不返现金额"
                                                        value={Common.numberFormat(this.state.model.wineAmount,2) || ''}
                                                        onChange={(e) => this.modelChange('wineAmount', e)} />
                                                </Col>
                                            </Row>
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>总金额：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input className="zhInputNumber"
                                                        min={0}
                                                        disabled
                                                        placeholder="合计金额"
                                                        value={Common.numberFormat(this.state.model.tradeAmount , 2) || ''}
                                                        onChange={(e) => this.modelChange('tradeAmount', e)} />
                                                </Col>
                                                <Col span={4} className='zhTxt'>消费方式：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Select
                                                        style={{ width: "100%" }}
                                                        placeholder="消费方式"
                                                        value={this.state.model.cashierType || ''}
                                                        disabled
                                                    >
                                                    </Select>
                                                </Col>
                                            </Row>
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>消费状态：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Select
                                                        style={{ width: "100%" }}
                                                        placeholder="消费状态"
                                                        value={ContextHandler.getMapValue('cashierStatusMap', this.state.model.cashierStatus) || ''}
                                                        disabled
                                                    >
                                                    </Select>
                                                </Col>
                                                <Col span={4} className='zhTxt'>消费时间：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input className="zhInputNumber"
                                                        min={0}
                                                        disabled
                                                        placeholder="消费时间"
                                                        value={moment(this.state.model.cashierTime).format(Config.dateFormatALL)}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row className='zhRow zhRowButtonRight'>
                                            <Button className="zhButtonRight" icon="rollback" onClick={() => { this.cancel() }}>返回</Button>
                                        </Row>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
    exportExcel() {
        this.setState({ isExport: true });
        fetch(Config.apiBackServer + "consumptionManagementOfPlatform/exportExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                       // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "店铺消费明细.xlsx";
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    this.cancel();
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({ isExport: false })
        });
    }
}