import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Input, Select, DatePicker, Spin} from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import './shopTurnoverDetail.css';
const { TextArea } = Input;

export default class ShopTurnoverDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            isLoading: false,
            isExport: false,
            searchModel: {shopId: Config.userInfo.shopId ,pageSize: 10, page: 1, auditStatus: "",paymentStartTime: new Date().setDate(new Date().getDate() - 7), paymentEndTime:new Date() },
        }
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState({ isLoading: true });
        let model = this.state.searchModel;
        fetch(Config.apiBackServer + "shopTurnoverDetail/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }


    
    searchChange(k, e) {
        let { searchModel} = this.state;
        searchModel[k] = e;
        this.setState({ searchModel });
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            selectedRowKeys: [],
            selectedRows: [],
            searchModel: searchModel,
        });
        this.search();
    }

    
    tableChange(value) {
        if (value === "2") {
            this.eye();
        } else {
            this.setState({ tabIndex: value, })
            this.cancel();
        }
    }

    /**点击查看进编辑页 */
    eye() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let model = this.state.selectedRows[0];
        if (model.auditStatus === "CHECKED") {
            this.setState({
                tabIndex: "2",
                flag: "edit",
                model: model,
            })
        } else{
            Message.openMsgError("错误", "未审核数据无法查看")
        }
    }

    reset() {
        this.setState({
            selectedRows: [],
            selectedRowKeys: [],
            searchModel: {
                page: 1,
                pageSize: 10,
                auditStatus: "",
                shopId: Config.userInfo.shopId ,
                auditStartDate: null,
                auditEndDate: null,
                cardNumber: '',
                paymentStartTime: new Date().setDate(new Date().getDate() - 7),
                paymentEndTime:new Date() ,
            },
        }, () => {
            this.search()
        })
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            model: {},
            selectedRowKeys: [],
            selectedRows: [],
        })
        this.search();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '会员卡号',
                dataIndex: 'vipNumber',
                className: "tableWidth10",
            },
            {
                title: '会员手机号',
                dataIndex: 'vipPhone',
                className: "tableWidth10",
            },
            {
                title: '收银员卡号',
                dataIndex: 'operatorNumber',
                className: 'tableWidth10',
            },
            {
                title: '收银员手机号',
                dataIndex: 'operatorPhone',
                className: "tableWidth10",
            },
            {
                title: '营业额日期',
                dataIndex: 'paymentTime',
                className: "tableWidth11",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmount',
                className: "tableRightAlignment tableWidth9",
                render: (text) => Common.numberFormat(text || 0, 2)
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmount',
                className: "tableRightAlignment tableWidth9",
                render: (text) => Common.numberFormat(text || 0, 2)
            },
            {
                title: '奖励金额',
                dataIndex: 'paymentAmount',
                className: "tableRightAlignment tableWidth9",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '审核状态',
                dataIndex: 'auditStatus',
                className: "tableWidth9",
                render: (text) => ContextHandler.getMapValue('auditStatusMap', text)
            },
            // {
            //     title: '审核人',
            //     dataIndex: 'nickName',
            //     className: "tableWidth7",
            //     render: (text,record) => record.auditStatus === 'CHECKED' ? record.nickName  : ""
            // },
            {
                title: '审核时间',
                dataIndex: 'auditDate',
                className: "tableWidth11",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            }, 
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };

        return (
            <div className="shopTurnoverDetail">
                <div className="zhRoot">
                    <Spin spinning={this.state.isExport} tip="下载中...">
                        <div className="zhTitle">营业额提成</div>
                        <div className="content">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="列表" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">营业额日期：</Col>
                                        <Col span={6} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                value={this.state.searchModel.paymentStartTime == null ? null : moment(new Date(this.state.searchModel.paymentStartTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("paymentStartTime", value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                        <DatePicker placeholder="至日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                value={this.state.searchModel.paymentEndTime == null ? null : moment(new Date(this.state.searchModel.paymentEndTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("paymentEndTime", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.vipNumber || ''}
                                                onChange={(e) => this.searchChange('vipNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="会员手机号"
                                                value={this.state.searchModel.vipPhone || ''}
                                                onChange={(e) => this.searchChange('vipPhone', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">审核日期：</Col>
                                        <Col span={6} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                value={this.state.searchModel.auditStartDate == null ? null : moment(new Date(this.state.searchModel.auditStartDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("auditStartDate", value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                value={this.state.searchModel.auditEndDate == null ? null : moment(new Date(this.state.searchModel.auditEndDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("auditEndDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={2} className="zhTxt">审核状态：</Col>
                                        <Col span={3} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.searchModel.auditStatus || ''}
                                                onChange={(e) => this.searchChange('auditStatus', e)} >
                                                <Option key="" value={""}>全部</Option>
                                                <Option key="CHECKED" value={"CHECKED"}>已审核</Option>
                                                <Option key="UNCHECKED" value={"UNCHECKED"}>未审核</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.eye()} icon="eye">查看</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" type="primary" onClick={() => this.exportExcel()} icon="download">导出</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey="detailsShopSalesPaymentId"
                                            // scroll={{ x: 1000 }}
                                            bordered={true}                                     //边线
                                            columns={columns}                                   //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables}                      //数据
                                            pagination={{                                       //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,                      //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,              //每页显示多少条数据方法
                                                total: this.state.searchModel.total,            //总共多少条数据
                                                current: this.state.searchModel.page,           //当前页
                                                pageSize: this.state.searchModel.pageSize,      //每页显示多少条数据
                                                defaultCurrent: 1,                              //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'detailsShopSalesPaymentId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                        />
                                    </Row>
                                </TabPane>
                                <TabPane tab="详情" key="2">
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>会员卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.model.vipNumber} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>会员手机号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.model.vipPhone} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>收银员卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.model.operatorNumber} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>收银员手机号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.model.operatorPhone} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>返现金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled 
                                            value={Common.numberFormat(this.state.model.foodAmount || 0, 2)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>不返现金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled 
                                            value={Common.numberFormat(this.state.model.wineAmount || 0, 2)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>营业额日期：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' disabled value={this.state.model.paymentTime == null ? moment(new Date()).format(Config.dateFormatALL) : moment(this.state.model.paymentTime).format(Config.dateFormatALL)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>奖励金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled 
                                            value={Common.numberFormat(this.state.model.paymentAmount || 0, 2)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>审核状态：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled
                                                value={ContextHandler.getMapValue('auditStatusMap', this.state.model.auditStatus)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>审核时间：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' disabled value={this.state.model.auditDate == null ? moment(new Date()).format(Config.dateFormatALL) : moment(this.state.model.auditDate).format(Config.dateFormatALL)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>备注：</Col>
                                        <Col span={16} className='RemarkInput'>
                                            <TextArea
                                                value={this.state.model.auditRemark || ""}
                                                className='disableFontColor'
                                                onChange={(e) => this.modelChange('auditRemark', e.target.value)}
                                                placeholder="备注"
                                                disabled
                                                maxLength={500}
                                                autoSize={{ minRows: 3, maxRows: 3 }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='zhRow zhRowButtonRight'>
                                        <Button className="zhButtonRight" icon="rollback" onClick={() => { this.cancel() }}>返回</Button>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Spin>
                </div>
            </div>
        )
    }
    exportExcel() {
        this.setState({ isExport: true });
        fetch(Config.apiBackServer + "shopTurnoverDetail/exportExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "营业额提成.xlsx";
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({ isExport: false })
        });
    }
}