import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Input, DatePicker, } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import './cashier.css';

export default class Cashier extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "2",
            selectedRowKeys: [],
            selectedRows: [],
            selectedRowKeysThree: [],
            selectedRowsThree: [],
            model: {},
            tables: [],
            tables2: [],//收银员查询数据
            searchModel: { pageSize: 10, page: 1, },
            funcList: [],
            searchShopList: [],
            allowEditing: false,
            value: 'CASH',
            amountFlag: '', //不返现或者返现输入框标志位
            summaryModel: {},
            phoneList: [],
            cardList: [],
            tables1: [],//收银列表
            searchModelThree: { pageSize: 10, page: 1, },
            tablesThree: [],
            isLoading: false,
            isTimerSearch:false,
            timeDown: 600
        }
    }

    componentDidMount() {
        this.search();
        this.searchShop();
        this.searchCashier();//收银员查询数据 
        this.searchNoColsingList();
    }


    searchCashier() {
        console.log("searchCashier----")
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "cashier/searchCashier", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log("data")
            console.log(data)
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables1: data.object,
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchNoColsingList() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "cashier/searchNoColsingList", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({
                    tables2: data.object,
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchShop() {
        fetch(Config.apiBackServer + "openMembership/searchShop", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
            this.setState({
                searchShopList: data.object.list
            }, () => {
                let model = { shopId: this.state.searchShopList[0].shopId }
                this.setState({
                    model: model
                })
            })
        }).catch(err => { });
    };

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "cashier/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }


    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, funcName: "" },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    onSelectChangeThree = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeysThree: selectedRowKeys,
            selectedRowsThree: selectedRows
        })
    }

    //清空列表2选中
    clearSelectThree() {
        this.setState({ selectedRowKeysThree: [], selectedRowsThree: [] });
    }

    add() {
        this.setState({
            tabIndex: "2",
            flag: "add",
            model: {
                openDate: new Date(),
                endDate: new Date().setFullYear(new Date().getFullYear() + 1),
                active: 'Y',
                shopId: this.state.searchShopList[0].shopId,
                allowEditing: true,
                allowEditing1: true,
                cashierType: "CASH",
            },
        })
    }
    clear() {
        var model = this.state.model;
        model.footAmount = "";
        model.countAmount = "";
        model.wineAmount = "";
        model.phone = "";
        model.remark = "";
        model.cardNumber = "";
        model.paymentAmount = "";
        model.giveChangeAmount = "";
        model.headPortrait = "";
        this.setState({
            model: model
        })
    }
    /**选中右边键盘数字,改变左边金额 */
    changeNumber(flag) {
        var model = this.state.model;
        if ("footAmount" === this.state.amountFlag) {
            if (model.footAmount == null || model.footAmount === "" || model.footAmount === undefined) {
                model.footAmount = flag;

            } else {
                if (model.footAmount.length < 9) {
                    model.footAmount = model.footAmount + flag;
                }

            }
            model.countAmount = this.countSum("footAmount", model.footAmount);
            if(Common.isNotEmpty(model.paymentAmount)){
                model.giveChangeAmount = this.SUB(model.paymentAmount, model.countAmount);
            }
        } else if ("wineAmount" === this.state.amountFlag) {
            if (model.wineAmount == null || model.wineAmount === "" || model.wineAmount === undefined) {
                model.wineAmount = flag;
            } else {
                if (model.wineAmount.length < 9) {
                    model.wineAmount = model.wineAmount + flag;
                }
            }
            model.countAmount = this.countSum("wineAmount", model.wineAmount);
            if(Common.isNotEmpty(model.paymentAmount)){
                model.giveChangeAmount = this.SUB(model.paymentAmount, model.countAmount);
            }
        } else if ("paymentAmount" === this.state.amountFlag) {
            if (model.paymentAmount == null || model.paymentAmount === "" || model.paymentAmount === undefined) {
                model.paymentAmount = flag;
            } else {
                if (model.paymentAmount.length < 9) {
                    model.paymentAmount = model.paymentAmount + flag;
                }
            }

            if (model.paymentAmount != null && model.countAmount != null) {
                model.giveChangeAmount = this.SUB(model.paymentAmount, model.countAmount);
            }
        }

        this.changeNumberColor(flag);

        this.setState({
            model: model
        })
    }

    /** 选择键盘数字 改变背景颜色方法*/
    changeNumberColor(flag) {
        let number1 = document.getElementById("1");
        let number2 = document.getElementById("2");
        let number3 = document.getElementById("3");
        let number4 = document.getElementById("4");
        let number5 = document.getElementById("5");
        let number6 = document.getElementById("6");
        let number7 = document.getElementById("7");
        let number8 = document.getElementById("8");
        let number9 = document.getElementById("9");
        let number0 = document.getElementById("0");
        let number00 = document.getElementById("00");
        let number = document.getElementById(".");
        number7.className = "number lineRight lineBottom";
        number8.className = "number lineRight lineBottom";
        number9.className = "number lineBottom";
        number4.className = "number lineRight lineBottom";
        number5.className = "number lineRight lineBottom";
        number6.className = "number  lineBottom";
        number1.className = "number lineRight  lineBottom";
        number2.className = "number lineRight  lineBottom";
        number3.className = "number  lineBottom";
        number0.className = "number lineRight lineBottom";
        number00.className = "number lineRight lineBottom";
        number.className = "number  lineBottom";
        let firstDiv = document.getElementById(flag);
        if ("1" === flag || "7" === flag || "8" === flag || "4" === flag || "5" === flag || "2" === flag || "0" === flag || "00" === flag) {
            firstDiv.className = "number lineRight lineBottom bottomCalrbul";
        } else {
            firstDiv.className = "number lineBottom bottomCalrbul";
        }
    }


    /**挂单 */
    myList() {
        let msg = this.check();
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        let model = this.state.model;
        fetch(Config.apiBackServer + "cashier/myList", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({
                    model: {}
                })
                this.searchNoColsingList();
                Message.openMsgSuccess("成功", "保存成功");

            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        }).catch(err => { });
    }

    /**结算 */
    settlement() {
        let msg = this.check();
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        let model = this.state.model;
        fetch(Config.apiBackServer + "cashier/insert", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                // this.cancel();
                this.setState({
                    model: {},
                    timeDown: 600
                })
                Message.openMsgSuccess("成功", "保存成功");
                this.searchCashier();//收银员查询数据
                console.log("settlement----")
                if (!this.state.isTimerSearch) {
                    this.countDownFunc();
                }
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        }).catch(err => { }).finally(() =>{});
    }

    countDownFunc(){
        console.log("countDownFunc----")
        let that = this;
        let countDownTimer = setTimeout(() => {
            that.setState({isTimerSearch: true,timeDown:that.state.timeDown-5},()=>{
                if(that.state.timeDown <= 0){

                    console.log("clearTimeout----")
                    that.setState({isTimerSearch: false });
                    clearTimeout(that.state.countDownTimer); 
                }else{
                    that.searchCashier();
                    let endDate = Date.parse(new Date()); 
                    let record =that.state.tables1[0];
                    let startTime=record.cashierTime;
                    console.log("Date.parse");
                    console.log(startTime);
                    console.log(endDate);
                    console.log(record.cashierStatus);
                    if(record.cashierStatus==="PAID" || (endDate-startTime > 6000000 ) ){
                        that.setState({isTimerSearch: false });
                        clearTimeout(that.state.countDownTimer); 
                    } else {
                        that.countDownFunc();
                    }   
                }
            })
        }, 5000);
        this.setState({countDownTimer})
    }

    check() {
        let msg = ""
        let model = this.state.model
        if (model.phone == null || model.phone === '') {
            msg += "请输入会员手机号;"
        }
        if (model.cardNumber == null || model.cardNumber === '') {
            msg += "请输入会员卡号;"
        }
        if (model.footAmount == null || model.footAmount === '') {
            msg += "请输入返现金额;"
        }
        console.log(model.footAmount)
        console.log(Common.numberFormat(model.footAmount, 2))
        if(Common.isNotEmpty(model.footAmount) && Common.numberFormat(model.footAmount, 2) === "0.00"){
            msg += "请输入正确金额;"
        }
        if (model.giveChangeAmount < 0) {
            msg += "找零金额错误;"
        }
        if (Common.isEmpty(model.nickName)) {
            msg += "未找到该会员信息，请重新输入会员手机号或会员卡号;"
        }
        return msg
    }
    //自动加上三天
    getNextDay(d, t) {
        console.log(d, t)//格式为---2019-02-13 3
        d = new Date(d);
        console.log(d)//格式为---Wed Feb 13 2019 08:00:00 GMT+0800 (中国标准时间)
        d = +d + (1000 * 60 * 60 * 24) * t;
        console.log(d)//格式为--时间戳1550275200000
        // d = new Date(d);
        // console.log(d)//格式为---Sat Feb 16 2019 08:00:00 GMT+0800 (中国标准时间)
        return new Date(d)   //格式为"2019-02-16 00:00:00"
    }

    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let model = this.state.selectedRows[0];
        model.countAmount = this.countSum1(model.wineAmount, model.footAmount);
        this.setState({
            tabIndex: "2",
            flag: "edit",
            model: model,
        })

    }

    //定义2个数相加函数
    countSum1(value1, value2) {
        let d = 3,//定义小数位的初始长度，默认为整数，即小数位为0
            sum = 0,//定义sum来接收所有数据的和
            //循环所有的参数
            m = Math.pow(10, d);
        if (value1 != null && value1 !== "" && value1 !== undefined) {
            sum += value1 * m;
        }

        if (value2 != null && value2 !== "" && value2 !== undefined) {
            sum += value2 * m;
        }
        //返回结果
        return sum / m;

    }


    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    //键盘监听事件
    handleKeyDown(e) {
        let model = this.state.model;
        console.log(e.keyCode)
        if (e.keyCode === 13 || e.keyCode === 9) {//按下了Enter键 或者Tab切换键

            if (this.state.amountFlag === "phone") {
                if (model.phone == null || model.phone === undefined || model.phone === "") {
                    this.clear();
                    Message.openMsgError('请输入会员手机号.');
                } else {
                    this.changeSearch('phone');
                }
            } else if (this.state.amountFlag === "cardNumber") {
                if (model.cardNumber == null || model.cardNumber === "" || model.cardNumber === undefined) {
                    this.clear();
                    Message.openMsgError('请输入会员卡号.');
                } else {
                    this.changeSearch('cardNumber',true);
                }
            }
        }
    }

    //定义根据属性减法函数
    SUB(value1, value2) {
        let d = 3,//定义小数位的初始长度，默认为整数，即小数位为0
            sub = 0,//定义sum来接收所有数据的差
            //循环所有的参数
            m = Math.pow(10, d);
        sub = value1 * m - value2 * m;
        //返回结果
        return sub / m;

    }

    changeSearch(k,flag) {
        let newModel = this.state.model;
        newModel.searchType = k;
        fetch(Config.apiBackServer + "cashier/searchInfo", {
            method: 'POST',
            body: JSON.stringify(newModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log(data);
            if ("SUCCESS" === data.msg) {
                if(Common.isNotEmpty(data.object)){
                    newModel.phone = data.object.phone;
                    newModel.cardNumber = data.object.cardNumber;
                    newModel.cardType = data.object.cardType;
                    newModel.headPortrait = data.object.headPortrait;
                    newModel.nickName = data.object.nickName;
                    this.setState({
                        model: newModel,
                    });
                }
            } else {
                console.log(data.msg);
                if(flag){
                    Message.openMsgError("错误", data.msg)
                }
                let inputs = document.getElementById(k);
                inputs.focus();
                if (k === 'phone') {
                    this.clearPhone();
                } else if (k === 'cardNumber') {
                    this.clearCardNumber();
                }
            }
        }).catch(err => { });
    }

    clearPhone() {
        let model = this.state.model;
        model.cardNumber = "";
        model.headPortrait = "";
        model.nickName = "";
        model.cardType = "";
        model.footAmount = "";
        model.wineAmount = "";
        model.remark ="";
        this.setState({
            model: model
        })
    }
    
    clearCardNumber() {
        let model = this.state.model;
        model.phone = "";
        model.headPortrait = "";
        model.nickName = "";
        model.cardType = "";
        model.footAmount = "";
        model.wineAmount = "";
        model.remark ="";
        this.setState({
            model: model
        })
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        if (k === 'footAmount' || k === 'wineAmount') {
            if (e.length < 8) {
                let num = e.replace(/^ +| +$/g, '').replace(/[^\d\.]/g, '');
                if (num !== 0) {
                    newModel[k] = num;
                    newModel['countAmount'] = this.countSum(k, num);
                } else {
                    newModel[k] = '';
                    if (Common.isNotEmpty(newModel.wineAmount)) {
                        newModel['countAmount'] = this.countSum('wineAmount', newModel.wineAmount)
                    } else if (Common.isNotEmpty(newModel.footAmount)) {
                        newModel['countAmount'] = this.countSum('footAmount', newModel.footAmount)
                    } else {
                        newModel['countAmount'] = '';
                    }
                }
            }
        } else if (k === 'remark' || k === 'cashierType') {
            newModel[k] = e.target.value;
        } else {
            newModel[k] = e;
        }
        if (k === "paymentAmount") {
            if (e.length < 8) {
                let number = e.replace(/^ +| +$/g, '').replace(/[^\d\.]/g, '');
                if (number !== 0) {
                    newModel[k] = number;
                } else {
                    newModel[k] = null;
                }
            }
        }
        //找零
        if (Common.isNotEmpty(newModel.countAmount) && Common.isNotEmpty(newModel.paymentAmount)) {
            newModel['giveChangeAmount'] = this.SUB(newModel.paymentAmount, newModel.countAmount);
        } else {
            newModel['giveChangeAmount'] = '';
        }

        if (k === 'phone' ) {
            if(e.length < 11){
                newModel.cardNumber = '';
                newModel.headPortrait = "";
                newModel.nickName = "";
                newModel.cardType = "";
                newModel.footAmount = "";
                newModel.wineAmount = "";
                newModel.remark ="";
                this.setState({
                    model: newModel,
                });
            }else if(e.length === 11){
                this.changeSearch(k,true);
            }
        }

        if (k === 'cardNumber') {
            newModel.phone = '';
            newModel.headPortrait = "";
            newModel.nickName = "";
            newModel.cardType = "";
            newModel.footAmount = "";
            newModel.wineAmount = "";
            newModel.remark ="";
            this.setState({
                model: newModel,
            });
            if(e.length > 5){
                this.changeSearch(k,false);
            }
        }

        /* if ((k === 'phone') || (k === 'cardNumber' )) {
            if (k === 'phone' && e.length < 11) {
                newModel.cardNumber = '';
            } else if (k === 'cardNumber' && e.length >= 7) {
                newModel.phone = '';
            }
            this.setState({
                model: newModel,
            });
            console.log(newModel)
            this.changeSearch(k,false);
        } */
        this.setState({
            model: newModel,
        });
    }


    //定义根据属性加法函数
    countSum(name, value) {
        let d = 3,//定义小数位的初始长度，默认为整数，即小数位为0
            sum = 0,//定义sum来接收所有数据的和
            //循环所有的参数
            m = Math.pow(10, d);
        if (name === "footAmount") {
            if (value != null && value !== "" && value !== undefined) {
                sum += value * m;
            }
            if (this.state.model.wineAmount != null && this.state.model.wineAmount !== "" && this.state.model.wineAmount !== undefined) {
                sum += this.state.model.wineAmount * m;
            }
        }

        if (name === "wineAmount") {
            if (value != null && value !== "" && value !== undefined) {
                sum += value * m;
            }
            if (this.state.model.footAmount != null && this.state.model.footAmount !== "" && this.state.model.footAmount !== undefined) {
                sum += this.state.model.footAmount * m;
            }
        }
        //返回结果
        return sum / m;
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
        })
        this.search()
    }

    tableChange(value) {
        if (value === "2") {
            if (this.state.selectedRows.length === 1) {
                this.edit();
            } else {
                this.add();
            }
        } else if (value === "1") {
            this.setState({
                tab: value,
            })
            this.cancel();
        } else {
            this.setState({
                tabIndex: value,
            })
            this.searchThree();
        }
        this.clearSelectThree();
        this.resetThree();
    }

    onChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            value: e.target.value,
        });
    };

    searchThree() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "cashier/searchMyList", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModelThree),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModelThree;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
            this.setState({
                searchModelThree: model,
                tablesThree: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    resetThree() {
        this.clearSelectThree();
        this.setState({
            searchModelThree: {
                page: 1,
                pageSize: 10,
                cardNumber: "",
                openCashierTime: null,
                endCashierTime: null,
            },
            tables: [],
        });
    }

    searchChangeThree(k, e) {
        let newModel = this.state.searchModelThree;
        newModel[k] = e;
        this.setState({
            searchModelThree: newModel
        });
    }

    pageChangeThree = (current, pageSize) => {
        let searchModel = this.state.searchModelThree;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModelThree: searchModel,
        });
        this.searchThree();
    }

    handle(record, tab) {
        let saveModel = this.state.model;
        saveModel.cardNumber = record.cardNumber;
        saveModel.phone = record.phone;
        saveModel.footAmount = record.footAmount;
        saveModel.wineAmount = record.wineAmount;
        saveModel.remark = record.remark;
        saveModel.whetherMasterCardId = record.whetherMasterCardId;
        saveModel.countAmount = this.countSum1(saveModel.wineAmount, saveModel.footAmount);
        this.setState({ model: saveModel, tabIndex: tab }, () => {
            this.changeSearch('cardNumber',true);
        });
        fetch(Config.apiBackServer + "cashier/deleteMyList", {
            method: 'POST',
            body: JSON.stringify(record),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.searchNoColsingList();
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        }).catch(err => { });
    }

    reSearch(record){
        console.log(record);
        this.searchCashier();//收银员查询数据 
    }

    render() {
        const { TabPane } = Tabs;
        const columns = [
            {
                title: '收银/挂单日期',
                dataIndex: 'cashierTime',
                className: "tableWidth15",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth15",
            },
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: "tableWidth15",
            },
            {
                title: '返现金额',
                dataIndex: 'footAmountStr',
                className: "tableWidth10 tableRightAlignment",
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmountStr',
                className: "tableWidth10 tableRightAlignment",
            },
            {
                title: '收银方式',
                dataIndex: 'cashierType',
                className: "tableWidth10",
                render: (text) => ContextHandler.getTransactionTypeValue(Common.isNotEmpty(text)?text:"WECHAT")
            },
            {
                title: '备注',
                dataIndex: 'remark',
                className: "tableWidth25",
            },
        ];

        const columns1 = [
            {
                title: '收银日期',
                dataIndex: 'cashierTime',
                className: "tableWidth14",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: "tableWidth10",
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth10",
            },
            {
                title: '返现金额',
                dataIndex: 'footAmountStr',
                className: "tableWidth10 tableRightAlignment",
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmountStr',
                className: "tableWidth10 tableRightAlignment",
            },
            {
                title: '收银方式',
                dataIndex: 'cashierType',
                className: "tableWidth8",
                render: (text) => ContextHandler.getTransactionTypeValue(text)
            },
            {
                title: '备注',
                dataIndex: 'remark',
                className: "tableWidth20",
            },
            {
                title: '状态',
                dataIndex: 'cashierStatus',
                className: "tableWidth8",
                render: (text) => ContextHandler.getMapValue("cashierStatusMap",Common.isNotEmpty(text)?text:"READY_TO_PAY")
            },
            {
                title: '',
                dataIndex: '',
                className: "tableWidth10",
                render: (text, record, index) =>record.cashierStatus==="READY_TO_PAY"?(<Button className="columnBtn" type="primary" onClick={() => { this.reSearch(record) }}>刷新</Button>):""
            },
        ];

        const columnsTwo = [
            {
                title: '挂单日期',
                dataIndex: 'cashierTime',
                className: 'tableWidth35',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '返现金额',
                dataIndex: 'footAmountStr',
                className: 'tableWidth30 tableRightAlignment',
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmountStr',
                className: 'tableWidth30 tableRightAlignment',
            },
            {
                title: '',
                dataIndex: '',
                className: 'tableWidth5',
                render: (text, record, index) => (<Button className="btnStylez" type="primary" onClick={() => { this.handle(record, "2") }}>处理</Button>)
            },
        ];

        const columnsThree = [
            {
                title: '挂单日期',
                dataIndex: 'cashierTime',
                className: "tableWidth15",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth15",
            },

            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: "tableWidth15",
            },
            {
                title: '返现金额',
                dataIndex: 'footAmountStr',
                className: "tableWidth10 tableRightAlignment",

            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmountStr',
                className: "tableWidth10 tableRightAlignment",
            },
            {
                title: '备注',
                dataIndex: 'remark',
                className: "tableWidth25",
            },
            {
                title: '',
                dataIndex: '',
                className: "tableWidth10",
                render: (text, record, index) => (<Button className="" type="primary" onClick={() => { this.handle(record, "2") }}>处理</Button>)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };

        const rowSelectionThree = {
            selectedRowKeys: this.state.selectedRowKeysThree,
            onChange: this.onSelectChangeThree,
            type: 'radio',
        };

        const { TextArea } = Input;
        return (
            <div className="cashier">
                <div className="zhRoot">
                    <div className="content">
                        <Tabs activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">会员卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="会员卡号"
                                            value={this.state.searchModel.cardNumber || ''}
                                            onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">收银/挂单日期：</Col>
                                    <Col span={3} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            value={this.state.searchModel.openCashierTime == null ? null : moment(new Date(this.state.searchModel.openCashierTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("openCashierTime", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                    <Col span={2} className="zhTxt">至</Col>
                                    <Col span={3} className="zhInput">
                                        <DatePicker placeholder="至日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            value={this.state.searchModel.endCashierTime == null ? null : moment(new Date(this.state.searchModel.endCashierTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("endCashierTime", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                </Row>

                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <Button type="primary" onClick={() => this.add()} icon="plus">新增</Button>
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        key="cardNumber"
                                        bordered={true} //边线
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        columns={columns} //列名
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'cardNumber', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                        rowClassName="tableColor"
                                    />
                                </Row>

                            </TabPane>
                            <TabPane tab="收银" key="2">
                                <div className="cashierFrame">
                                    <Row>
                                        <div className="cashierLeft">
                                            <Row className='row'>
                                                <div>
                                                    <div className="cardNumber">操作员卡号：{Config.userInfo.cardNumber || ''}</div>
                                                    <div className="phone">手机号：{Config.userInfo.phone || ''}</div>
                                                </div>
                                            </Row>
                                            <Row className='row'>
                                                <div className="cashierMember">
                                                    <div className="cashierName">收银台</div>
                                                    {/* <div className="underline">{"收银员："+ (Config.userInfo.phone?Config.userInfo.phone:"")}</div> */}
                                                </div>
                                            </Row>
                                            <Row className='row'>
                                                <Col xs = {20} sm = {12} md = {20} lg = {8} className='zhTxt1'>会员手机号<span className='redStar'>*</span></Col>
                                                <Col xs = {20} sm = {20} md = {20} lg = {12} className='zhInput1'>
                                                    <Input maxLength={11} id="phone"
                                                        onClick={() =>
                                                            this.setState({ amountFlag: "phone" })
                                                        }
                                                        placeholder="会员手机号"
                                                        value={this.state.model.phone || ''}
                                                        onChange={(e) => this.modelChange('phone', e.target.value)}
                                                        onKeyDown={(e) => this.handleKeyDown(e)} />
                                                </Col>
                                            </Row>
                                            <Row className='row'>
                                                <Col xs = {20} sm = {20} md = {20} lg = {8} className='zhTxt1'>会员卡号<span className='redStar'>*</span></Col>
                                                <Col xs = {20} sm = {20} md = {20} lg = {12} className='zhInput1'>
                                                    <Input maxLength={10} id="cardNumber"
                                                        onClick={() =>
                                                            this.setState({ amountFlag: "cardNumber" })
                                                        }
                                                        placeholder="会员卡号"
                                                        value={this.state.model.cardNumber || ''}
                                                        onChange={(e) => this.modelChange('cardNumber', e.target.value)}
                                                        onKeyDown={(e) => this.handleKeyDown(e)}
                                                    />
                                                </Col>
                                            </Row>
  
                                            <Row className='row'>
                                                <div className="head" hidden={!(Common.isNotEmpty(this.state.model.headPortrait)|| Common.isNotEmpty(this.state.model.nickName) || Common.isNotEmpty(this.state.model.cardType))}>
                                                    <div className="headPortrait" hidden={Common.isEmpty(this.state.model.phone)  || Common.isEmpty(this.state.model.cardNumber) }>
                                                        <img alt="" src={Common.isEmpty(this.state.model.headPortrait)
                                                            ? Config.headPortraitImgs + "defaultHead.png"
                                                            : (this.state.model.headPortrait.indexOf('http') == -1 && (this.state.model.headPortrait.indexOf('https') == -1)
                                                                ? Config.headPortraitImgs + this.state.model.headPortrait
                                                                : this.state.model.headPortrait
                                                            )
                                                            } 
                                                        />
                                                    </div>
                                                    <div className="right" hidden={Common.isEmpty(this.state.model.phone)  || Common.isEmpty(this.state.model.cardNumber) }>
                                                        <div className="nikeName">{this.state.model.nickName}</div>
                                                        <div className="cardType">{this.state.model.cardType === "GOLDEN_CARD" ? "金卡会员" : "银卡会员"}</div>
                                                    </div>
                                                    {/* <div className="cardType" hidden={Common.isEmpty(this.state.model.phone)  || Common.isEmpty(this.state.model.cardNumber) }>
                                                        <img alt=""  src={Config.icon + (this.state.model.cardType === "GOLDEN_CARD" ? "goldMember.png" : "silverMember.png")}></img>
                                                    </div> */}
                                                </div>
                                                {/* <Col xs = {20} sm = {20} md = {20} lg = {7} className='headPortrait'>
                                                <div className="headPortrait">
                                                    <img alt="" className="img" src={Common.isEmpty(this.state.model.headPortrait)
                                                        ? Config.headPortraitImgs + "defaultHead.png"
                                                        : (this.state.model.headPortrait.indexOf('http') == -1 && (this.state.model.headPortrait.indexOf('https') == -1)
                                                            ? Config.headPortraitImgs + this.state.model.headPortrait
                                                            : this.state.model.headPortrait
                                                        )
                                                        } 
                                                        />
                                                </div>
                                                </Col>
                                                <Col xs = {20} sm = {20} md = {20} lg = {7} className='userName'>
                                                    {this.state.model.nikeName === undefined ? "" : this.state.model.nikeName}
                                                </Col>
                                                <Col xs = {20} sm = {20} md = {20} lg = {7} className='count'>
                                                    <img alt=""  src={Config.icon + (this.state.model.cardType === "GOLDEN_CARD" ? "goldMember.png" : "silverMember.png")}></img>
                                                </Col> */}
                                            </Row>

                                            <Row className='row'>
                                                <Col  xs = {20} sm = {20} md = {20} lg = {8} className='zhTxt1'>返现金额<span className='redStar'>*</span></Col>
                                                <Col xs = {20} sm = {20} md = {20} lg = {12} className='zhInput1'>
                                                    <Input maxLength={7}
                                                        onClick={() =>
                                                            this.setState({ amountFlag: "footAmount" })
                                                        }
                                                        placeholder="返现金额"
                                                        value={this.state.model.footAmount || ''}
                                                        onChange={(e) => this.modelChange('footAmount', e.target.value)} />
                                                </Col>
                                            </Row>

                                            <Row className='row'>
                                                <Col xs = {20} sm = {20} md = {20} lg = {8} className='zhTxt1'>不返现金额</Col>
                                                <Col xs = {20} sm = {20} md = {20} lg = {12} className='zhInput1'>
                                                    <Input maxLength={7}
                                                        onClick={() =>
                                                            this.setState({ amountFlag: "wineAmount" })
                                                        }
                                                        placeholder="不返现金额"
                                                        value={this.state.model.wineAmount || ''}
                                                        onChange={(e) => this.modelChange('wineAmount', e.target.value)} />
                                                </Col>
                                            </Row>

                                            <Row className='row'>
                                                <Col xs = {20} sm = {20} md = {20} lg = {8} className='zhInput1 remark'>
                                                    <TextArea className="zhInputNumber " maxLength={500}
                                                        onClick={() =>
                                                            this.setState({ amountFlag: "remark" })
                                                        }
                                                        autoSize={{ minRows: 5, maxRows: 5 }}
                                                        placeholder="备注"
                                                        value={this.state.model.remark || ''}
                                                        onChange={(e) => this.modelChange('remark', e)} />
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="cashierRight">
                                            <div className="paymentMethod">
                                                <div className="paymentMethodLeft">
                                                    <div className="paymentMethodTitle">
                                                        支付
                                                    </div>
                                                    <div className="paymentMethodCountAmount">
                                                        {this.state.model.countAmount || ''}
                                                    </div>
                                                </div>

                                                <div className="paymentMethodMiddle">
                                                    <div className="paymentMethodreceipts">
                                                        实收
                                                    </div>
                                                    <div className="paymentAmount">
                                                        <Input className="number"
                                                            maxLength={7}
                                                            onClick={() =>
                                                                this.setState({ amountFlag: "paymentAmount" })
                                                            }
                                                            placeholder="实收金额"
                                                            value={this.state.model.paymentAmount || ''}
                                                            onChange={(e) => this.modelChange('paymentAmount', e.target.value)} />
                                                    </div>
                                                </div>

                                                <div className="paymentMethodRight">
                                                    <div className="paymentMethodTitle">
                                                        找零
                                                    </div>
                                                    <div className="paymentMethodCountAmount">
                                                        {this.state.model.giveChangeAmount || ''}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="cashierPaymentButton">
                                                <div className="leftNumber">
                                                    <div className="leftMidule">
                                                        <div className="firstRow">
                                                            <div className="number lineRight lineBottom" onClick={() => { this.changeNumber('7') }} id='7'> 7</div>
                                                            <div className="number lineRight lineBottom" onClick={() => { this.changeNumber('8') }} id='8'> 8</div>
                                                            <div className="number lineBottom" onClick={() => { this.changeNumber('9') }} id='9'> 9</div>
                                                        </div>
                                                        <div className="firstRow">
                                                            <div className="number lineRight lineBottom" onClick={() => { this.changeNumber('4') }} id='4'> 4</div>
                                                            <div className="number lineRight lineBottom" onClick={() => { this.changeNumber('5') }} id='5'> 5</div>
                                                            <div className="number lineBottom" onClick={() => { this.changeNumber('6') }} id='6'> 6</div>
                                                        </div>

                                                        <div className="firstRow">
                                                            <div className="number  lineRight lineBottom" onClick={() => { this.changeNumber('1') }} id='1'> 1</div>
                                                            <div className="number  lineRight lineBottom" onClick={() => { this.changeNumber('2') }} id='2'> 2</div>
                                                            <div className="number lineBottom" onClick={() => { this.changeNumber('3') }} id='3'> 3</div>
                                                        </div>
                                                        <div className="firstRow">
                                                            <div className="number lineRight" onClick={() => { this.changeNumber('0') }} id='0'> 0</div>
                                                            <div className="number lineRight" onClick={() => { this.changeNumber('00') }} id='00'> 00</div>
                                                            <div className="number" onClick={() => { this.changeNumber('.') }} id='.'> .</div>
                                                        </div>
                                                    </div>
                                                    <div className="leftBottom">
                                                        <div className="button bottomCalr" onClick={() => { this.clear() }}> 清除</div>
                                                        <div className="button bottomCalrbul" onClick={() => { this.myList() }}> 挂单</div>
                                                        <div className="button1 bottomCalrshen" onClick={() => { this.settlement() }}> 下单</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cashierRightList">
                                            <div className="cashierMyNoColsing">
                                                <div className="cashierMyNoColsingTitle">我的挂单</div>
                                            </div>
                                            <div className="cashierMyNoColsingList">
                                                <Table
                                                    rowKey="id"
                                                    bordered={true} //边线
                                                    columns={columnsTwo} //列名
                                                    dataSource={this.state.tables2} //数据
                                                    loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                                    pagination={false}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <div >
                                        <Table
                                            rowKey="id"
                                            style={{ width: "99%", margin: "0 0.5% 0 0.5%", paddingBottom: "0.5%", borderRadius: "10px" }}
                                            bordered={true} //边线
                                            columns={columns1} //列名
                                            dataSource={this.state.tables1} //数据
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            pagination={false}
                                        />
                                    </div>

                                </div>
                            </TabPane>
                            <TabPane tab="挂单" key="4">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">会员卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="会员卡号"
                                            value={this.state.searchModelThree.cardNumber || ''}
                                            onChange={(e) => this.searchChangeThree('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">挂单日期：</Col>
                                    <Col span={3} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            value={this.state.searchModelThree.openCashierTime == null ? null : moment(new Date(this.state.searchModelThree.openCashierTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChangeThree("openCashierTime", value)}
                                            format={Config.dateFormat} />
                                    </Col>

                                    <Col span={2} className="zhTxt">至</Col>
                                    <Col span={3} className="zhInput">
                                        <DatePicker placeholder="至日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            value={this.state.searchModelThree.endCashierTime == null ? null : moment(new Date(this.state.searchModelThree.endCashierTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChangeThree("endCashierTime", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                </Row>

                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button type="primary" onClick={() => this.searchThree()} icon="search">搜索</Button>
                                        <Button className="zhButtonRight" onClick={() => this.resetThree()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelectionThree}
                                        key="id"
                                        bordered={true} //边线
                                        columns={columnsThree} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tablesThree} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChangeThree,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChangeThree,    //每页显示多少条数据方法
                                            total: this.state.searchModelThree.total,             //总共多少条数据
                                            current: this.state.searchModelThree.page,            //当前页
                                            pageSize: this.state.searchModelThree.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRowsThree', 'selectedRowKeysThree'), // 点击行
                                            };
                                        }}
                                        rowClassName="tableColor"
                                    />
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}