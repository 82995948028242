import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Select, DatePicker,Input,Spin } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
const { TabPane } = Tabs;

export default class dailyProvSaleReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
                tabIndex: '1',//页面
                provinceList:[],//省
                selectedRowKeys: [],
                selectedRows: [],
                tables:[],//表格数据
                model:{},
                searchModel: {actionTime: null, endTime: null ,province:'',pageSize: 10, page: 1, },
                tabDetails:{},//详情数据
                detailsTable:{},//详情页表格数据
                detailsModel:{pageSize: 10, page: 1},
                isExport : false,
                isLoading: false
        }
    }

    componentDidMount(){
        this.searchProvince();//初适化省份数据
        this.searchTables();//表格数据
    }

    searchTables(){
        let model = this.state.searchModel;
        this.setState({ isLoading: true });
        fetch(Config.apiBackServer + "dailyProvSaleReport/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
                this.setState({
                    searchModel:model,
                    tables: data.object.list,
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));;
    }

    searchProvince(){
        fetch(Config.apiBackServer + "area/getProvince", {
            method: 'POST',
            body:{},
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({
                    provinceList: data.object
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        });
    }

    //属性改变
    searchChange(k, e) {
        let { searchModel, provinceList } = this.state;
        searchModel[k] = e;
        if ('provinceId' === k) {
            if (Common.isNotEmpty(e)) {
                let index = provinceList.findIndex((item) => item.id === e);
                searchModel.provinceName = provinceList[index].name;
            } else {
                searchModel.provinceName = null;
            }
        }
        this.setState({ searchModel });
    }

    reset() {
        this.setState({
            searchModel: { actionTime:null,endTime:null, province:'',  pageSize: 10,page: 1},
        }, () => {
            this.searchTables();
        })
    }


    downloadTableExcel() {
        this.setState({ isExport: true })
        fetch(Config.apiBackServer + "dailyProvSaleReport/downloadTableExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                }
                reader.readAsText(blob);
            } else {
                var filename = "各省业绩日报表.xlsx";
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => { }).finally(() => this.setState({ isExport: false }));
    }

    pageChange = (current, pageSize) =>{
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.searchTables();
    }

    detailsModelpageChange = (current, pageSize) => {
        let detailsModel = this.state.detailsModel;
        detailsModel.pageSize = pageSize;
        detailsModel.page = current;
        this.setState({
            detailsModel: detailsModel,
        });
        this.tableDetails();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            isExport: false,
        })
        this.searchTables()
    }

    tabChange(value){
        if(value==="2"){
            this.tableDetails();
        }else{
            this.cancel();
        }
    }

    tableDetails(){
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
       
        let newSelectedRows = this.state.selectedRows[0];
        newSelectedRows.page= this.state.detailsModel.page;
        newSelectedRows.pageSize= this.state.detailsModel.pageSize;
        
        let detailsModel = this.state.detailsModel;
        fetch(Config.apiBackServer + "provincialDayDetails/oneDayDetails", {
            method: 'POST',
            body: JSON.stringify(newSelectedRows),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                detailsModel.total = data.object.total;
                this.setState({
                    tabIndex:'2',
                    tabDetails: this.state.selectedRows[0],
                    detailsTable :data.object.list,
                    detailsModel:detailsModel
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        });
    }
    
    selectRadioRow(record) {
        let selectedRowKeys = this.state.selectedRowKeys;
        let checkboxState = this.state.selectedRows;
        let i = checkboxState.indexOf(record);
        if (i > -1) {
            selectedRowKeys = [];
            checkboxState = [];
        } else {
            selectedRowKeys = [record.provinceName + record.cashierTime + record.cashierCount];
            checkboxState = [record];
        }
        console.log(selectedRowKeys)
        this.setState({ selectedRowKeys : selectedRowKeys, selectedRows: checkboxState });
    }

    render(){
        const columns= [
            {
                title: '省份',
                className: "tableWidth12",
                dataIndex: 'provinceName',
            },
            {
                title: '消费日期',
                dataIndex: 'cashierTime',
                className: "tableWidth12",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '消费金额',
                dataIndex: 'tradeAmount',
                className: "tableWidth12 tableRightAlignment",
                render:(text) => Common.numberFormat(text, 2)
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmount',
                className: "tableWidth12 tableRightAlignment",
                render:(text) => Common.numberFormat(text, 2)
            },
            {
                title: '不返现金额',
                dataIndex: 'wineAmount',
                className: "tableWidth12 tableRightAlignment",
                render:(text) => Common.numberFormat(text, 2)
            },
            {
                title: '奖励金额',
                dataIndex: 'rebateAmount',
                className: "tableWidth12 tableRightAlignment",
                render:(text) => Common.numberFormat(text, 2)
            },
            {
                title: '店铺到账',
                dataIndex: 'paymentAmount',
                className: "tableWidth12 tableRightAlignment",
                render:(text) => Common.numberFormat(text, 2)
            },
            {
                title: '消费笔数',
                className: "tableWidth12 tableRightAlignment",
                dataIndex: 'cashierCount',
            },
        ]

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };

        return(
            <div className="platformStoresStatistics">
                <div className="zhRoot">
                    <div className="zhTitle">各省业绩日报表</div>
                    <div className="content">
                        <Spin spinning={this.state.isExport} tip="下载中...">
                            <Tabs  activeKey={this.state.tabIndex} onChange={(value) => this.tabChange(value)}>
                                <TabPane tab="列表" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">省份：</Col>
                                        <Col span={3} className="zhInput">
                                            <Select style={{width:"100%"}} placeholder="全部"
                                                value={this.state.searchModel.provinceId||''}
                                                onChange={(e) => this.searchChange('provinceId', e)}
                                            >
                                                <Select.Option key="" value="">全部</Select.Option>
                                                {this.state.provinceList.map((item) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={2} className="zhTxt">消费日期：</Col>
                                        <Col span={7} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="shopRebateStatement"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("startTime", value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                                <DatePicker placeholder="至日期"
                                                className="shopRebateStatement"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endTime", value)}
                                                format={Config.dateFormat} />
                                        </Col>           
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button type="primary" onClick={() => this.searchTables()} icon="search">搜索</Button>
                                            <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button className="zhButtonLeft" type="primary" onClick={() => this.downloadTableExcel()} icon="download">导出</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table 
                                            rowSelection={rowSelection}
                                            rowKey={record => record.provinceName + record.cashierTime + record.cashierCount}
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            loading={{spinning:this.state.isLoading, tip:"加载中..."}}
                                            dataSource={this.state.tables} //数据
                                            pagination={{  //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,                      //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,              //每页显示多少条数据方法
                                                total: this.state.searchModel.total,            //总共多少条数据
                                                current: this.state.searchModel.page,           //当前页
                                                pageSize: this.state.searchModel.pageSize,      //每页显示多少条数据
                                                defaultCurrent: 1,                              //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => this.selectRadioRow(record), // 点击行
                                                };
                                            }}
                                        />
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}