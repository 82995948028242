import React, { Component } from 'react';
import { Spin, Button, Row, Col, Tabs, Table, Input, Select, Modal, DatePicker,Upload,Icon } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import context from '../../public/js/context';
import { ContextHandler } from '../../public/js/contextHandler';
import SelectCardNumberBySettlementP from './../selectCardNumberBySettlementP/selectCardNumberBySettlementP';

import './promotionPerformanceSettlement.css';
import CheckableTag from 'antd/lib/tag/CheckableTag';

const confirm = Modal.confirm;
const { TextArea } = Input;
const { Option } = Select;
var css;
export default class PromotionPerformanceSettlement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondarySelectedRowKeys: [],
            secondarySelectedRows: [],
            model: {},
            tables: [],
            secondaryList: [],
            secondaryFlag: "add",
            searchModel: { pageSize: 10, page: 1, showShop: 'Y' },
            secondaryModel: { pageSize: 10, page: 1, showShop: 'Y' },
            searchShopList: [],
            paymentAmount: [{}],
            shopFlag: false,
            preservationFlag: false,
            openSpecialGoldCard: false,
            paymentAmount: '',
            statu: '',
            goldCardCount: 0,
            silverCardCount: 0,
            isLoading: true,
            saveFlag: true,//重复点击标志
            secondarySave: true,
            isDown: false,
            provinceList: [],//省
            cityList: [],//市
            areaList: [],//区
            isExport: false,
            previewVisibleZFB:false,
            imageFileZFB: [],
            ImageNumZFB: 1,
            previewVisibleWX:false,
            imageFileWX: [],
            ImageNumWX: 1,
            imageFile: [],
            ImageNum: 1,
            previewVisible: false,
        }
        this.init()
    }

    init() {
    }

    componentWillMount = () => {
        this.search();
    }

    search() {
        var model = this.state.searchModel;
        console.log(model);
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "promotionPerformanceSettlement/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            let list = JSON.parse(JSON.stringify(data.object.list.list));
            if ("SUCCESS" === data.msg) {
                model.total = data.object.list.total;
                if(data.object.amountAll != null && data.object.amountAll != undefined){
                    let newModel=data.object.amountAll;
                    newModel.cardNumber="cardNumber";
                    list.push(newModel);
                }
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }


    reset() {
        this.setState({
            searchModel: { page: 1,
                           pageSize: 10,
                           cardNumber: '',
                           phone:'',
                           performanceSettlementStatus:'',
                           openDate:null,
                           endDate:null},
        }, () => {
            this.cancel();
        })
        
    }

    exportReport() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "promotionPerformanceSettlement/promotionPerformanceSettlementReport", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "推广业绩结算" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }
    secondaryOnSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            secondarySelectedRowKeys: selectedRowKeys,
            secondarySelectedRows: selectedRows
        })
    }


    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let module = this.state.selectedRows[0];
        module.pageSize = 10;
        module.page = 1;
        var weChatAddress = module.weChatAddress;
        let file1 = [{ uid: '-1', }]
        if (Common.isNotEmpty(weChatAddress) && weChatAddress.indexOf('http') != -1) {
            file1[0].url = weChatAddress;
            file1[0].name = weChatAddress;
        } else if (Common.isNotEmpty(weChatAddress) && weChatAddress.indexOf('http') == -1) {
            file1[0].url = Config.consumptionRebate + weChatAddress;
            file1[0].name = weChatAddress;
        } else {
            file1 = []
        }
        var alipayAddress = module.alipayAddress;
        let file2 = [{ uid: '-1', }]
        if (Common.isNotEmpty(alipayAddress) && alipayAddress.indexOf('http') != -1) {
            file2[0].url = alipayAddress;
            file2[0].name = alipayAddress;
        } else if (Common.isNotEmpty(alipayAddress) && alipayAddress.indexOf('http') == -1) {
            file2[0].url = Config.consumptionRebate + alipayAddress;
            file2[0].name = alipayAddress;
        } else {
            file2 = []
        }
        var settlementVoucher = module.settlementVoucher;
        let file3 = [{ uid: '-1', }]
        if (Common.isNotEmpty(settlementVoucher) && settlementVoucher.indexOf('http') != -1) {
            file3[0].url = settlementVoucher;
            file3[0].name = settlementVoucher;
        } else if (Common.isNotEmpty(settlementVoucher) && settlementVoucher.indexOf('http') == -1) {
            file3[0].url = Config.consumptionRebate + settlementVoucher;
            file3[0].name = settlementVoucher;
        } else {
            file3 = []
        }

        fetch(Config.apiBackServer + "promotionPerformanceSettlement/searchEdit", {
            method: 'POST',
            body: JSON.stringify(module),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            let list = JSON.parse(JSON.stringify(data.object.list.list));
            if ("SUCCESS" === data.msg) {
                let newModel=data.object.amountAll;
                if(newModel != null && newModel !=undefined){
                    newModel.id="id";
                    module.countAmount  = newModel.consumptionAmount;
                    module.foodAmount = newModel.foodAmount;
                    list.push(newModel);
                }

                module.list = list;
                this.setState({
                    tabIndex: "2",
                    model: module,
                    flag: "edit",
                    secondaryList:list,
                    imageFileWX: file1,
                    imageFileZFB: file2,
                    imageFile:file3,
                })
            }
        }).catch(err => { });
    }

    searchSecondary(model){
        let module = model;
        var weChatAddress = module.weChatAddress;
        let file1 = [{ uid: '-1', }]
        if (Common.isNotEmpty(weChatAddress) && weChatAddress.indexOf('http') != -1) {
            file1[0].url = weChatAddress;
            file1[0].name = weChatAddress;
        } else if (Common.isNotEmpty(weChatAddress) && weChatAddress.indexOf('http') == -1) {
            file1[0].url = Config.consumptionRebate + weChatAddress;
            file1[0].name = weChatAddress;
        } else {
            file1 = []
        }
        var alipayAddress = module.alipayAddress;
        let file2 = [{ uid: '-1', }]
        if (Common.isNotEmpty(alipayAddress) && alipayAddress.indexOf('http') != -1) {
            file2[0].url = alipayAddress;
            file2[0].name = alipayAddress;
        } else if (Common.isNotEmpty(alipayAddress) && alipayAddress.indexOf('http') == -1) {
            file2[0].url = Config.consumptionRebate + alipayAddress;
            file2[0].name = alipayAddress;
        } else {
            file2 = []
        }
        module.pageSize = 10;
        module.page = 1;
        fetch(Config.apiBackServer + "promotionPerformanceSettlement/searchSecondary", {
            method: 'POST',
            body: JSON.stringify(module),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log(data);
            let list = JSON.parse(JSON.stringify(data.object.list.list));
            if ("SUCCESS" === data.msg) {
                module.settlementSum = list.length;
                let newModel=data.object.amountAll;
                if(newModel != null && newModel !=undefined){
                    newModel.id="id";
                    module.countAmount  = newModel.consumptionAmount;
                    module.foodAmount = newModel.foodAmount;
                    module.amount = newModel.promotionRebate;
                    list.push(newModel);
                }

                module.list = list;
                this.setState({
                    tabIndex: "2",
                    model: module,
                    secondaryList:list,
                    imageFileWX: file1,
                    imageFileZFB: file2,
                })
            }
        }).catch(err => { });
    }

    accAdd(arg1,arg2){
        var r1,r2,m;
        try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
        try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
        m=Math.pow(10,Math.max(r1,r2))
        return (arg1*m+arg2*m)/m
      }

    Mul(a, b) {
        var str1 = a.toString(),str2 = b.toString(),m=0;
        try{m += str1.split('.')[1].length;}catch(e){}
        try{m += str2.split('.')[1].length;}catch(e){}
        return Number(str1.replace(".", "")) * Number(str2.replace(".", "")) / Math.pow(10, m)
    }

    goldenCardExport() {
        this.setState({ isDown: true })
        fetch(Config.apiBackServer + "openMembership/goldenCardExport", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob().then(blob => {
            if (blob.type == "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" !== data.msg) {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "企业金卡" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + ".xlsx"
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        })).catch(err => ({ err })).finally(() => {
            this.setState({ isDown: false })
        });
    }



    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize-1;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }
    secondaryPageChange = (current, pageSize) => {
        let secondaryModel = this.state.secondaryModel;
        secondaryModel.pageSize = pageSize-1;
        secondaryModel.page = current;
        this.setState({
            secondaryModel: secondaryModel,
        });
        this.edit("secondaryPageChange");
    }

    modelChange(k, e) {//需求待定
        let newModel = this.state.model;
        newModel[k] = e;
        if (k === "promotionEndDate") {
            this.searchSecondary(newModel);
        }
      
        this.setState({
            model: newModel,
        });
    }
    secondaryChange(k, e) {//需求待定
        let newModel = this.state.secondaryModel;
        newModel[k] = e;
        this.setState({
            secondaryModel: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    handlePreviewZFB = (file) => {
        this.setState({
            previewImageZFB: file.url || file.thumbUrl,
            previewVisibleZFB: true,
        });
    }

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    handlePreviewWX = (file) => {
        this.setState({
            previewImageWX: file.url || file.thumbUrl,
            previewVisibleWX: true,
        });
    }

    handleCancel2 (){
        this.setState({ previewVisibleZFB: false })
      } 
    handleCancel3 (){
    this.setState({ previewVisibleWX: false })
    } 
    handleCancel (){
        this.setState({ previewVisible: false })
        } 

      handleChangeZFB(file) {
        this.setState({
            imageFileZFB: file.fileList
        })
    }

    handleChangeWX(file) {
        this.setState({
            imageFileWX: file.fileList
        })
    }

    handleChange(file) {
        this.setState({
            imageFile: file.fileList
        })
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondaryList: [],
            secondarySelectedRows: [],
            secondarySelectedRowKeys: [],
            secondaryFlag: "add"
        })
        this.search()
    }

    tableChange(value, flag) {
        if (flag === "secondary") {
            if (value === "2") {
                if (this.state.secondarySelectedRows.length == 1) {
                    this.secondaryEdit();
                } else {
                    this.secondaryAdd();
                }
            } else {
                this.setState({
                    secondaryTabIndex: value,
                })
                this.secondaryCancel();
            }
        } else {
            if (value === "2") {
                if (this.state.selectedRows.length == 1) {
                    this.edit();
                }
            } else {
                this.setState({
                    tab: value,
                })
                this.cancel();
            }
        }

    }

    add() {
        this.setState({
            tabIndex: "2",
            flag: "add",
            imageFileWX:'',
            imageFile:'',
            imageFileZFB:'',
            model:{
                promotionEndDate: new Date(new Date().getTime()),//前一天 - 24*60*60*1000
                settlementDate: new Date(),
                performanceSettlementStatus:'N',
            },
        })
    }

    selectCardNumberPopBySettlementP = (ref) => {
        this.selectCardNumberPopBySettlementP = ref;
    }

    selectCardNumberBySettlmentP() {
        this.selectCardNumberPopBySettlementP.open()
    }

    closePopup() {
        let cardNumbermodel = this.selectCardNumberPopBySettlementP.state.selectedRows[0]
        let model = this.state.model
        model.cardNumber = cardNumbermodel.cardNumber;
        model.phone = cardNumbermodel.phone;
        model.userId = cardNumbermodel.userId;
        model.memberConsumptionCommission = cardNumbermodel.memberConsumptionCommission;
        model.settlementDate =new Date();
        model.alipayAddress = cardNumbermodel.alipayAddress;
        model.weChatAddress = cardNumbermodel.weChatAddress;
        console.log(model);
        this.setState({
            model: model
        }, () => {
            this.searchSecondary(model);
        })
    }


    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const uploadButtonZFB = (
            <div>
                <Icon type="plus" />
                <div>支付宝收款码</div>
            </div>
        );
        const uploadButtonWX = (
            <div>
                <Icon type="plus" />
                <div>微信收款码</div>
            </div>
        );
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div>上传结算凭证</div>
            </div>
        );

        const columns = [
            {
                title: '推广会员卡号',
                dataIndex: 'cardNumber',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">总计</span>,
                            props: {
                                colSpan: 4,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '推广会员手机号',
                dataIndex: 'phone',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '推广卡类型',
                dataIndex: 'cardNumberType',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return (Common.isNotEmpty(item.sysRoleCode) && item.sysRoleCode.indexOf("HEAD_RESPONSIBLE") > -1 ? "总部卡" :
                        (Common.isNotEmpty(item.sysRoleCode) && item.sysRoleCode.indexOf("STORE_RESPONSIBLE") > -1 ? "店铺卡" :
                            (text === "Y" ? "金卡" : (text === "N" ? "银卡" : ""))
                        ))
                    }
                }
            },
            {
                title: '结算返利截止日期',
                dataIndex: 'promotionEndDate',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>
                    }
                }
            },
            {
                title: '消费金额',
                dataIndex: 'consumptionAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat(text, 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat(text, 2) 
                    }
                }
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat(text, 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat(text, 2) 
                    }
                }
            },
            {
                title: '消费返利金额',
                dataIndex: 'amount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat(text, 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat(text, 2) 
                    }
                }
            },
            {
                title: '结算数量',
                dataIndex: 'settlementSum',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{text}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '结算日期',
                dataIndex: 'settlementDate',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>
                    }
                }

            },
            {
                title: '结算状态',
                dataIndex: 'performanceSettlementStatus',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return text === 'Y' ? '已结算' : '未结算'
                    }
                }
            }

        ];    
        const secondaryColumns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: <span className="weight">总计</span>,
                            props: {
                                colSpan: 3,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '消费日期',
                dataIndex: 'cashierTime',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text == null ? null : moment(text).format(Config.dateFormat)
                    }
                }
            },
            {
                title: '消费金额',
                dataIndex: 'consumptionAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat(text, 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat(text, 2)
                    }
                }
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat(text, 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat(text, 2)
                    }
                }
            },
            {
                title: '推广返利金额',
                dataIndex: 'promotionRebate',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat(text, 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat(text, 2)
                    }
                }
            },
            {
                title: '推广业绩返利比例',
                dataIndex: 'memberConsumptionCommission',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '结算状态',
                dataIndex: 'settlementStatus',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return  text=== 'Y' ? '已结算' : '未结算'
                    }
                }
            },
            {
                title: '消费店铺',
                dataIndex: 'shopName',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
           
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        const secondaryRowSelection = {
            selectedRowKeys: this.state.secondarySelectedRowKeys,
            onChange: this.secondaryOnSelectChange,
            type: 'radio',
        };
        return (
            <div className="promotionPerformanceSettlement">
                <div className="zhRoot">
                    <Spin spinning={this.state.isExport} tip="下载中...">
                    <div className="zhTitle">推广业绩结算</div>
                    <div className="content">
                        <SelectCardNumberBySettlementP onRef={this.selectCardNumberPopBySettlementP} closePopup={() => this.closePopup()} />
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Spin spinning={this.state.isDown} tip="下载中...">
                                <Row className="row">
                                        <Col span={3} className="zhTxt">推广会员卡号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="推广会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={3} className="zhTxt">推广会员手机号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="推广会员手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>
                                        <Col span={3} className="zhTxt">结算状态：</Col>
                                        <Col span={4} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.searchModel.performanceSettlementStatus || ''}
                                                onChange={(e) => this.searchChange('performanceSettlementStatus', e)} >
                                                <Option value={""}>全部</Option>
                                                {context.settlementStatusMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className="row">
                                      
                                        <Col span={3} className="zhTxt">结算返利截止日期：</Col>
                                        <Col span={5} className="zhInput">
                                            <DatePicker placeholder="开始日期"
                                                className="datePicker1"
                                                allowClear={false}
                                                value={this.state.searchModel.openDate == null ? null : moment(new Date(this.state.searchModel.openDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("openDate", value)}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                format={Config.dateFormat} />
                                            &nbsp;至&nbsp;
                                            <DatePicker placeholder="结束日期"
                                                className="datePicker1"
                                                allowClear={false}
                                                value={this.state.searchModel.endDate == null ? null : moment(new Date(this.state.searchModel.endDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endDate", value)}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    

                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.add()} icon="plus">结算</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.edit()} icon="edit">查看</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" type="primary" onClick={() => this.exportReport()} icon="download">导出excel</Button>
                                        <Button className="zhButtonLeft" type="primary" onClick={() => this.delete()} icon="delete">删除</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey="id"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables} //数据
                                            pagination={{  //分页
                                                ageSizeOptions: ['11', '21', '31', '41'],
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total + (
                                                    this.state.searchModel.total % this.state.searchModel.pageSize === 0 ?
                                                        parseInt(this.state.searchModel.total / this.state.searchModel.pageSize) :
                                                        parseInt(this.state.searchModel.total / this.state.searchModel.pageSize) + 1), 
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize+1,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                            rowClassName="tableColor"
                                        />
                                    </Row>
                                </Spin>
                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Modal title="放大图" visible={this.state.previewVisibleZFB} footer={null} onCancel={()=>this.handleCancel2()}>
                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImageZFB} />
                                </Modal>
                                <Modal title="放大图" visible={this.state.previewVisibleWX} footer={null} onCancel={()=>this.handleCancel3()}>
                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImageWX} />
                                </Modal>
                                    <Row>
                                        <Col span={4} className='zhTxt'>推广会员卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="推广会员卡号"
                                                value={this.state.model.cardNumber || ''}
                                                onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col hidden ={this.state.flag === 'edit'} span={2} style={{ marginTop: "5px" }} >
                                            <Button  type="primary" onClick={() => this.selectCardNumberBySettlmentP()} >选择会员</Button>
                                        </Col>
                                        
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt' >推广会员手机号：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="推广会员手机号"
                                                disabled
                                                value={this.state.model.phone || ''}
                                                onChange={(e) => this.modelChange('phone', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>结算日期：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="结算日期"
                                                className="parts-numberInput"
                                                disabled
                                                allowClear={false}
                                                value={this.state.model.settlementDate}
                                                value={this.state.model.settlementDate == null ? null : moment(new Date(this.state.model.settlementDate), Config.dateFormat)}
                                                onChange={(value) => this.modelChange("settlementDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>

                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>结算返利截止日期：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="结算截止日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                disabled={this.state.flag === 'edit'}
                                                value={this.state.model.promotionEndDate == null ? null : moment(new Date(this.state.model.promotionEndDate), Config.dateFormat)}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                onChange={(value) => this.modelChange("promotionEndDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={4} className="zhTxt">结算状态：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.model.performanceSettlementStatus || ''}
                                                disabled
                                                onChange={(e) => this.modelChange('performanceSettlementStatus', e)} >
                                                {context.settlementStatusMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt' >推广返利金额：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="推广返利金额"
                                                disabled
                                                value={Common.numberFormat(this.state.model.amount, 2)  || ''}
                                                onChange={(e) => this.modelChange('amount', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt' >结算数量：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="结算数量"
                                                disabled
                                                value={this.state.model.settlementSum || ''}
                                                onChange={(e) => this.modelChange('settlementSum', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>消费金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="消费金额"
                                                value={Common.numberFormat(this.state.model.countAmount, 2) || ''}
                                                onChange={(e) => this.modelChange('countAmount', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>返现金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="返现金额"
                                                value={Common.numberFormat(this.state.model.foodAmount, 2) || ''}
                                                onChange={(e) => this.modelChange('foodAmount', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>支付宝收款码：</Col>
                                        <Col span={6} className='imgUpload zhInput'>
                                            <Upload
                                                key={"1"}
                                                disabled
                                                accept=".png, .jpg, .jpeg"
                                                action={Config.apiBackServer + "test/test"}
                                                listType="picture-card"
                                                fileList={this.state.imageFileZFB}
                                                onPreview={this.handlePreviewZFB}
                                                >
                                                {this.state.imageFileZFB.length >= this.state.ImageNumZFB ? null : uploadButtonZFB}
                                            </Upload>
                                        </Col>
                                        <Col span={4} className='zhTxt'>微信收款码：</Col>
                                        <Col span={6} className='imgUpload zhInput'>
                                            <Upload
                                                key={"1"}
                                                disabled
                                                accept=".png, .jpg, .jpeg"
                                                action={Config.apiBackServer + "test/test"}
                                                listType="picture-card"
                                                fileList={this.state.imageFileWX}
                                                onPreview={this.handlePreviewWX}
                                               >
                                                {this.state.imageFileWX.length >= this.state.ImageNumWX ? null : uploadButtonWX}
                                            </Upload>
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                            <Col span={4} className='zhTxt'>结算凭证<span className='redStar'>*</span>：</Col>
                                            <Col span={6} className='imgUpload zhInput'>
                                                <Upload
                                                    key={"1"}
                                                    disabled={ this.state.model.performanceSettlementStatus === 'Y' }
                                                    accept=".png, .jpg, .jpeg"
                                                   
                                                    action={Config.apiBackServer + "test/test"}
                                                    listType="picture-card"
                                                    fileList={this.state.imageFile}
                                                    onPreview={this.handlePreview}
                                                    onChange={(file) => this.handleChange(file)}>
                                                    {this.state.imageFile.length >= this.state.ImageNum ? null : uploadButton}
                                                </Upload>
                                            </Col>
                                            <Col span={4} className='zhTxt'>备注：</Col>
                                            <Col span={6} className='zhInput'>
                                                <TextArea
                                                    value={this.state.model.remark || ""}
                                                    disabled={ this.state.model.performanceSettlementStatus === 'Y' }
                                                    onChange={(e) => this.modelChange('remark', e.target.value)}
                                                    placeholder="备注"
                                                    maxLength={100}
                                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                                />
                                            </Col>
                                        </Row>
                                    

                                    
                                    <Row className='zhRow'>
                                            <Row className='zhRow'></Row>
                                            <Row className='row'>
                                                <Table 
                                                    rowKey="id"
                                                    bordered={true} //边线
                                                    loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                                    columns={secondaryColumns} //列名
                                                    dataSource={this.state.secondaryList} //数据
                                                    pagination={{  //分页
                                                        pageSizeOptions: ['11', '21', '31', '41'],
                                                        showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                        onChange: this.secondaryPageChange,            //上页下页或者跳页方法
                                                        onShowSizeChange: this.secondaryPageChange,    //每页显示多少条数据方法
                                                        total: this.state.secondaryModel.total + (
                                                            this.state.secondaryModel.total % this.state.secondaryModel.pageSize === 0 ?
                                                                parseInt(this.state.secondaryModel.total / this.state.secondaryModel.pageSize) :
                                                                parseInt(this.state.secondaryModel.total / this.state.secondaryModel.pageSize) + 1), 
                                                        current: this.state.secondaryModel.page+1,         //当前页
                                                        pageSize: this.state.secondaryModel.pageSize,       //每页显示多少条数据
                                                        defaultCurrent: 1,                               //默认当前页为1
                                                    }}

                                                   
                                                    rowClassName="tableColor"
                                                />
                                            </Row>
                                    </Row>
                                    <Row className='zhRow zhRowButtonRight'>
                                        <Button type="primary" icon="check-circle" hidden={this.state.flag === 'edit' && this.state.model.performanceSettlementStatus === 'Y'} onClick={() => { this.saveSecondary() }}>结算</Button>
                                        <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancelSecondary() }}>取消</Button>
                                    </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                    </Spin>
                </div>
            </div >
        )
    }




    cancelSecondary() {
        var model = this.state.searchModel;
        model.settlementStatus = '';
        this.setState({
            tabIndex: "1",
            searchModel:model,
        })
        this.search();
    }

    check(model){
        let msg = "";
        if(Common.isEmpty(model.userId)){
            msg += "请先选择用户;"
        }
        if(Common.isEmpty(model.amount) || model.amount === 0){
            msg += "推广返利金额必须大于零;"
        }
        if(Common.isEmpty(model.settlementSum)){
            msg += "结算数量不能为空;"
        }
        if(Common.isEmpty(model.promotionEndDate)){
            msg += "结算返利截止日期不能为空;"
        }else if( Common.dateFormat(model.promotionEndDate , "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")){
            msg += "结算返利截止日期不能超过当前时间;"
        }
        if(Common.isEmpty(model.alipayAddress) && Common.isEmpty(model.weChatAddress)){
            msg += "请先上传支付宝收款码或者微信收款码;"
        }
        if(Common.isEmpty(model.settlementVoucher)){
            msg += "请先上传结算凭证;"
        }
        return msg
    }

    saveSecondary() {
        let model = this.state.model
        this.obtainImgData();
        var msg = this.check(model);
        let url = "";
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        if (this.state.flag === "add") {
            url = "promotionPerformanceSettlement/insert";
        } else if (this.state.flag === "edit") {
            url = "promotionPerformanceSettlement/update"
        }
        console.log(model);
        // return;

        fetch(Config.apiBackServer + url,{
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel();
                Message.openMsgSuccess("成功", "保存成功")
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode);
            }
            this.setState({ secondarySave: false, })
        }).catch(err => { });
    }


    obtainImgData() {
        let model = this.state.model;
        let imageFile = this.state.imageFile;
        if (imageFile.length !== 0) {
            for (let i in imageFile) {
                if (Common.isEmpty(imageFile[i].url)) {
                    model.settlementVoucher = imageFile[i].thumbUrl;
                } else {
                    model.settlementVoucher = imageFile[i].name;
                }
            }
        } else {
            model.settlementVoucher = null;
        }
   
        this.setState({
            model: model
        })
    }

    delete() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        let model = this.state.selectedRows[0]
        if("Y" === model.performanceSettlementStatus){
            Message.openMsgError("错误", "只能删除结算状态为未结算的数据");
            return;
        }
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "promotionPerformanceSettlement/delete", {
                    method: 'POST',
                    body: JSON.stringify(model),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.search()
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }

}