import React, { Component } from 'react';
import { DatePicker, Button, Row, Col, Tabs, Table, Select, Modal, Icon, Input } from 'antd';

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import SelectShop from './../selectShop/selectShop';
import { Common } from '../../public/js/common';
import moment from 'moment';//日期格式化导包
import './shopPerformanceStatistics.css';
import { Bar, } from "react-chartjs-2";
import { Chart } from "chart.js";

const confirm = Modal.confirm;
export default class ShopPerformanceStatistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            tableHeadrList: [],
            searchShopList: [],
            isLoading: false,
            searchModel: {shopId: "", pageSize: 10, page: 1, startTime: new Date().setMonth(new Date().getMonth() - 1), endTime: new Date(),},

            chartData: [],
            chartList: [],
            model: { shopId: "", pageSize: 10, page: 1, startTime: new Date().setMonth(new Date().getMonth() - 1), endTime: new Date(), },
            foodAmountTotal: 0,
            wineAmountTotal: 0,
            rebateAmountTotal: 0,
        }
    }

    componentDidMount() {
        this.searchShop();

    }

    chartSearch() {
        let model = this.state.model;
        let msg = this.check(this.state.model)
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "cashier/cashierChartSearch", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                let list = data.object.list;
                let xAxisContentList = [];
                let rebateAmountList = [];
                let foodAmountList = [];
                let wineAmountList = [];
                let foodAmountTotal = 0;
                let wineAmountTotal = 0;
                let rebateAmountTotal = 0;
                list.forEach(element => {
                    xAxisContentList.push(element.cashierTime != null ? moment(element.cashierTime).format("YYYY-MM-DD") : null)
                    foodAmountList.push(element.footAmount || 0)
                    wineAmountList.push(element.wineAmount || 0)
                    rebateAmountList.push(element.rebateAmount || 0)
                    foodAmountTotal += parseFloat(element.footAmount || 0)
                    wineAmountTotal += parseFloat(element.wineAmount || 0)
                    rebateAmountTotal += parseFloat(element.rebateAmount || 0)
                });

                let datesetsItem = {
                    label: "业绩奖励",
                    data: rebateAmountList,
                    backgroundColor: "#37a2da",
                    borderColor: "#37a2da",
                    borderWidth: 1,
                    hoverBackgroundColor: "#37a2da",
                    hoverBorderColor: "#37a2da",
                    barThickness: "flex",
                    fill: false
                }
                let foodAmountDateItem = {
                    label: "返现金额",
                    data: foodAmountList,
                    backgroundColor: "#ee3f4d",
                    borderColor: "#ee3f4d",
                    borderWidth: 1,
                    hoverBackgroundColor: "#ee3f4d",
                    hoverBorderColor: "#ee3f4d",
                    barThickness: "flex",
                    fill: false
                }
                let wineAmountDateItem = {
                    label: "不返现金额",
                    data: wineAmountList,
                    backgroundColor: "#FF9933",
                    borderColor: "#FF9933",
                    borderWidth: 1,
                    hoverBackgroundColor: "#FF9933",
                    hoverBorderColor: "#FF9933",
                    barThickness: "flex",
                    fill: false
                }
                let  chartData = {
                    labels: xAxisContentList,
                    datasets: [foodAmountDateItem,wineAmountDateItem,datesetsItem]
                }
                this.setState({
                    chartData: chartData,
                    chartList: data.object,
                    foodAmountTotal: foodAmountTotal,
                    wineAmountTotal: wineAmountTotal,
                    rebateAmountTotal: rebateAmountTotal,
                })
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchShop() {
        fetch(Config.apiBackServer + "openMembership/searchShop", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({
                    searchShopList: data.object.list
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode);
            }
        }).catch(err => { });
    };

    search() {
        let msg = this.check(this.state.searchModel)
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        fetch(Config.apiBackServer + "cashier/statisticalSearch", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            let list = [];
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
                if (data.object.list == null || data.object.list.length < 1) {
                    Message.openMsg("info", "提示", "暂无数据")
                }
                list = data.object.list
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: list
            }, () => this.forceUpdate())

        });
    }

    check(model) {
        let msg = ""
        // let model = this.state.searchModel
        if (!model.shopId) {
            msg += "店铺不能为空;"
        }
        if (!model.startTime) {
            msg += "开始时间不能为空;"
        }
        if (!model.endTime) {
            msg += "结束时间不能为空;"
        }
        return msg
    }

    searchTableHeadr() {
        this.setState({
            tables: [],
            tableHeadrList: [],
            isLoading: true
        })
        fetch(Config.apiBackServer + "user/cashierSearch", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({
                    tableHeadrList: data.object.list
                }, () => {
                    if(data.object.list.length > 0 && data.object.list !== null) {
                        this.search();
                    } else {
                        Message.openMsg("info", "提示", "暂无数据")
                    }
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    reset() {
        this.setState({
            tables: [],
            tableHeadrList: [],
            foodAmountTotal: 0,
            wineAmountTotal: 0,
            rebateAmountTotal: 0,
            searchModel: {shopId: "", pageSize: 10, page: 1, startTime: new Date().setMonth(new Date().getMonth() - 1), endTime: new Date(),},
            chartData: {
                labels: [],
                datasets: []
            },
            chartList: [],
            model: { shopId: "", pageSize: 10, page: 1, startTime: new Date().setMonth(new Date().getMonth() - 1), endTime: new Date(), },
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    searchChartChange(k, e) {
        let {model} = this.state;
        if (k === "startTime") {
            model.endTime = null;
        } else if('shopId' === k) {
            if (Common.isNotEmpty(e)) {
                let index = this.state.searchShopList.findIndex((item) => item.shopId === e);
                model.shopName = this.state.searchShopList[index].shopName;
            } else {
                model.shopName = null;
            }
        }
        model[k] = e;
        this.setState({ model });
    }

    handleEndDisabledDate(current) {
        let {startTime} = this.state.model
        if (!current || !startTime) {
            return true;
        }
        return current > moment(startTime).add(1, 'month') || current < moment(startTime);
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        if ('shopId' === k) {
            if (Common.isNotEmpty(e)) {
                let index = this.state.searchShopList.findIndex((item) => item.shopId === e);
                newModel.shopName = this.state.searchShopList[index].shopName;
            } else {
                newModel.shopName = null;
            }
        }
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    tableChange(value) {
        this.setState({
            tabIndex: value,
        }, () => {
            this.reset();
        })
    }

    format(num) {
        return (num.toFixed(2) + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
    }

    selectShopPopup = (ref) => {
        this.selectShopPopup = ref;
    }

    closePopup() {
        let shopModel = this.selectShopPopup.state.selectedRows[0]
        let model = this.state.searchModel
        model.shopId = shopModel.shopId
        model.shopName = shopModel.shopName
        this.setState({
            searchModel: model,
            model: model
        })
    }

    openPopup() {
        this.selectShopPopup.open()
    }

    render() {
        const { TabPane } = Tabs;

          const columns = [{
            title: '日期',
            dataIndex: 'day',
            width: "6vw",
            render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
        }];
        this.state.tableHeadrList.map((item) => {
            columns.push({
                title: item.cardNumber,
                dataIndex: item.userId,
                key: item.userId,
                children: [
                    {
                        title: '总计',
                        key: item.userId + "1",
                        className: "tableRightAlignment",
                        render: (text, row, index) => {
                            return row[item.userId] ? this.format(JSON.parse(JSON.stringify(row[item.userId])).tradeAmount || 0): 0
                        }
                    },
                    {
                        title: '返现金额',
                        key: item.userId + "2",
                        className: "tableRightAlignment",
                        render: (text, row, index) => {
                            return row[item.userId] ?  this.format(JSON.parse(JSON.stringify(row[item.userId])).footAmount || 0 ): 0
                        }
                    },
                    {
                        title: '不返现金额',
                        key: item.userId + "3",
                        className: "tableRightAlignment",
                        render: (text, row, index) => {
                            return row[item.userId] ? this.format(JSON.parse(JSON.stringify(row[item.userId])).wineAmount || 0) : 0
                        }
                    },
                    {
                        title: '奖励金额',
                        key: item.userId + "4",
                        className: "tableRightAlignment",
                        render: (text, row, index) => {
                           return row[item.userId] ? this.format(JSON.parse(JSON.stringify(row[item.userId])).rebateAmount || 0) : 0
                        }
                    },
                ]

            })

        })

        return (
            <div className="shopPerformanceStatistics" >
                <div className="zhRoot">
                    <div className="zhTitle">收银员业绩统计</div>
                    <div className="content">
                        <SelectShop onRef={this.selectShopPopup} closePopup={() => this.closePopup()} />
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="统计" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">店铺名称<span className='redStar'>*</span>：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input
                                            placeholder="开卡店铺名称"
                                            disabled
                                            value={this.state.model.shopName || ''} />
                                    </Col>
                                    <Col span={2} style={{ marginTop: "5px" }} >
                                        <Button type="primary" onClick={() => this.openPopup()} >选择店铺</Button>
                                    </Col>
                                    <Col span={2} className="zhTxt">收银日期<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            allowClear={true}
                                            className="userRoyaltiesDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.model.startTime == null ? null : moment(new Date(this.state.model.startTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChartChange("startTime", value)}
                                            format={Config.dateFormat}/>
                                        &nbsp;&nbsp;至&nbsp;&nbsp;
                                        <DatePicker placeholder="至日期"
                                            allowClear={true}
                                            className="userRoyaltiesDatePicker"
                                            disabledDate={(e) => this.handleEndDisabledDate(e)}
                                            value={this.state.model.endTime == null ? null : moment(new Date(this.state.model.endTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChartChange("endTime", value)}
                                            format={Config.dateFormat}/>
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button type="primary" icon="search" onClick = {() => this.chartSearch()}>搜索</Button>
                                        <Button className="zhButtonRight" icon="redo" onClick = {() => this.reset()}>重置</Button>
                                    </Col>
                                </Row>
                                <Row style={{ height: "500px" }}>
                                <Bar
                                    width={1200}
                                    data={this.state.chartData}
                                    options={{
                                        title: {
                                            display: true,
                                            text: "收银业绩统计",
                                            position: "top",
                                        },
                                        tooltips: {
                                            enabled: true,
                                            intersect: false
                                        },
                                        maintainAspectRatio: false,
                                        responsive: true,
                                        scales: {
                                            xAxes: [{

                                            }],
                                            yAxes: [{
                                                ticks: {
                                                    beginAtZero: true,
                                                    callback: function (value) { if (value % 1 === 0) { return value } }
                                                }
                                            }]
                                        },
                                        legend: {
                                            position: "bottom",
                                            align: "center",
                                            labels: {
                                                boxWidth: 20
                                            }
                                        },
                                        hover: {
                                            animationDuration: 0  // 防止鼠标移上去，数字闪烁
                                        },
                                            animation: {
                                                onComplete: function() {
                                                    var chartInstance = this.chart,
                                                    ctx = chartInstance.ctx;
                                                    // 以下属于canvas的属性（font、fillStyle、textAlign...）
                                                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                                                    ctx.fillStyle = "black";
                                                    ctx.textAlign = 'center';
                                                    ctx.textBaseline = 'bottom';

                                                    this.data.datasets.forEach(function (dataset, i) {
                                                        var meta = chartInstance.controller.getDatasetMeta(i);
                                                        meta.data.forEach(function (bar, index) {
                                                            var data = dataset.data[index];
                                                            ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                                        });
                                                    });
                                                }
                                            }
                                        }}
                                    />
                                </Row>
                                <Row><span>返现总金额:</span><span style={{fontSize:"16px", fontWeight:"600",marginLeft:"12px"}}>{Common.numberFormat(this.state.foodAmountTotal || 0, 2)}</span></Row>
                                <Row><span>不返现总金额:</span><span style={{fontSize:"16px", fontWeight:"600",marginLeft:"12px"}}>{Common.numberFormat(this.state.wineAmountTotal || 0, 2)}</span></Row>
                                <Row><span>业绩总奖励:</span><span style={{fontSize:"16px", fontWeight:"600",marginLeft:"12px"}}>{Common.numberFormat(this.state.rebateAmountTotal || 0, 2)}</span></Row>
                            </TabPane>
                            <TabPane tab="列表" key="2">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">店铺名称<span className='redStar'>*</span>：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input
                                            placeholder="开卡店铺名称"
                                            disabled
                                            value={this.state.searchModel.shopName || ''} />
                                    </Col>
                                    <Col span={2} style={{ marginTop: "5px" }} >
                                        <Button type="primary" onClick={() => this.openPopup()} >选择店铺</Button>
                                    </Col>
                                    <Col span={2} className="zhTxt">开始时间<span className='redStar'>*</span>：</Col>
                                    <Col span={3} className="zhInput">
                                        <DatePicker placeholder="开始时间"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("startTime", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                    <Col span={2} className="zhTxt">结束时间<span className='redStar'>*</span>：</Col>
                                    <Col span={3} className="zhInput">
                                        <DatePicker placeholder="结束时间"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("endTime", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button type="primary" onClick={() => this.searchTableHeadr()} icon="search">搜索</Button>
                                        <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row className='zhRow'>
                                    <Table
                                        rowKey="cashierTime"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        dataSource={this.state.tables} //数据
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        rowClassName="tableColor"
                                    />
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}