import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Input, Select, Spin, Modal, Typography, DatePicker } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';
import { Bar, } from "react-chartjs-2";
import { Chart } from "chart.js";
import './userRebateStatistics.css';

export default class UserRebateStatistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            isLoading: false,
            searchModel: { startDate: new Date().setMonth(new Date().getMonth() - 1), endDate: new Date(), pageSize: 10, page: 1, rebateState: "" },
            chartList: [],
            charData: {},
            isExport: false,
            oneLayerList: [],
            twoLayerList: [],
            threeLayerList: [],
            newUsersTotal: 0
        }
        //this.componentDidMount();

    }

    componentDidMount() {
        this.fromSearch();
    }

    check() {
        let model = this.state.searchModel
        let str = ""
        if (Common.isNotEmpty(model.consumeStartDate) && Common.isNotEmpty(model.consumeEndDate) ) {
            if(model.consumeStartDate > model.consumeEndDate){
                str += "消费由日期不能大于消费至日期;"
            }
            
        }
        if (Common.isNotEmpty(model.startDate) && Common.isNotEmpty(model.endDate) ) {
            if(model.startDate > model.endDate){
                str += "返利由日期不能大于返利至日期;"
            }
            
        }
        return str
    }

    fromSearch() {
        /* let str = this.check()
        if (Common.isNotEmpty(str)) {
            Message.openMsgError("错误", str)
            return
        }
        let model = this.state.searchModel */
        let model = this.state.searchModel
        let consumeStartDate = model.consumeStartDate != null ? moment(model.consumeStartDate).format("YYYYMMDD") : null;
        let consumeEndDate = model.consumeEndDate != null ? moment(model.consumeEndDate).format("YYYYMMDD") : null;
        if (consumeEndDate!=null&& consumeStartDate != null ) {
            if(consumeEndDate < consumeStartDate){
                Message.openMsgError("错误", "消费由日期不能大于消费至日期;");
                return;
            }
        }
        let startDate = model.startDate != null ? moment(model.startDate).format("YYYYMMDD") : null;
        let endDate = model.endDate != null ? moment(model.endDate).format("YYYYMMDD") : null;
        if (startDate!=null&& endDate != null ) {
            if(endDate < startDate){
                Message.openMsgError("错误", "返利由日期不能大于返利至日期;");
                return;
            }
        }
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "userRebateStatistics/fromSearch", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            let list = []
            if ("SUCCESS" === data.msg) {
                list = JSON.parse(JSON.stringify(data.object.list.list))
                let newModel = data.object.amountAll || {};
                newModel.rebateId = "rebateId";
                list.push(newModel);
                model.total = data.object.list.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: list,
                amountAll: data.object.amountAll || {},
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    reset() {
        let model = { page: 1, pageSize: 10, rebateState: "", startDate: new Date().setMonth(new Date().getMonth() - 1), endDate: new Date() }
        this.setState({
            searchModel: model,
            twoLayerList: [],
            threeLayerList: [],
        }, () => {
            this.fromSearch()
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize - 1;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.fromSearch();
    }

    exportReport() {
        /* let str = this.check()
        if (Common.isNotEmpty(str)) {
            Message.openMsgError("错误", str)
            return
        }
        let model = this.state.searchModel */
        let model = this.state.searchModel
        let consumeStartDate = model.consumeStartDate != null ? moment(model.consumeStartDate).format("YYYYMMDD") : null;
        let consumeEndDate = model.consumeEndDate != null ? moment(model.consumeEndDate).format("YYYYMMDD") : null;
        if (consumeEndDate!=null&& consumeStartDate != null ) {
            if(consumeEndDate < consumeStartDate){
                Message.openMsgError("错误", "消费由日期不能大于消费至日期;");
                return;
            }
        }
        let startDate = model.startDate != null ? moment(model.startDate).format("YYYYMMDD") : null;
        let endDate = model.endDate != null ? moment(model.endDate).format("YYYYMMDD") : null;
        if (startDate!=null&& endDate != null ) {
            if(endDate < startDate){
                Message.openMsgError("错误", "返利由日期不能大于返利至日期;");
                return;
            }
        }
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "userRebateStatistics/exportExcel", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "用户返利统计" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '会员手机号',
                dataIndex: 'phone',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="amount">总计</span>,
                            props: {
                                colSpan: 4,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '推荐卡号',
                dataIndex: 'whetherMasterCard',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '会员卡类型',
                dataIndex: 'cardType',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        if (Common.isEmpty(item.rebateType)) {
                            if (item.cardType === "Y") {
                                return "金卡"
                            } else if (item.cardType === "N") {
                                return "银卡"
                            }
                        } else {
                            if (item.rebateType === "BUSINESS") {
                                return "业务员金卡"
                            } else if (item.rebateType === "CORPORATE") {
                                return "企业金卡"
                            }
                        }

                    }
                }
            },
            {
                title: '消费金额',
                dataIndex: 'foodAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="amount">{Common.numberFormat(parseFloat(item.foodAmountAll || 0) + parseFloat(item.wineAmountAll || 0), 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat(parseFloat(item.foodAmount || 0) + parseFloat(item.wineAmount || 0), 2)
                    }
                }
            },
            {
                title: '返现金额',
                dataIndex: 'foodAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="amount">{Common.numberFormat(item.foodAmountAll, 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat(text, 2)
                    }
                }
            },
            {
                title: '已返利金额',
                dataIndex: 'alreadyRebateAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {

                        return {
                            children: <span className="amount">{Common.numberFormat(item.alreadyRebateAmountAll, 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }

                    } else {
                        return Common.numberFormat(text || 0, 2)
                    }
                }
            },
            {
                title: '未返利金额',
                dataIndex: 'notRebateAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="amount">{Common.numberFormat(item.notRebateAmountAll, 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }

                    } else {
                        return Common.numberFormat(text || 0, 2)
                    }
                }
            },
            {
                title: '返利总金额',
                dataIndex: 'notRebateAmount',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="amount">{Common.numberFormat(parseFloat(item.alreadyRebateAmountAll || 0) + parseFloat(item.notRebateAmountAll || 0), 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat(parseFloat(item.alreadyRebateAmount || 0) + parseFloat(item.notRebateAmount || 0), 2)
                    }
                }
            },
        ];

        return (
            <div className="userRebateStatistics">
                <div className="zhRoot">
                    <div className="zhTitle">用户返利统计</div>
                    <div className="content">
                        <Spin spinning={this.state.isExport} tip="下载中...">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} >
                                <TabPane tab="统计" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={20}
                                                placeholder="会员手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>

                                        <Col span={2} className="zhTxt">返利日期：</Col>
                                        <Col span={5} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.startDate == null ? null : moment(new Date(this.state.searchModel.startDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("startDate", value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.endDate == null ? null : moment(new Date(this.state.searchModel.endDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">推荐卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="推荐卡号"
                                                value={this.state.searchModel.whetherMasterCard || ''}
                                                onChange={(e) => this.searchChange('whetherMasterCard', e.target.value)} />
                                        </Col>

                                        <Col span={2} className="zhTxt">返利状态：</Col>
                                        <Col span={3} className="zhInput">
                                            <Select style={{ width: "100%" }} value={this.state.searchModel.rebateState || ''}
                                                onChange={(e) => this.searchChange('rebateState', e)}>
                                                <Option key={"a"} value={""}>全部</Option>
                                                {context.rebateStatusMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>

                                        <Col span={2} className="zhTxt">消费日期：</Col>
                                        <Col span={5} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.consumeStartDate == null ? null : moment(new Date(this.state.searchModel.consumeStartDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("consumeStartDate", value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.consumeEndDate == null ? null : moment(new Date(this.state.searchModel.consumeEndDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("consumeEndDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button type="primary" onClick={() => this.fromSearch()} icon="search">搜索</Button>
                                            <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.exportReport()} icon="download">导出</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table
                                            // rowSelection={false}
                                            rowKey="rebateId"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            dataSource={this.state.tables || []} //数据
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            pagination={{  //分页
                                                pageSizeOptions: ['11', '21', '31', '41'],
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total + (
                                                    this.state.searchModel.total % this.state.searchModel.pageSize === 0 ?
                                                        parseInt(this.state.searchModel.total / this.state.searchModel.pageSize) :
                                                        parseInt(this.state.searchModel.total / this.state.searchModel.pageSize) + 1
                                                ),             //总共多少条数据
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize + 1,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                        // onRow={(record) => {
                                        //     return {
                                        //         onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRows', 'selectedRowKeys'), // 点击行
                                        //     };
                                        // }}
                                        />
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}